import React from 'react';
import dates from '~/utils/dates';

const TermsLine: React.FC<any> = (props: any) => {
    return <div className="termLine">
        <div className="school">
            <small>Escola</small>
            <span>{ props.school.name  }</span>
        </div>

        <div className="date">
            <small>Data da Visita</small>
            <span>{ dates.getDateFormatted(props.visit_date, 'dd/mm/yyyy') } ({ props.visit_time_init } às { props.visit_time_end })</span>
        </div>

        <div className="supervisor">
            <small>Supervisor</small>
            <span>{ props.supervision_name }</span>
        </div>

        <div className="reponsible">
            <small>Reponsável da Escola</small>
            <span>{ props.school_representative_name }</span>
        </div>
    </div>;
}

export default TermsLine;
