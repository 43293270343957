import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import ParentsStudentRecordContent from './Content';

const ParentsStudentRecord: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData]   = useState<any>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <ParentsStudentRecordContent data={data} />
    }

    useEffect(() => {
        !ready && api.get(`student/${user.student_id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setData(resp.data);
            setReady(true);
        }).catch(errr => {
            console.log(errr);
            setError('Não foi possível localizar as informações do Aluno');
            setReady(true);
        })
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Ficha do Aluno") && setBreadcrumbs({
            curr: 'Ficha do Aluno',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Ficha do Aluno' }
            ]
        });
    }, [breadcrumbs]);

    return <Template
        page="Ficha do Aluno"
        pageTitle="Pais e Alunos - Ficha do Aluno"
        className="parentsStudentRecord"
    >
        {ready ? getContent() : <Loading />}
    </Template>;
}

export default ParentsStudentRecord;