import { ChangeEvent, useContext, useState } from "react";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalForm: React.FC<any> = ({ data, setModalOpen }: any) => {
    const [newData, setNewData]   = useState<any>({ name: data.name, mail: data.mail });
    const [loadData, setLoadData] = useState<boolean>(false);
    const [error, setError]       = useState<string | null>(null);

    // Contexts
    const { user, updateUser } = useContext(GeneralContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { name, value } = event.target;
        setNewData({
            ...newData,
            [name]: value
        });
    }

    const saveDataForm = async () => {
        if (newData.name.trim() === "" || newData.mail.trim() === "") {
            setError("Preencha todos os campos.");
            return;
        }

        // Clear states
        setLoadData(true);
        setError(null);

        // Set Body POST
        let dataFormat: any = {
            id: data.id,
            ...newData
        };

        // Send data to server
        await api.post('users/profile', dataFormat, {
            headers: { Authorization: user.token }
        }).then(resp => {
            updateUser(resp.data);
            setLoadData(false);
            setModalOpen(false);
        }).catch(errr => {
            console.log("Errors")
            console.log(errr)
            setError("Erro ao salvar os dados. Tente novamente.")
        });
    };

    return !loadData ? <>
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editUser">
            <div className="form-group no-margin">
                <div className="input-group">
                    <label>Nome Completo</label>
                    <input
                        type="text"
                        placeholder="ex.: Carlos Araujo Silva"
                        name="name"
                        value={newData.name}
                        onChange={onChange}
                        required
                    />
                </div>

                <div className="input-group">
                    <label>Email</label>
                    <input
                        type="text"
                        placeholder="ex.: carlos.a.silva@gmail.com"
                        name="mail"
                        value={newData.mail}
                        onChange={onChange}
                        required
                    />
                </div>
            </div>
        </form>

        <div className="buttons">
            {error && <div className="error">{error}</div>}
            <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
            <button id="btnCancel" type="button" onClick={() => setModalOpen(false)}>Cancelar</button>
        </div>
    </> : <div className="saving">Salvando dados...</div>;
};

export default ModalForm;
