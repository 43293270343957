import React, { useContext, useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa6';
import Treats from '~/utils/treats';
import Dates from '~/utils/dates';
import sort_by from '~/utils/sort';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import TemplateSupDir from '../Template';
import Loading from '~/components/Default/Loading';
import Modal from './Modal';

const SupervisionDirectionActivitiesReports: React.FC = () => {
    const [ready, setReady]     = useState(false);
    const [data, setData]       = useState<any>([]);
    const [classe, setClasse]   = useState<any>([]);
    const [classes, setClasses] = useState<any>([]);

    // Contexts
    const { user, school, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalClass, setModalOpen, setModalButton, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(data: any) {
        let tableHeader = [{ INFOS1: 'Nome', INFOS2: 'Idade', INFOS3: 'Ensino', INFOS4: 'Período', INFOS5: 'Classe', INFOS6: 'Média', INFOS7: 'Faltas'}];

        setModalClass('createRepGrades')
        setModalTitle('Relatório de Notas e Faltas')
        setModalBody(<Modal doc="schools_reports.grades" reportName="Relatório de Notas e Faltas" school={school} tableHeader={tableHeader} tableData={data} />)
        setModalButton(false)
        setModalOpen(true)
    }

    function onChangeClasss(e: React.ChangeEvent<HTMLSelectElement>) {
        let classs = classes.filter((el: any) => el.id === Number(e.target.value))[0];
        setClasse(classs)
    }

    function generateReport() {
        let dataTable: any = [];

        data.map((el: any) => {
            dataTable.push({
                INFOS1: Treats.formatNames(el.name),
                INFOS2: treatYearsOld(el.birth),
                INFOS3: Treats.formatNames(el.school_class.teaching.name),
                INFOS4: Treats.formatNames(el.school_class.shift.name),
                INFOS5: `${Treats.formatNames(el.school_class.class_serie)} Série ${Treats.formatNames(el.school_class.class_acron)}`,
                INFOS6: 0,
                INFOS7: 0
            })
        })

        openModal(dataTable)
    }

    function treatYearsOld (date: any) {
        let yearsOld = Dates.getYearsOld(date);

        // Less than a year old
        if (yearsOld.years===0) return yearsOld.months + ' meses';
        else return yearsOld.years + ' ano' + (yearsOld.years>1 ? 's' : '')
    }

    function classContent () {
        return <div className="classes">
            <select id="classes" name="classes" onChange={onChangeClasss} defaultValue={classe.id}>
                {
                    classes.map((el: any) => <option
                        key={`school_${el.id}`}
                        value={el.id}
                    >
                        {Number(el.class_serie)>0 ? el.class_serie + ' ano' : 'Turma'} {el.class_acron.toUpperCase()}
                    </option>)
                }
            </select>
        </div>
    }

    async function loadData (class_id: number) {
        setReady(false);

        class_id && await api.get(`student/school/${school.id}/${class_id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setData(resp.data);
            setReady(true);
        })
    }

    // Get page data
    useEffect(() => {
        let url = school ? `?school=${school.id}` : '';
        !ready && school && classes.length===0 && api.get(`school_classes/${url}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            let classData = resp.data ? resp.data.sort(sort_by({ name: 'class_serie' }, { name: 'class_acron' })) : [];
            setClasses(classData);
            loadData(classData[0].id);
        })
    }, [ready]);

    useEffect(() => {
        loadData(classe.id);
    }, [classe]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Relatório de Notas e Faltas") && setBreadcrumbs({
            curr: 'Relatório de Notas e Faltas',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Supervisão e Direção', url: '/supervisao-e-direcao'},
                { name: 'Notas e Faltas' }
            ]
        });
    }, [breadcrumbs]);

    return <TemplateSupDir
        page="Relatório de Notas e Faltas"
        pageTitle="Supervisão e Direção - Relatório de Notas e Faltas"
        className="supDirGrades"
        module={classContent()}
        buttons={[
            {
                icons: <FaFilePdf size={14} />,
                title: "Exportar",
                nameButton: "Exportar",
                className: "report add",
                onClick: () => generateReport()
            }
        ]}
    >
        {
            ready ? (
                data.length > 0 ? (
                    <div className="report">
                        <div className="header">
                            <div className="name">Nome</div>
                            <div className="age">Idade</div>
                            <div className="teaching">Ensino</div>
                            <div className="shift">Período</div>
                            <div className="classs">Classe</div>
                            <div className="average">Média</div>
                            <div className="faults">Faltas</div>
                        </div>
                        {
                            ready && data.map((el: any) => <div key={`std_${el.id}`} className='line'>
                                <div className="name">{Treats.formatNames(el.name)}</div>
                                <div className="age">{treatYearsOld(el.birth)}</div>
                                <div className="teaching">{Treats.formatNames(el.school_class.teaching.name)}</div>
                                <div className="shift">{Treats.formatNames(el.school_class.shift.name)}</div>
                                <div className="classs">{Treats.formatNames(el.school_class.class_serie)} Série {Treats.formatNames(el.school_class.class_acron)}</div>
                                <div className="average">0</div>
                                <div className="faults">0</div>
                            </div>)
                        }
                    </div>
                ) : <div className='alert'>Nenhum aluno localizado</div>
            ): <Loading />
        }
    </TemplateSupDir>;
}

export default SupervisionDirectionActivitiesReports;