import React, { useContext } from 'react';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import TeacherGradesUser from './User';
import TeacherGradesAdmin from './Admin';

const TeacherGrades: React.FC = () => {
    // Contexts
    const { user } = useContext(GeneralContext);

    return user.employee_id ? (
        <TeacherGradesUser />
    ) : (
        <TeacherGradesAdmin />
    );
}

export default TeacherGrades;