import React, { useContext } from "react";
import api from "~/services/api";
import Treats from '~/utils/treats';
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

const ModalInactivate: React.FC<any> = ({ data, setReady, setModalOpen }: any) => {
    // Contexts
    const { user } = useContext(GeneralContext);

    async function inactivatetransfer() {
        console.log(data.id);

        // Send data to server
        await api.patch(`transfer`, { id: data.id }, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setReady(false);
            setModalOpen(false);
        }).catch(errr => {
            console.log("Errors")
            console.log(errr)
        });
    }

    return <>
        <div className="infos">
            <p>Deseja realmente cancelar a transferência referente ao Aluno<br /><b>"{Treats.formatNames(data.student.name)}"</b>?</p>
            <p className="alert">Esse processo é irreversível!</p>
        </div>

        <div className="btns">
            <button id="btnDelete" type="button" onClick={inactivatetransfer}>Deletar</button>
            <button id="btnCancel" type="button" onClick={() => setModalOpen(false)}>Voltar</button>
        </div>
    </>;
};

export default ModalInactivate;
