const treats = {
    formatNames: function (str: string) {
        let strBase     = str.trim().replace(/ +(?= )/g, '').toLowerCase().split(' ');
        let ignoreCases = ['de', 'da', 'das', 'do', 'e', 'o', 'emeb', '-'];
        let upperCases  = ['emeb', 'i', 'ii', 'iii'];

        return strBase.map((el: any) => {
            let strFinal = [];
            el = el.trim();

            if (ignoreCases.indexOf(el) < 0)
                strFinal.push(el[0].toUpperCase() + el.substring(1))
            else if (upperCases.indexOf(el) >= 0)
                strFinal.push(el.toUpperCase())
            else
                strFinal.push(el)

            return strFinal;
        }).join(" ")
    },

    formatRG: function (text: any) {
        text = typeof text !== 'string' ? String(text) : text;

        // Set Default Mask
        let mask = /^([\d]{2})([\d]{3})([\d]{3})([\d]{1})/;

        // Return Formatted
        return text.replace(mask, "$1.$2.$3-$4");
    },

    formatCPF: function (text: any) {
        text = typeof text !== 'string' ? String(text) : text;
        text = text.length === 10 ? text = '0' + text : text;

        // Set Default Mask
        let mask = /^([\d]{3})([\d]{3})([\d]{3})([\d]{2})/;

        // Return Formatted
        return text.replace(mask, "$1.$2.$3-$4");
    },

    formatCEP: function (text: any) {
        text = typeof text !== 'string' ? String(text) : text;
        text = text.length === 7 ? text = '0' + text : text;

        // Set Default Mask
        let mask = /^([\d]{2})([\d]{3})([\d]{3})|^[\d]{2}.[\d]{3}-[\d]{3}/;

        // Return Formatted
        return text.replace(mask, "$1.$2-$3");
    }
}

export default treats;