import React from 'react';
import { FaEye, FaRectangleList } from 'react-icons/fa6';
import dates from '~/utils/dates';
import treats from '~/utils/treats';
import './styles.css';

interface Props {
    data: any;
    openModal: Function;
}

const StudentBox: React.FC<any> = ({ data, openModal }: Props) => {
    const yearsOld = dates.getYearsOld(data.birth);
    const birth    = dates.getDateFormatted(data.birth);

    return <div className="studentBox">
        <div className="infos">
            <h3>{ treats.formatNames(data.name) }</h3>

            <div className="school">
                <b>Escola:</b> { treats.formatNames(data.school.name) }
            </div>

            <div className="ra">
                <b>RA:</b> { data.ra_number }
            </div>

            <div className="birth">
                { birth.split('-').join('/') } <i>({yearsOld.years > 0 ? yearsOld.years + ' ano' + (yearsOld.years>1 ? 's' : '') : yearsOld.months + (yearsOld.months>1 ? ' meses' : ' mês')})</i>
            </div>
        </div>

        <div className="buttons">
            <button type="button" className="view" onClick={() => openModal('infos', data)} title="Visualizar Aluno">
                <FaEye size={16} /><span> Visualizar</span>
            </button>

            <button type="button" className="presence" onClick={() => openModal('stdPresenceView', data)} title="Visualizar Presença">
                <FaRectangleList size={16} />
            </button>
        </div>
    </div>;
}

export default StudentBox;