import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import TemplateTeacher from '../Template';
import Loading from '~/components/Default/Loading';
import StudentBox from './Box';
import Infos from './Modal/infos';
import PresencesEdit from './Modal/presencesEdit';

const TeacherStudents: React.FC = () => {
    const [ready, setReady]               = useState(false);
    const [selClass, setSelClasss]        = useState<any>(null);
    const [classes, setClasses]           = useState<any>([]);
    const [students, setStudents]         = useState<any>(null);
    const [studentsList, setStudentsList] = useState<any>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs, checkAdminDirector, teacher } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal (type: string, data?: any) {
        if (type === "stdPresenceView") {
            setModalClass('stdPresenceView')
            setModalTitle('Presença do Aluno');
            setModalBody(<PresencesEdit data={data} setModalOpen={setModalOpen} />)
        } else {
            setModalClass('studentInfos')
            setModalTitle('Visualizando Aluno')
            setModalBody(<Infos data={data} />)
        }

        setModalOpen(true);
    }

    function getSelectClasses () {
        if (classes.length === 0) return undefined;

        return <div className='classes'>
            <select
                className='classSel'
                defaultValue={selClass ? selClass : ''}
                onChange={(evt) => {
                    setSelClasss(evt.currentTarget.value !== '' ? evt.currentTarget.value : null);
                    setReady(false);
                }}
            >
                <option value="">-- Classes --</option>
                <optgroup label='Classes'>
                    {
                        classes.map((el: any) => <option
                            key={`class_${el.id}`}
                            value={el.id}
                        >
                            {el.class_serie}ª Série{el.class_acron ? ' ' + el.class_acron.toUpperCase() : ''}
                        </option>)
                    }
                </optgroup>
            </select>
        </div>
    }

    // Get page data
    useEffect(() => {
        let url = checkAdminDirector() && teacher ? '?teacher=' + teacher.id : '';

        !ready && teacher && api.get(`teacher/students${selClass ? '/' + selClass : ''}${url}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setStudents(resp.data.students);
            setStudentsList(resp.data.students);
            setClasses(resp.data.classes);
            if (resp.data.classes.length===1) setSelClasss(resp.data.classes[0].id);
            setReady(true);
        }).catch(() => {
            setReady(true);
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Alunos") && setBreadcrumbs({
            curr: 'Alunos',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Professor', url: '/professor'},
                { name: 'Alunos' }
            ]
        });
    }, [breadcrumbs]);

    return <TemplateTeacher
        page="Alunos"
        pageTitle="Professor - Alunos"
        className="teacherStudents"
        search={{
            dataList: studentsList, // Substitua 'yourDataList' pela sua lista de dados
            filterKey: ["id", "name", "school.name", "teaching.name"], // Substitua 'nome' pela chave que você deseja filtrar
            setFilteredList: setStudents // Substitua 'setYourList' pela função que define a lista filtrada
        }}
        module={getSelectClasses()}
        // buttons={[
        //     {
        //         icons: <FaListCheck size={14} />,
        //         title: "Lista de Presença",
        //         className: "presence",
        //         onClick: () => alert("Lista de Presença")
        //     }
        // ]}
    >
        {
            ready ? students && students.length > 0 ? (
                    <div className="students">
                        {
                            students.map((data: any) => <StudentBox
                                key={`std_${data.id}`}
                                data={data}
                                openModal={openModal}
                            />)
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhum aluno</div>
            : <Loading />
        }
    </TemplateTeacher>;
}

export default TeacherStudents;