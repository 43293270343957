import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import api from '~/services/api';
import dates from '~/utils/dates';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';

const ParentsNutrition: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [tip, setTip] = useState<any>(null);

    // Parameters
    let params = useParams();
    let slug   = params.slug;


    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    function getAuthor () {
        if (tip.author)
            return <><b>Autor:</b> {tip.author.name}&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;</>
        else
            return <></>
    }

    function getContent() {
        let date = dates.getDate(tip.date);

        return <>
            <div className="headerBox">
                <div className="buttons">
                    <Link to="/pais-e-alunos/nutricao" className="btn back" title="Voltar">
                        <FaArrowLeft size={12} /><span> Voltar</span>
                    </Link>
                </div>
            </div>

            <h2>{tip.title}</h2>
            <small>{getAuthor()}<b>Publicado em</b> {date.day} de {date.monthName} de {date.year}</small>

            <hr />

            <div className="content" dangerouslySetInnerHTML={{__html: tip.text}}></div>
        </>
    }

    useEffect(() => {
        !ready && api.get(`nutrition/tip/s/${slug}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setTip(resp.data);
            setReady(true);
        }).catch(errr => {
            console.log(errr);
            setReady(true);
        })
    }, [])

    // Breadcrumbs
    useEffect(() => {
        if (user.role === "student") {
            (!breadcrumbs || breadcrumbs.curr !== "Dica de Nutrição") && setBreadcrumbs({
                curr: 'Dica de Nutrição',
                links: [
                    { name: 'Home', url: '/' },
                    { name: 'Nutrição', url: '/pais-e-alunos/nutricao' },
                    { name: 'Dica de Nutrição' }
                ]
            });
        } else {
            (!breadcrumbs || breadcrumbs.curr !== "Dica de Nutrição") && setBreadcrumbs({
                curr: 'Dica de Nutrição',
                links: [
                    { name: 'Home', url: '/' },
                    { name: 'Pais e Alunos', url: '/pais-e-alunos' },
                    { name: 'Nutrição', url: '/pais-e-alunos/nutricao' },
                    { name: 'Dica de Nutrição' }
                ]
            });
        }
    }, [breadcrumbs]);

    return <Template
        page="Nutrição"
        pageTitle={`Pais e Alunos - Nutrição${tip ? ' - ' + tip.title : ''}`}
        className="parentsNutritionTip"
    >
        {ready ? getContent() : <Loading />}
    </Template>;
}

export default ParentsNutrition;