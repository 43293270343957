import React, { useContext, useEffect, useState } from 'react';
import numbers from '~/utils/numbers';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import ParentsDashBoard from '~/pages/Parents/Dashboard';
import TeacherDashBoard from '~/pages/Teacher/Dashboard';

const Dashboard: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData]   = useState<any>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    function getDashboard () {
        if (user.employee_id) return <TeacherDashBoard />
        else if (user.role === 'student') return <ParentsDashBoard />

        return (
            <Template page="Dashboard" pageTitle="Dashboard" className="dash">
                {
                    ready ? <div className="contentBox">
                        <div className="headerLine">
                            <div className='box school'>
                                <h5>{ numbers.format(data.schools) }</h5>
                                <small>Total de Unidades Escolares</small>
                            </div>

                            <div className='box students'>
                                <h5>{ numbers.format(data.students) }</h5>
                                <small>Total de Alunos</small>
                            </div>

                            <div className='box elementary'>
                                <h5>{ numbers.format(data.otherUnits) }</h5>
                                <small>Total de Outras Unidades</small>
                            </div>
                        </div>

                        <h3>Alunos</h3>
                        <div className="boxLine">
                            <div className='box'>
                                <h5>{ numbers.format(data.preSchoolStudents) }</h5>
                                <small>Pré Escola</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.elementarySchoolStudents) }</h5>
                                <small>Fundamental</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.studentsMale) }</h5>
                                <small>Sexo Masculino</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.studentsFemale) }</h5>
                                <small>Sexo Feminino</small>
                            </div>
                        </div>

                        <h3>Alunos por Horário</h3>
                        <div className="boxLine">
                            <div className='box'>
                                <h5>{ numbers.format(data.studentsIntegral) }</h5>
                                <small>Integral</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.studentsMorning) }</h5>
                                <small>Manhã</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.studentsAfternoon) }</h5>
                                <small>Tarde</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.studentsNight) }</h5>
                                <small>Noite</small>
                            </div>
                        </div>

                        <h3>Funcionários</h3>
                        <div className="boxLine">
                            <div className='box employees'>
                                <h5>{ numbers.format(data.employees) }</h5>
                                <small>Funcionários</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.teachers) }</h5>
                                <small>Professores</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.drivers) }</h5>
                                <small>Motoristas</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.monitors) }</h5>
                                <small>Monitores(as)</small>
                            </div>
                        </div>
                    </div> : <Loading />
                }
            </Template>
        )
    }

    // Loading Data
    useEffect(() => {
        if (user.role === 'student' || user.employee_id) {
            setReady(true);
        } else {
            !ready && api.get('dashboard', {
                headers: { Authorization: user.token }
            }).then((resp: any) => {
                setData(resp.data);
                setReady(true);
            }).catch((err: any) => {
                setError('Erro ao carregar os dados do dashboard')
                setReady(true)
            });
        }
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        if (user.role !== 'student' && !user.employee_id) {
            (!breadcrumbs || breadcrumbs.curr !== "Dashboard") && setBreadcrumbs({
                curr: 'Dashboard',
                links: [
                    { name: 'Home' }
                ]
            });
        }
    }, [breadcrumbs]);

    return getDashboard();
}

export default Dashboard;