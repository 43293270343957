import React, { useContext } from 'react';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import TeacherUser from './User';
import TeacherAdmin from './Admin';

const Parents: React.FC = () => {
    // Contexts
    const { user } = useContext(GeneralContext);

    return user.employee_id ? (
        <TeacherUser />
    ) : (
        <TeacherAdmin />
    );
}

export default Parents;