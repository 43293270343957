import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import treats from "~/utils/treats";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";
import { FaSearch } from "react-icons/fa";

const ModalList: React.FC<any> = ({ class_id, data, setModalOpen }: any) => {
    const [ready, setReady]             = useState(false);
    const [students, setStudents]       = useState<any>(null);
    const [dataAverage, setDataAverage] = useState<any>();

    // Contexts
    const { user, teacher } = useContext(GeneralContext);

    function onChange(event: ChangeEvent<HTMLInputElement|HTMLSelectElement>) {
        let {name, value} = event.target;
        change(name, value);
    }

    function change(name: string, value: string,) {
        // Set Data
        setDataAverage({...dataAverage, [name]:value});
    }

    function getAverages(){
        let subjectId = 1;
        api.get(`teacher/grade/class/2024/${class_id}/${subjectId}/${dataAverage.bimester}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setStudents(resp.data);
            setReady(true);
        }).catch(() => {
            setReady(true);
        });
    }

    function calcAvarage () {
        console.log(dataAverage)
        console.log(data)
        let data_send = {
            "teacherId": teacher.id,
            "schoolSubjectId": 1,
            "classId": data.id,
            "bimester": Number(dataAverage.bimester)
        }

        api.post(`teacher/grades/average`, data_send, {
            headers: { Authorization: user.token }
        }).then(resp => {
            alert('Salvo com sucesso')
            // setStudents(resp.data);
        }).catch(() => {
            setReady(true);
        });
    }

    function getListContent () {
        console.log(data)

        return <>
            <div className="header">
                <div className="selectBox">
                    <label htmlFor="schoolSubjectId">Matérias</label>
                    <select
                        id="schoolSubjectId"
                        name="schoolSubjectId"
                        value={dataAverage?.schoolSubjectId}
                        onChange={onChange}
                    >
                        <option value="">  Selecione uma matéria </option>
                        {
                            data.teachers.map((el: any) => <option
                                key={`disc_${el.id}`}
                                value={el.discipline}
                            >
                                {el.discipline}
                            </option>)
                        }
                    </select>
                </div>

                <div className="selectBox">
                    <label htmlFor="schoolSubjectId" >Bimestre</label>
                    <select
                        id="bimester"
                        name="bimester"
                        value={dataAverage?.bimester}
                        onChange={onChange}
                    >
                        <option value="" selected disabled>  Selecione um bimestre </option>
                        <option value="1">Primeiro </option>
                        <option value="2">Segundo</option>
                        <option value="3">Terceiro</option>
                        <option value="4">Quarto</option>
                    </select>
                </div>

                <button onClick={() => getAverages()}><FaSearch size={13} /></button>
            </div>

            <hr />

            <div className="studentsList">
                {
                    students ? <>
                        <div className="studentHead">
                            <div className="name">Nome</div>
                            <div className="average">Média</div>
                        </div>

                        <div className="students">
                            {
                                students.map((el: any) => (
                                    <div className="studentLine">
                                        <div className="name">{treats.formatNames(el.name)}</div>
                                        <div className="average">{el.average ?? '-'}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </> : <div className="alertInfo">Não localizamos nenhum aluno</div>
                }

            </div>

            <div className="btns">
                <button id="btnGenerate"  onClick={() => calcAvarage()}>Gerar / Recalcular Médias dos Alunos</button>
                <button id="btnCancel" onClick={() => setModalOpen(false)}>Cancelar</button>
            </div>
        </>
    }

    // Get page data
    // useEffect(() => {
    //     let currentYear = new Date().getFullYear();
    //     let subjectId = 1
    //     console.log("🚀 ~ useEffect ~ year:", currentYear)
    //     // !ready && api.get(`teacher/grades/${currentYear}?class_id=${class_id}`, {
    //     !ready && api.get(`teacher/grade/class/2024/${class_id}/${subjectId}`, {
    //         headers: { Authorization: user.token }
    //     }).then(resp => {
    //         console.log("🚀 ~ !ready&&api.get ~ resp.data:", resp.data)
    //         setStudents(resp.data);
    //         setReady(true);
    //     }).catch(() => {
    //         setReady(true);
    //     });
    // }, [ready]);

    return getListContent();
};

export default ModalList;
