import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaCalendarDays } from 'react-icons/fa6';
import numbers from '~/utils/numbers';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';

const Transport: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData]   = useState<any>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    // Loading Data
    useEffect(() => {
        !ready && api.get('dashboard/sec-education', {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setData(resp.data);
            setReady(true);
        }).catch((err: any) => {
            setError('Erro ao carregar os dados do dashboard')
            setReady(true)
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Secretaria da Educação") && setBreadcrumbs({
            curr: 'Secretaria da Educação',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Secretaria da Educação' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Secretaria da Educação" pageTitle="Secretaria da Educação" className="secEduc">
        {
            ready ? <>
                <div className="contentBox">
                    <div className="header">
                        <Link className="calendarBox" to="/secretaria-educacao/calendario">
                            <FaCalendarDays size={36} />
                            <small>Calendário Escolar</small>
                        </Link>

                        <div className="boxs">
                            <div className='box schools'>
                                <h5>{ numbers.format(data.schools) }</h5>
                                <small>Escolas</small>
                            </div>

                            <div className='box others'>
                                <h5>{ numbers.format(data.otherUnits) }</h5>
                                <small>Outras Unidades</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.students) }</h5>
                                <small>Alunos</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.employees) }</h5>
                                <small>Funcionários</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.supervisors) }</h5>
                                <small>Supervisores</small>
                            </div>

                            <div className='box'>
                                <h5>{ numbers.format(data.directors) }</h5>
                                <small>Diretores</small>
                            </div>
                        </div>
                    </div>

                    <div className="boxLine">
                    </div>
                </div>
            </> : <Loading />
        }
    </Template>;
}

export default Transport;