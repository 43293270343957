import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';

const TemplateSchoolSecretary: React.FC<any> = (props) => {
    const [ready, setReady] = useState(false);
    const [schools, setSchools] = useState<any>([]);

    // Contexts
    const { user, school, changeSchool } = useContext(GeneralContext);

    function onChangeSchool(e: React.ChangeEvent<HTMLSelectElement>) {
        let school = schools.filter((el: any) => el.id === Number(e.target.value))[0];
        changeSchool(school)
    }

    function getSchools() {
        return <>
            {
                user.role==='admin' ? <div className="schools">
                    <select id="schools" name="schools" onChange={onChangeSchool} defaultValue={school.id}>
                        {
                            schools.map((el: any) => <option
                                key={`school_${el.id}`}
                                value={el.id}
                            >
                                {el.name}
                            </option>)
                        }
                    </select>
                </div> : <></>
            }

            { props.module && props.module }
        </>
    }

    useEffect(() => {
        // Get schools if not set ready and user have rule 3 or admin
        !ready && user.role==='admin' && api.get('school', {
            headers: { Authorization: user.token }
        }).then(resp => {
            if (!school) changeSchool(resp.data[0])
            setSchools(resp.data)
            setReady(true)
        }).catch(errr => {
            setReady(true)
            console.log(errr)
        })
    }, [schools]);

    return <Template page={props.page} pageTitle={props.pageTitle} className={props.className}>
        {
            ready ? <>
                <HeaderSearchAndForm
                    module={getSchools()}
                    isViewButton={props.buttons ? true : false}
                    buttons={props.buttons ? props.buttons : undefined}
                    isViewSearch={props.search ? true : false}
                    search={props.search ? props.search : undefined}
                />

                {ready ? props.children : <></>}
            </> : <></>
        }
    </Template>;
}

export default TemplateSchoolSecretary;