import React from 'react';
import Treats from '~/utils/treats';
import dates from '~/utils/dates';
import "./styles.css";

const ParentsEnrollmentContent: React.FC<any> = ({ data }: any) => {
    // Birth Infos
    const birthDate = dates.getDate(data.birth);
    const yearsOld = dates.getYearsOld(data.birth);

    function getStatusClass (status: string) {
        switch (status) {
            case 'ENCERRADA':
                return 'closed'

            case 'BAIXA - TRANSFERÊNCIA':
                return 'transfer'

            case 'REMANEJAMENTO':
                return 'relocation'

            default:
                return 'active'
        }
    }

    return <>
        <h3 className='noMargin'>Informações do Aluno</h3>
        <div className="line">
            <div className="box name">
                <small>Nome Completo:</small>
                <span>{Treats.formatNames(data.name)}</span>
            </div>

            <div className="box ra">
                <small>RA:</small>
                <span>{data.ra_number}</span>
            </div>

            <div className="box birth">
                <small>Data de Nascimento:</small>
                <span>{birthDate.day} de {birthDate.monthName} de {birthDate.year}</span>
            </div>

            <div className="box age">
                <small>Idade:</small>
                <span>{yearsOld.years > 0 ? yearsOld.years + ' ano' + (yearsOld.years>1 ? 's' : '') : yearsOld.months + (yearsOld.months>1 ? ' meses' : ' mês')}</span>
            </div>
        </div>

        <div className="line">
            <div className="box sex">
                <small>Gênero:</small>
                <span>{Treats.formatNames(data.sex)}</span>
            </div>

            <div className="box color_race">
                <small>Raça/Cor:</small>
                <span>{data.color_race ? Treats.formatNames(data.color_race) : 'Não Declarada'}</span>
            </div>

            <div className="box mother">
                <small>Nome da Mãe:</small>
                <span>{Treats.formatNames(data.mother_name)}</span>
            </div>

            <div className="box father">
                <small>Nome do Pai:</small>
                <span>{Treats.formatNames(data.father_name)}</span>
            </div>
        </div>

        <h3>Histórico de Matrícula</h3>
        {
            data.historic.length>0 ? data.historic.map((el: any, idx: number) => <div key={`hist_${idx}`} className={`historicBox${idx===0 ? ' noMargin' : ''}`}>
                <div className="line">
                    <div className="box school">
                        <small>Escola:</small>
                        <span>{Treats.formatNames(el.school)}</span>
                    </div>

                    <div className="box city">
                        <small>Cidade:</small>
                        <span>{Treats.formatNames(el.city)}</span>
                    </div>

                    <div className="box network">
                        <small>Rede:</small>
                        <span>{Treats.formatNames(el.network)}</span>
                    </div>

                    <div className="box year">
                        <small>Ano Letivo:</small>
                        <span>{el.year}</span>
                    </div>
                </div>

                <div className="line">
                    <div className="box type">
                        <small>Tipo:</small>
                        <span>{Treats.formatNames(el.teaching)}</span>
                    </div>

                    <div className="box class">
                        <small>Classe:</small>
                        <span>{Treats.formatNames(el.class)}</span>
                    </div>

                    <div className="box period">
                        <small>Período:</small>
                        <span>{Treats.formatNames(el.period)}</span>
                    </div>
                </div>

                <div className="line">
                    <div className={`box status ${getStatusClass(el.enrollment.status)}`}>
                        <small>Status da Matrícula:</small>
                        <span>{Treats.formatNames(el.enrollment.status)}</span>
                    </div>

                    <div className="box date_init">
                        <small>Início da Matrícula:</small>
                        <span>{dates.getDateFormatted(el.enrollment.date_init + ' 03:00:00', 'dd/mm/yyyy')}</span>
                    </div>

                    <div className="box date_end">
                        <small>Fim da Matrícula:</small>
                        <span>{dates.getDateFormatted(el.enrollment.date_end + ' 03:00:00', 'dd/mm/yyyy')}</span>
                    </div>

                    <div className="box date_inclusion">
                        <small>Inclusão da Matrícula:</small>
                        <span>{dates.getDateFormatted(el.enrollment.date_inclusion, 'dd/mm/yyyy HH:MM')}</span>
                    </div>
                </div>
            </div>) : <div className='noData'>Não foi possível localizar dados de matrícula do aluno</div>
        }
    </>
}

export default ParentsEnrollmentContent;