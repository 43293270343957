import { ChangeEvent, useContext, useEffect, useState } from "react";
import api from "~/services/api";
import Treats from '~/utils/treats';
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

// Base Data
let baseData = {
    "id"                 : null,
    "school_id_requester": "",
    "school_id_required" : "",
    "applicant_id"       : "",
    "student_id"         : "",
    "status"             : "Pendente",
};

const ModalForm: React.FC<any> = (props: any) => {
    const [ready, setReady]       = useState<boolean>(false);
    const [loadData, setLoadData] = useState<boolean>(false);
    const [data, setData]         = useState<any>(baseData);
    const [schools, setSchools]   = useState<any>([]);
    const [students, setStudents] = useState<any>([]);
    const [errors, setErrors]     = useState<string | null>(null);

    // Contexts
    const { user, school } = useContext(GeneralContext);

    async function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { name, value } = event.target;

        if (name === "school_id_requester" && value.trim() !== '') {
            getStudents(Number(value));
            setData({ ...data, [name]: (value ? Number(value) : "") });
        } else if (name === "school_id_requester" && value.trim() === '') {
            setStudents([]);
            setData({ ...data, student_id: "", [name]: (value ? Number(value) : "") });
        } else {
            setData({ ...data, [name]: (value ? (name==='status' ? value : Number(value)) : "") });
        }
    }

    async function getStudents (school_id: number) {
        await api.get(`student/school/${school_id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setStudents(resp.data)
        }).catch(errr => {
            console.log(errr)
        });
    }

    function getContent() {
        if (loadData) return <div className="saving">Salvando dados...</div>

        return <>
            <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editUser">
                <div className="form-group no-margin">
                    <div className="inputLabel">
                        <label>Escola Origem</label>
                        <select name="school_id_requester" onChange={onChange} defaultValue={data?.school_id_requester} disabled={true}>
                            <option value="">-- Selecione a Escola --</option>

                            {
                                schools.map((el: any) => <option key={`scRequires_${el.id}`} value={el.id}>
                                    { Treats.formatNames(el.name) }
                                </option>)
                            }
                        </select>
                    </div>
                </div>

                <div className="form-group no-margin">
                    <div className="inputLabel">
                        <label>Escola Destino</label>
                        <select name="school_id_required" onChange={onChange} defaultValue={data.school_id_required===null ? '999999' : data.school_id_required} disabled={schools && schools.length>0 ? false : true}>
                            <option value="">-- Selecione a Escola --</option>
                            <option value="999999">Escola Particular / Outro Município</option>

                            {
                                schools.map((el: any) => <option key={`scRequester_${el.id}`} value={el.id}>
                                    { Treats.formatNames(el.name) }
                                </option>)
                            }
                        </select>
                    </div>
                </div>

                <hr />

                <div className="form-group no-margin">
                    <div className="inputLabel">
                        <label>Aluno</label>
                        <select name="student_id" onChange={onChange} defaultValue={data?.student_id} disabled={students && students.length>0 ? false : true}>
                            <option value="">-- Selecione um Aluno --</option>

                            {
                                students.map((el: any) => <option key={`std_${el.id}`} value={el.id}>
                                    { Treats.formatNames(el.name) }
                                </option>)
                            }
                        </select>
                    </div>
                </div>
            </form>

            <div className="buttons">
                {errors && <div className="error">{errors}</div>}
                <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
                <button id="btnCancel" type="button" onClick={() => props.setModalOpen(false)}>Cancelar</button>
            </div>
        </>
    }

    const saveDataForm = async () => {
        if (
            data.school_id_requester === "" ||
            data.school_id_required === "" ||
            data.student_id === ""
        ) {
            setErrors("Preencha todos os campos.");
            return;
        }

        // Clear states
        // setLoadData(true);
        setErrors(null);

        // Set Body POST
        let dataFormat: any = data;
        dataFormat.applicant_id = user.id;
        if (props.data && props.data.id) dataFormat.id = props.data.id;

        // Send data to server
        await api.post('transfer', dataFormat, {
            headers: { Authorization: user.token }
        }).then(data => {
            setLoadData(false);
            props.setReady(false);
            props.setModalOpen(false);
        }).catch(errr => {
            console.log("Errors")
            console.log(errr)
            setErrors("Erro ao salvar os dados. Tente novamente.")
        });
    };

    async function loadInfos() {
        try {
            const [schoolsResponse] = await Promise.all([
                api.get('school', { headers: { Authorization: user.token } }),
                getStudents(school.id)
            ]);

            setSchools(schoolsResponse.data);
            setReady(true);
        } catch (error) {
            alert('Erro ao carregar informações do formulário')
            console.error('Erro ao carregar informações:', error);
        }
    }

    useEffect(() => {
        !ready && loadInfos();
    }, [ready])

    useEffect(() => {
        if (props.data) setData({
            id                 : props.data.id,
            school_id_requester: school.id,
            school_id_required : props.data.school_id_required,
            applicant_id       : props.data.applicant_id,
            student_id         : props.data.student_id,
            status             : props.data.status
        })
        else setData({
            ...data,
            school_id_requester: school.id,
        })
    }, [])

    return ready ? getContent() : <></>
};

export default ModalForm;
