import React, { useContext } from 'react';
import { FaTrash, FaChevronLeft, FaFloppyDisk } from 'react-icons/fa6';
import { PlanningDataEf, PlanningData } from '../types';

// Components
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import { InputLabel } from '~/components/Forms';
import api from '~/services/api';
import { GeneralContext } from '~/contexts';
import { useNavigate } from 'react-router-dom';

interface PlanningEfProps {
	data: PlanningData;
	dataEf: PlanningDataEf;
	subjects: any;
	infos: any;
	setDataEf: React.Dispatch<React.SetStateAction<PlanningDataEf>>;
	setStep: React.Dispatch<React.SetStateAction<number>>;
}

const PlanningEf: React.FC<PlanningEfProps> = ({ data, dataEf, setDataEf, setStep, infos, subjects  }) => {

	const { user } = useContext(GeneralContext);
	const navigate = useNavigate();


	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index?: number) => {
		if (typeof index === 'number') {
			const updatedRows = [...dataEf.additionalRows];

			updatedRows[index] = {
				...updatedRows[index],
				[e.target.name]: e.target.value,
			};
			setDataEf((prev) => ({ ...prev, additionalRows: updatedRows }));
		} else {
			setDataEf((prev) => ({ ...prev, [e.target.name]: e.target.value }));
		}
	};

	const addRow = () => {
		setDataEf((prev) => ({
			...prev,
			additionalRows: [
				...prev.additionalRows,
				{
					additionalMaterials: '',
					additionalModalities: '',
					additionalReferences: '',
					additionalEvaluationInstrument: '',
				},
			],
			}));
	  };


    const removeRow = (index: number) => {
		setDataEf((prev) => ({
			...prev,
			additionalRows: prev.additionalRows.filter((_, i) => i !== index),
		}));
    };

	async function sendForm() {
        await api.post('/teacher/planning-ef', {...data, ...dataEf}, {headers: { Authorization: user.token }})
			.then(resp => {
				alert('Cadastro realizado com sucesso')
				console.log(resp)
				navigate('/professor/planejamento')
			}).catch(err => {
				console.log("🚀 ~ sendForm ~ err:", err)
			})
		}

	return <>
		<HeaderSearchAndForm
			buttons={[
				{ nameButton: "Voltar", onClick: () => setStep(0), icons: <FaChevronLeft size={14} /> },
				{ nameButton: "Salvar", onClick: () => sendForm(), className: 'save', icons: <FaFloppyDisk size={14} /> }
			]}
			isViewButton={true}
			isViewSearch={false}
		/>
		<div className="ef">
			<header>
				<label><b>Bimestre:</b> {data.bimester}</label>
				<label><b>Série:</b> {data.series}</label>
				<label><b>Matéria:</b> {subjects.find((subject:any) => subject.id == data.subject)?.name || 'Matéria não encontrada'}</label>
			</header>

			<div className="contentForm">
				<div className="formGroup center">

					<div className="formItem inputLabel">
						<label htmlFor="hability">Habilidade</label>
						<select onChange={handleInputChange} id="hability" name="hability" value={dataEf?.hability} defaultValue={dataEf?.hability} required>
							<option value="" disabled>  Selecione uma habilidade </option>
							{
								infos
									// .filter((el: any) => el.planningSkillCycle === data.level && el.schoolSubjectId === data.subject)
									.filter((el: any) => el.planningSkillCycle == '1' && el.schoolSubjectId == data.subject)
									.filter((el: any, index: number, self: any[]) =>
										index === self.findIndex((e: any) => e.planningSkillId === el.planningSkillId)
									)
									.map((el: any) => (
										<option key={'hability_' + el.planningSkillId} value={el.planningSkillId}>
											{el.planningSkillId}
										</option>
									))
							}
						</select>
					</div>
				</div>

				<div className="formGroup">
					{/* <div className="formItem">
						<InputLabel
							name="languagePractices"
							type="text"
							labelName="Práticas de linguagem"
							value={dataEf.languagePractices}
							onChange={handleInputChange}
						/>
					</div> */}

					<div className="formItem inputLabel">
						<label htmlFor="thematicUnit">{(data?.subject == '1' )? 'Práticas de linguagem': 'Unidades temáticas'}</label>
						<select onChange={handleInputChange} id="thematicUnit" name="thematicUnit" value={dataEf?.thematicUnit} defaultValue={dataEf?.thematicUnit} required>
							<option value="" disabled>  Selecione uma {(data?.subject == '1' )? 'práticas de linguagem': 'unidades temáticas'} </option>
							{
								infos
									.filter((el: any) => el.planningSkillId == dataEf.hability)
									.map((el: any) => (
										<option key={'thematicUnit_' + el.skillThematicUnitId} value={el.skillThematicUnitId}>
											{el.thematicUnitDescription}
										</option>
									))
							}
						</select>
					</div>

					<div className="formItem inputLabel">
						<label htmlFor="knowledgeObjects">Objetos de conhecimento</label>
						<select onChange={handleInputChange} id="knowledgeObjects" name="knowledgeObjects" value={dataEf?.knowledgeObjects} defaultValue={dataEf?.knowledgeObjects} required>
							<option value="" disabled> Selecione um objeto de conhecimento </option>
							{
								infos
									.filter((el: any) => el.planningSkillId == dataEf.hability )
									.map((el: any) => (
										<option key={'knowledgeObjects_' + el.objectKnowId} value={el.objectKnowId}>
											{el.objectKnowDescription}
										</option>
									))
							}
						</select>
					</div>
				</div>

				<div className="formGroup">

					<div className="formItem">
						<InputLabel
							name="usedMaterials"
							type="text"
							labelName="Materiais utilizados"
							value={dataEf.usedMaterials}
							onChange={handleInputChange}
						/>
					</div>

					<div className="formItem">
						<InputLabel
							name="organizationalModalities"
							type="text"
							labelName="Modalidades organizativas"
							value={dataEf.organizationalModalities}
							onChange={handleInputChange}
						/>
					</div>
				</div>

				<div className="formGroup">
					<div className="formItem">
						<InputLabel
							name="references"
							type="text"
							labelName="Referências"
							value={dataEf.references}
							onChange={handleInputChange}
						/>
					</div>
					<div className="formItem">
						<InputLabel
							name="evaluationInstrument"
							type="text"
							labelName="Instrumento de avaliação"
							value={dataEf.evaluationInstrument}
							onChange={handleInputChange}
						/>
					</div>

				</div>

				<hr />

				{
					dataEf.additionalRows.map((row, index) => (
						<div className="formGroup lines" key={index}>
							<div className="formItem">
								<InputLabel
									name={`additionalMaterial_${index}`}
									type="text"
									labelName="Materiais utilizados"
									value={row.additionalMaterials[index]}
									onChange={(e) => handleInputChange(e, index)}
								/>
							</div>

							<div className="formItem">
								<InputLabel
									name={`additionalModalities_${index}`}
									type="text"
									labelName="Modalidades organizativas"
									value={row.additionalModalities[index]}
									onChange={(e) => handleInputChange(e, index)}
								/>
							</div>

							<div className="formItem">
								<InputLabel
									name={`additionalReferences_${index}`}
									type="text"
									labelName="Referências"
									value={row.additionalReferences[index]}
									onChange={(e) => handleInputChange(e, index)}
								/>
							</div>
							<div className="formItem">
								<InputLabel
									name={`evaluationInstrument_${index}`}
									type="text"
									labelName="Instrumento de avaliação"
									value={row.additionalEvaluationInstrument[index]} // Garantindo que o valor reflita o estado
									onChange={(e) => handleInputChange(e, index)}
								/>
							</div>
							<button
								type="button"
								className="del"
								onClick={() => removeRow(index)}
							>
								<FaTrash />
							</button>
						</div>
					))
				}

				{
					dataEf.additionalRows.length < 3 && (
						<button type="button" className='add' onClick={addRow}>
							Adicionar Linha
						</button>
					)
				}
			</div>
		</div>
    </>;
};

export default PlanningEf;