import React from 'react';
import { FaEye } from 'react-icons/fa6';
import "./styles.css";

const TeacherPlanningContent: React.FC<any> = ({ plans, openModal }: any) => {
    return <div className='plans'>
        <div className="childish">
            <h3>Ensino Infantil</h3>
            <div className="header">
                <div className="serie">Série</div>
                <div className="bimonthly">Bimestre</div>
                <div className="ability">Habilidade</div>
                <div className="description">Descrição da habilidade</div>
                <div className="experience">Campo de experiência</div>
                <div className="btn"></div>
            </div>

            <div className="content">
                {
                    plans.ei.map((item: any, idx: number) => (
                        <div key={`childish_${idx}`} className="line">
                            <div className="serie">{item.series}</div>
                            <div className="bimonthly">{item.bimester}</div>
                            <div className="ability">{item.planningSkillId}</div>
                            <div className="description">{item.planningSkillDesc}</div>
                            <div className="experience">{item.fieldsExperienceDesc}</div>
                            <button
                                type='button'
                                className="btn"
                                onClick={() => openModal(item)}
                            >
                                <FaEye />
                            </button>
                        </div>
                    ))
                }
            </div>
        </div>

        <hr />

        <div className="fundamental">
        <h3>Ensino Fundamental</h3>
            <div className="header">
                <div className="serie">Série</div>
                <div className="bimonthly">Bimestre</div>
                <div className="ability">Habilidade</div>
                <div className="description2">Descrição da habilidade</div>
                <div className="knowledge">Objeto de conhecimento</div>
                <div className="theme">Unidade Temática</div>
                <div className="btn"></div>
            </div>

            <div className="content">
                {
                    plans.ef.map((item: any, idx: number) => (
                        <div key={`fundamental_${idx}`} className="line">
                            <div className="serie">{item.series}</div>
                            <div className="bimonthly">{item.bimester}</div>
                            <div className="ability">{item.planningSkillId}</div>
                            <div className="description2">{item.planningSkillDesc}</div>
                            <div className="knowledge">{item.objectKnowDesc}</div>
                            <div className="theme">{item.thematicUnitDesc}</div>
                            <button
                                type='button'
                                className="btn"
                                onClick={() => openModal(item)}
                            >
                                <FaEye />
                            </button>
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
}

export default TeacherPlanningContent;