import React, { useContext, useEffect, useState } from 'react';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import PlanningInit from '../Box/init';
import PlanningEf from '../Box/ef';
import PlanningEi from '../Box/ei';

// Types
import { PlanningData, PlanningDataEf, PlanningDataEi } from '../types';
import api from '~/services/api';

const TeacherPlanning: React.FC = () => {
	const { breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
	const {user}                          = useContext(GeneralContext);

	const [step, setStep]         = useState(0);
	const [infosEf, setInfosEf]       = useState<any>()
	const [infosEi, setInfosEi]       = useState<any>()
	const [subjects, setSubjects] = useState<any>()
	const [data, setData] = useState<PlanningData>({
		bimester: '',
		series: '',
		level: '',
		subject: '',
	});
	const [dataEf, setDataEf] = useState<PlanningDataEf>({
		hability: '',
		languagePractices: '',
		thematicUnit: '',
		knowledgeObjects: '',
		usedMaterials: '',
		organizationalModalities: '',
		plannedActivities: '',
		references: '',
		evaluationInstrument: '',
		additionalRows: [],
	});

	const [dataEi, setDataEi] = useState<PlanningDataEi>({
		hability: '',
		fieldsExperience: '',
		approach: '',
		contextPlanning: '',
		sessionPlanning: '',
		additionalRows: [],
	});

	async function loadInfos() {
		await api.get('/teacher/planning', {headers: { Authorization: user.token }})
        .then(resp => {
            console.log("🚀 ~ loadInfos ~ resp.data.plannings.ei:", resp.data.plannings.ei)

            setInfosEf(resp.data.plannings.ef)
            setInfosEi(resp.data.plannings.ei)
            setSubjects(resp.data.subjects)
        }).catch(err => {
            console.log("Load error", err)
        })
	}

	// Atualiza os breadcrumbs
	useEffect(() => {
		if (!infosEf && !infosEi && !subjects) loadInfos()
		if (!breadcrumbs || breadcrumbs.curr !== 'Cadastro de planejamento') {
			setBreadcrumbs({
				curr: 'Cadastro de planejamento',
				links: [
				{ name: 'Home', url: '/' },
				{ name: 'Professor', url: '/professor' },
				{ name: 'Planejamento', url: '/professor/planejamento' },
				{ name: 'Cadastro de planejamento' },
				],
			});
		}
	}, [breadcrumbs]);

	if(!infosEf && !infosEi && !subjects) {
		return <p>Carregando...</p>
	}

	return (
		<Template
			page="Cadastro de planejamento de aulas"
			pageTitle="Professor - Cadastro de Planejamento de Aulas"
			className="registerPlanning"
		>
			{step === 0 && <PlanningInit setStep={setStep} data={data} setData={setData} subjects={subjects}/>}
			{step === 1 && <PlanningEi   setStep={setStep} data={data} dataEi={dataEi} setDataEi={setDataEi} infos={infosEi} subjects={subjects}/>}
			{step === 2 && <PlanningEf   setStep={setStep} data={data} dataEf={dataEf} setDataEf={setDataEf} infos={infosEf} subjects={subjects}/>}

		</Template>
	);
};

export default TeacherPlanning;
