import React from 'react';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa6';
import dates from '~/utils/dates';
import "./styles.css";

const curr = dates.getDateFormatted(new Date(), 'dd/mm/yyyy');

const ParentsContent: React.FC<any> = ({ schedules, tips }: any) => {
    function getSchedule () {
        let days = dates.getWeekDays(new Date());

        return <div className="schedules">
            {
                days.map((el: any, idx: number) => {
                    let daySchedule = schedules.filter((el2: any) => el.date === dates.getDateFormatted(el2.date, 'dd/mm/yyyy'));
                    let infosDay    = daySchedule.length > 0 ? daySchedule[0].meals : [];

                    return <div
                        key={`sch_${idx}`}
                        className={`scheduleBox${curr === el.date ? ' curr' : ''}`}
                    >
                        <div className='week'>{el.week}</div>
                        <div className='date'>{el.date}</div>
                        <hr />
                        <div className='meals'>{infosDay.length > 0 ? infosDay.length + (infosDay.length > 1 ? ' Refeições' : ' Refeição') : '-'}</div>
                    </div>
                })
            }
        </div>
    }

    function getTips () {
        return <>
            <h3>Dicas de Nutrição</h3>

            <div className="tipsContent">
                {
                    tips.length > 0 ? <>
                        <div className="head">
                            <div className="title">Título</div>
                            <div className="author">Autor</div>
                            <div className="date">Publicação</div>
                            <div className="btn"></div>
                        </div>

                        <div className="tips">
                            {
                                tips.map((el: any) => <div
                                    key={`tip_${el.id}`}
                                    className="tipLine"
                                >
                                    <div className="title">{el.title}</div>
                                    <div className="author">{el.author ? el.author.name : '-'}</div>
                                    <div className="date">{dates.getDateFormatted(el.date, 'dd/mm/yyyy')}</div>
                                    <Link to={`/pais-e-alunos/nutricao/${el.slug}`} className="btn"><FaEye size={14} /></Link>
                                </div>)
                            }
                        </div>
                    </> : <div className="alertInfo">Nenhuma dica disponível</div>
                }
            </div>
        </>
    }

    return <>
        { getSchedule() }
        { getTips() }
    </>
}

export default ParentsContent;