import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaAddressCard, FaBook, FaCalendarPlus, FaChartSimple, FaUsersLine } from 'react-icons/fa6';
import { GeneralContext } from '~/contexts/general';

const Teacher: React.FC = () => {
    const { user } = useContext(GeneralContext);
    const location = useLocation();

    return (
        <>
            {
                user.employee_id && (
                    <Link to="/professor/turmas" className={location.pathname === '/professor/turmas' ? ' active' : ''}>
                        <FaUsersLine />
                        <span>Turmas</span>
                    </Link>
                )
            }

            {
                user.employee_id && (
                    <Link to="/professor/alunos" className={location.pathname === '/professor/alunos' ? ' active' : ''}>
                        <FaAddressCard />
                        <span>Alunos</span>
                    </Link>
                )
            }

            {
                user.employee_id && (
                    <Link to="/professor/atividades" className={location.pathname === '/professor/atividades' ? ' active' : ''}>
                        <FaBook />
                        <span>Provas e Atividades</span>
                    </Link>
                )
            }

            {
                user.employee_id && (
                    <Link to="/professor/notas" className={location.pathname === '/professor/notas' ? ' active' : ''}>
                        <FaChartSimple />
                        <span>Notas e Outros</span>
                    </Link>
                )
            }

            {
                user.employee_id && (
                    <Link to="/professor/planejamento" className={location.pathname === '/professor/planejamento' ? ' active' : ''}>
                        <FaCalendarPlus />
                        <span>Planejamento de Aulas</span>
                    </Link>
                )
            }
        </>
    );
}

export default Teacher;
