import React, { useState } from 'react';
import Treats from '~/utils/treats';
import dates from '~/utils/dates';
import "./styles.css";

const ParentsContent: React.FC<any> = ({ data, currDate, date, presences, grades, getMonthPresence, getMonthGrades, setDate, setError }: any) => {
    const [monthSel, setMonthSel] = useState<any>(currDate.month);

    function getDays () {
        if (presences === null) return <></>;

        return Array.from(Array(date.days), (e: any, idx: number) => {
            let day     = (idx + 1) <= 9 ? '0' + (idx + 1) : String(idx + 1);
            let weekend = dates.checkWeekend(date.year + '-' + date.month + '-' + day + ' 03:00:00');

            // Check Present / Fault or Weekend
            let presenceBase = presences.length > 0 ? presences.filter((el: any) => el.day === day) : [];
            let presence     = { class: 'fault', value: 'Faltou' };

            if (presenceBase.length > 0 && presenceBase[0].status === 'present') presence = { class: 'present', value: 'Presente' }
            if (weekend) presence = { class: 'weekend', value: 'Final de Semana' }

            return <div className={`dayLine ${presence.class}`} key={`presence_${day}`}>
                <div className="day">{ day }</div>
                <div className={`status ${presence.class!=='weekend' ? presence.class : ''}`}>{ presence.value }</div>
            </div>
        })
    }

    function changeMonth(month: string) {
        let newDate = dates.getDate(`${date.year}-${(Number(month)<=9 ? '0' : '') + month}-01 03:00:00`);
        setDate(newDate);
        setMonthSel(month);
        getMonthPresence(month);
        getMonthGrades(month);
        setError(null);
    }

    function getContentMonths() {
        return <>
            <button
                className={(monthSel==='01' ? 'active' : '') + (Number(currDate.month)<1 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=1 ? (monthSel!=='01' ? changeMonth('01') : null) : undefined}
                type="button"
            >
                Jan
            </button>

            <button
                className={(monthSel==='02' ? 'active' : '') + (Number(currDate.month)<2 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=2 ? (monthSel!=='02' ? changeMonth('02') : null) : undefined}
                type="button"
            >
                Fev
            </button>

            <button
                className={(monthSel==='03' ? 'active' : '') + (Number(currDate.month)<3 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=3 ? (monthSel!=='03' ? changeMonth('03') : null) : undefined}
                type="button"
            >
                Mar
            </button>

            <button
                className={(monthSel==='04' ? 'active' : '') + (Number(currDate.month)<4 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=4 ? (monthSel!=='04' ? changeMonth('04') : null) : undefined}
                type="button"
            >
                Abr
            </button>

            <button
                className={(monthSel==='05' ? 'active' : '') + (Number(currDate.month)<5 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=5 ? (monthSel!=='05' ? changeMonth('05') : null) : undefined}
                type="button"
            >
                Mai
            </button>

            <button
                className={(monthSel==='06' ? 'active' : '') + (Number(currDate.month)<6 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=6 ? (monthSel!=='06' ? changeMonth('06') : null) : undefined}
                type="button"
            >
                Jun
            </button>

            <button
                className={(monthSel==='07' ? 'active' : '') + (Number(currDate.month)<7 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=7 ? (monthSel!=='07' ? changeMonth('07') : null) : undefined}
                type="button"
            >
                Jul
            </button>

            <button
                className={(monthSel==='08' ? 'active' : '') + (Number(currDate.month)<8 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=8 ? (monthSel!=='08' ? changeMonth('08') : null) : undefined}
                type="button"
            >
                Ago
            </button>

            <button
                className={(monthSel==='09' ? 'active' : '') + (Number(currDate.month)<9 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=9 ? (monthSel!=='09' ? changeMonth('09') : null) : undefined}
                type="button"
            >
                Set
            </button>

            <button
                className={(monthSel==='10' ? 'active' : '') + (Number(currDate.month)<10 ? ' disabled' : '')}
                onClick={() => Number(currDate.month)>=10 ? (monthSel!=='10' ? changeMonth('10'): null) : undefined}
                type="button"
            >
                Out
            </button>

            <button
                className={(monthSel==='11' ? 'active' : '') + (Number(currDate.month)<11 ? ' disabled' : '')}
                onClick={() => Number(currDate.month)>=11 ? (monthSel!=='11' ? changeMonth('11'): null) : undefined}
                type="button"
            >
                Nov
            </button>

            <button
                className={(monthSel==='12' ? 'active' : '') + (Number(currDate.month)<12 ? ' disabled' : '')}
                onClick={() => Number(currDate.month)>=12 ? (monthSel!=='12' ? changeMonth('12'): null) : undefined}
                type="button"
            >
                Dez
            </button>
        </>
    }

    return <>
        {
            data ? <div className="studentHead">
                <h2>{Treats.formatNames(data.name)}</h2>
                <div className="classs">
                    {data.school_class.class_serie} Ano {data.school_class.class_acron.toUpperCase()}
                </div>
            </div> : <></>
        }

        <div className="monthsLine">
            {getContentMonths()}
        </div>

        <div className="dataColumns">
            <div className="grades">
                <h3>Notas</h3>

                <div className="gradeHeader">
                    <div className="activity">Prova / Atividade</div>
                    <div className="grade">Nota</div>
                </div>

                {
                    grades && grades.length > 0 ? grades.map((el: any) => <div className="lineGrade">
                        <div className="activity">{ el.title }</div>
                        <div className="grade">{ el.grades[0].grade }</div>
                    </div>) : <div className="alertInfo">Nenhum prova cadastrada no momento</div>
                }
            </div>

            <div className="presences">
                <h3>Presenças</h3>

                <div className="dayHeader">
                    <div className="day">Dia</div>
                    <div className="status">Status</div>
                </div>

                <div className="daysBox">
                    {getDays()}
                </div>
            </div>
        </div>
    </>
}

export default ParentsContent;