import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import Dates from '~/utils/dates';
import Treats from '~/utils/treats';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';


// Variables Base
const schoolBase = { id: 0, name: "", address: "" };

let dataBase: any = {
    "REPORT_NAME"   : "",
    "SCHOOL_NAME"   : "",
    "SCHOOL_ADDRESS": "",
    "TABLE_HEADER"  : "",
    "TABLE_DATA"    : ""
};

const SchoolSecretaryReports: React.FC<any> = ({report, reportName}: any) => {
    const [ready, setReady]       = useState<boolean>(false);
    const [loadData, setLoadData] = useState<boolean>(false);
    const [schools, setSchools]   = useState<any>([]);
    const [school, setSchool]     = useState<any>(schoolBase);
    const [errors, setErrors]     = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);
    const { setModalOpen, setModalButton } = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLSelectElement>) {
        let { value } = event.target;

        // Set Data
        let scBase = schools.filter((el2: any) => Number(value) === el2.id)[0];
        if (!scBase) setSchool(schoolBase)
        else setSchool({id: scBase.id, name: Treats.formatNames(scBase.name), address: Treats.formatNames(scBase.address + (scBase.address_number ? ', ' + scBase.address_number : '') + ' - ' + scBase.address_neighborhood)})
    }

    function getContent () {
        if (loadData) return <div className="saving">Gerando relatório...</div>

        return <>
            <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()}>
                <div className="form-group no-margin">
                    <div className="inputLabel">
                        <label htmlFor="shools">Escola</label>
                        <select id="schools" onChange={onChange} defaultValue={school.id}>
                            <option value="0">-- Selecione uma escola --</option>
                            {
                                schools.map((el: any) => <option key={`school_${el.id}`} value={el.id}>{el.name}</option>)
                            }
                        </select>
                    </div>
                </div>
            </form>

            <div className="buttons">
                {errors && <div className="error">{errors}</div>}

                <button id="btnAdd" type="button" onClick={saveDataForm}>Gerar Relatório</button>
                <button id="btnCancel" type="button" onClick={() => setModalOpen(false)}>Cancelar</button>
            </div>
        </>
    }

    function treatYearsOld (date: any) {
        let yearsOld = Dates.getYearsOld(date);

        // Less than a year old
        if (yearsOld.years===0) return yearsOld.months + ' meses';
        else return yearsOld.years + ' ano' + (yearsOld.years>1 ? 's' : '')
    }

    function getGender (gender: string) {
        switch (gender) {
            case 'male':
                return 'Masculino';

            case 'female':
                return 'Feminino';

            default:
                return 'Não informado';
        }
    }

    function treatTeachers (teachers: any) {
        let ret: any = [];
        let teacher = 0;

        // Loop teachers
        let item: any = {id: 0, name: '', birth: '', gender: '', disciplines: []};
        teachers.map((el: any, idx: number) => {
            if (teacher === 0) {
                item = {id: el.id, name: el.name, birth: el.birth_date, gender: el.gender, disciplines: []};
                teacher = el.id;
            }  else if (teacher !== Number(el.id)) {
                ret.push(item);
                teacher = el.id;
                item = {id: el.id, name: el.name, birth: el.birth_date, gender: el.gender, disciplines: []};
            }

            if (!item.disciplines.includes(el.discipline)) {
                item.disciplines.push(el.discipline);
                item.disciplines.sort();
            }

            if (idx===(teachers.length-1)) ret.push(item);
        })

        return ret;
    }

    async function getTableInfos() {
        let infos: any = { header: [], data: [] };

        if (report === "students") {
            infos.header = [{ INFOS1: 'Nome', INFOS2: 'Idade', INFOS3: 'Ensino', INFOS4: 'Período', INFOS5: 'Classe' }];

            await api.get(`student/school/${school.id}`, {
                headers: { Authorization: user.token }
            }).then(async resp => {
                if (resp.data.length > 0) {
                    resp.data.map((el: any) => infos.data.push({
                        INFOS1: Treats.formatNames(el.name),
                        INFOS2: treatYearsOld(el.birth),
                        INFOS3: Treats.formatNames(el.school_class.teaching.name),
                        INFOS4: Treats.formatNames(el.school_class.shift.name),
                        INFOS5: `${Treats.formatNames(el.school_class.class_serie)} Série ${Treats.formatNames(el.school_class.class_acron)}`
                    }))
                }
            }).catch(errr => {
                console.log(errr)
            });
        } else if (report === "teachers") {
            infos.header = [{ INFOS1: 'Nome', INFOS2: 'Idade', INFOS3: 'Gênero', INFOS4: 'Disciplinas' }];

            await api.get(`teacher/school/${school.id}`, {
                headers: { Authorization: user.token }
            }).then(async resp => {
                if (resp.data.length > 0) {
                    let teachers = treatTeachers(resp.data);
                    teachers.map((el: any) => infos.data.push({
                        INFOS1: Treats.formatNames(el.name),
                        INFOS2: treatYearsOld(el.birth),
                        INFOS3: getGender(el.gender),
                        INFOS4: Treats.formatNames(el.disciplines.join(', '))
                    }))
                }
            }).catch(errr => {
                console.log(errr)
            });
        }

        return infos;
    }

    async function saveDataForm() {
        // Set errors false
        setErrors(false);

        // Get infos for table
        let infos: any = await getTableInfos();

        // Check if School is selected
        if (school.id === 0) {
            setErrors("A escola é obrigatório!")
            return false;
        }

        // Check if exists data
        if (infos.length === 0) {
            setErrors("Não há informações para o relatório!")
            return false;
        }

        // Set Loader
        setLoadData(true);
        setModalButton(false)

        // Get School data and name report
        let data            = dataBase;
        data.REPORT_NAME    = reportName;
        data.SCHOOL_NAME    = school.name;
        data.SCHOOL_ADDRESS = school.address;
        data.TABLE_HEADER   = infos.header;
        data.TABLE_DATA     = infos.data;

        // Get report
        api.post(`docs/school/schools_reports.${report}`, data, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then(async resp => {
            let url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
            window.open(url, reportName, "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=900,height=600");
            setLoadData(false);
            setModalButton(true)
            // setModalOpen(false);
        }).catch(errr => {
            console.log(errr)
        });
    }

    // Get Schools Data
    useEffect(() => {
        !ready && api.get('school', {
            headers: { Authorization: user.token }
        }).then(async resp => {
            setSchools(resp.data)
            setReady(true)
        }).catch(errr => {
            console.log(errr)
        });
    }, [ready])

    return ready ? getContent() : <></>
}

export default SchoolSecretaryReports;