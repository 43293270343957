import React, { useContext, useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa6';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import Loading from '~/components/Default/Loading';
import VisitTerm from './Modal/visit_term'
import VisitTermBox from './Box';

const SupervisionDirectionTermVisit: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [data, setData]   = useState<any>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalClass, setModalOpen, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(doc: string, data?: any) {
        if (doc === 'term_visit' && !data) {
            setModalClass('createVisitTerm')
            setModalTitle('Termo de Visita')
            setModalBody(<VisitTerm setReadyPage={setReady} />)
            setModalOpen(true)
        }
    }

    // Get page data
    useEffect(() => {
        // supervision_direction
        !ready && api.get(`supervision_direction/visit-term`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setData(resp.data);
            console.log(resp.data);
            setReady(true);
        }).catch(() => {
            setReady(true);
        });
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Termo de Visita") && setBreadcrumbs({
            curr: 'Termo de Visita',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Supervisão e Direção', url: '/supervisao-e-direcao'},
                { name: 'Termo' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Termo de Visita" pageTitle="Supervisão e Direção - Termo de Visita" className="supDirectionVisitTerm">
        {
            ready ? <>
                <HeaderSearchAndForm
                    buttons={[
                        { nameButton: "Novo Termo de Visita", onClick: () => { openModal('term_visit') }, className: "add", icons: <FaFilePdf /> }
                    ]}
                    isViewButton={true}
                    isViewSearch={false}
                />

                {
                    data.length > 0 ? (
                        <div className="documents">
                            {
                                data.map((el: any) => (
                                    <VisitTermBox key={`visitTerm_${el.id}`} {...el} />
                                ))
                            }
                        </div>
                     ) : <div className='alert'>Nenhum termo de visita localizado</div>
                }
            </> : <Loading />
        }
    </Template>;
}

export default SupervisionDirectionTermVisit;