import React, { useContext } from 'react';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import ParentsGradesUser from './User';
import ParentsGradesAdmin from './Admin';

const ParentsGrades: React.FC = () => {
    // Contexts
    const { user } = useContext(GeneralContext);

    return user.role === 'student' ? (
        <ParentsGradesUser />
    ) : (
        <ParentsGradesAdmin />
    );
}

export default ParentsGrades;