import { useContext, useEffect, useState } from 'react';
import Treats from '~/utils/treats';
import validateForm from '~/utils/validateForm';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Base Data
let dataBase = {
    "SCHOOL_NAME"   : "",
    "SCHOOL_ADDRESS": "",
    "TABLE"         : []
};

const ClassSheetsDoc: React.FC<any> = ({doc, reportName, school}: any) => {
    const [errors, setErrors] = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);
    const { setModalOpen } = useContext(ModalContext);

    async function saveDataForm (dataSave: any) {
        setErrors(false);

        // Get Teachers Data
        let data            = dataBase;
        data.SCHOOL_NAME    = Treats.formatNames(school.name);
        data.SCHOOL_ADDRESS = Treats.formatNames(school.address + (school.address_number ? ', ' + school.address_number : '') + ' - ' + school.address_neighborhood);

        // Check Errors
        if (validateForm.checkFields(data, ['TABLE'])) {
            setErrors('Todos os campos devem estar preenchidos')
            return false
        }

        // Treat Datas to Table and Set
        let itensData = dataSave.map((el: any) => ({
            SERIE: el.serie,
            PERIOD: getShiftName(el.period),
            STUDENTS: el.students ? el.students.length : 0,
            CLASS_TIME: el.class_time.init + ' - ' + el.class_time.end,
            DISCIPLINES: el.disciplines.length>0 ? el.disciplines.join(', ') : '-',
        }));

        data.TABLE = itensData;

        api.post(`docs/${doc}`, data, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then(async resp => {
            let url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
            window.open(url, reportName, "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=900,height=600");
            setModalOpen(false);
        }).catch(errr => {
            console.log(errr)
        });
    }

    function getShiftName (period: string) {
        switch (period) {
            case 'INTEGRAL':
                return 'Integral'
            case 'MANHA':
                return 'Matutino'
            case 'TARDE':
                return 'Vespertino'
            case 'NOITE':
                return 'Noturno'
            default:
                return 'Não Identificado'
        }
    }

    useEffect(() => {
        api.get(`school_classes/control/class/sheets/${school.id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            saveDataForm(resp.data)
        }).catch(errr => {
            console.log(errr)
        });
    }, [])

    return !errors ? <div className="saving">Gerando ficha...</div> : <div className="error">{errors}</div>
}

export default ClassSheetsDoc;