import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';

const TemplateSchoolSecretary: React.FC<any> = (props) => {
    const [ready, setReady] = useState(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [schools, setSchools] = useState<any[]>([]);
    const [students, setStudents] = useState<any[]>([]);

    // Contexts
    const { user, school, student, changeSchool, checkSchool, changeStudent, checkStudent } = useContext(GeneralContext);

    function onChangeSchool(e: React.ChangeEvent<HTMLSelectElement>) {
        let school = schools.filter((el: any) => el.id === Number(e.target.value))[0];
        changeSchool(school)
        changeStudent(null)
    }

    function onChangeStudent(e: React.ChangeEvent<HTMLSelectElement>) {
        let student = students.filter((el: any) => el.id === Number(e.target.value))[0];
        changeStudent(student)
    }

    function getSchoolsAndStudents() {
        return <>
            {
                schools.length > 0 ? <div className="schools">
                    <select id="schools" name="schools" onChange={onChangeSchool} defaultValue={school.id}>
                        {
                            schools.map((el: any) => <option
                                key={`school_${el.id}`}
                                value={el.id}
                            >
                                {el.name}
                            </option>)
                        }
                    </select>
                </div> : <></>
            }

            {
                students.length > 0 ? <div className="students">
                    <select id="students" name="students" onChange={onChangeStudent} defaultValue={student.id}>
                        {
                            students.map((el: any) => <option
                                key={`student_${el.id}`}
                                value={el.id}
                            >
                                {el.name}
                            </option>)
                        }
                    </select>
                </div> : <></>
            }

            {props.module && props.module}
        </>
    }

    async function loadStudents(school_id: number) {
        console.log('Aqui')
        await api.get(`student/school/${school_id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            const studentData = student ? resp.data.find((el: any) => el.id === Number(student.id)) : null;
            if (!student) changeStudent(resp.data[0]);
            else if (student && !student.ra_number && studentData) changeStudent(studentData);
            setStudents(resp.data)
        }).catch(errr => {
            console.log(errr)
        });
    }

    async function loadInfos() {
        try {
            const { data: schoolData } = await api.get('school', {
                headers: { Authorization: user.token },
            });

            // Set Schools Data
            setSchools(schoolData);

            // Check School is defined and set if not exit
            const selectedSchool = school || schoolData[0];
            if (!school) changeSchool(selectedSchool);

            // Load Students
            await loadStudents(selectedSchool.id);
        } catch (error) {
            console.error('Erro ao buscar escolas:', error);
        } finally {
            setReady(true);
        }
    }

    useEffect(() => {
        // Get schools
        !ready && loaded && loadInfos();
    }, [ready, loaded]);

    // Loading Context
    useEffect(() => {
        if (!school || !student) {
            checkSchool();
            checkStudent();
            setLoaded(true);
        } else if (school && student) {
            setLoaded(true);

        }
    }, [school, student]);

    return <Template page={props.page} pageTitle={props.pageTitle} className={props.className}>
        {
            ready ? <>
                <HeaderSearchAndForm
                    module={getSchoolsAndStudents()}
                    isViewButton={props.buttons ? true : false}
                    buttons={props.buttons ? props.buttons : undefined}
                    isViewSearch={props.search ? true : false}
                    search={props.search ? props.search : undefined}
                />

                { props.children }
            </> : <></>
        }
    </Template>;
}

export default TemplateSchoolSecretary;