import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '../Template';
import Loading from '~/components/Default/Loading';
import EnrollmentContent from './Content';

const ParentsEnrollment: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData]   = useState<any>(null);

    // Contexts
    const { user, student, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    function getContent() {
        if (error) return <div className='noData'>{error}</div>
        if (!data) return <div className='noData'>Não foi possível localizar dados do aluno</div>

        return <EnrollmentContent data={data} />
    }

    async function loadInfos() {
        student && student.ra_number && api.get(`student/enrollment/${student.ra_number}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setData(resp.data);
            setReady(true);
        }).catch(errr => {
            console.log(errr);
            setError('Não foi possível localizar as informações do Aluno');
            setReady(true);
        })
    }

    useEffect(() => {
        !ready && data===null && loadInfos();
    }, [])

    useEffect(() => {
        setReady(false);
        loadInfos();
    }, [student])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Matrícula") && setBreadcrumbs({
            curr: 'Matrícula',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Pais e Alunos', url: '/pais-e-alunos'},
                { name: 'Matrícula' }
            ]
        });
    }, [breadcrumbs]);

    return <Template
        page="Matrícula"
        pageTitle="Pais e Alunos - Matrícula"
        className="parentsEnrollment"
    >
        {ready ? getContent() : <Loading />}
    </Template>;
}

export default ParentsEnrollment;