import React, { useContext } from 'react';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import TeacherPlanningUser from './User';
import TeacherPlanningAdmin from './Admin';

const TeacherPlanning: React.FC = () => {
    // Contexts
    const { user } = useContext(GeneralContext);

    return user.employee_id ? (
        <TeacherPlanningUser />
    ) : (
        <TeacherPlanningAdmin />
    );
}

export default TeacherPlanning;
