import React, { useContext, useEffect } from 'react';
import { FaFilePdf } from 'react-icons/fa6';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import TeacherSheets from './Modal/teacher_sheets';
import ClassSheets from './Modal/class_sheets';
import ClassMirror from './Modal/class_mirror';
import TemplateSchoolSecretary from '../Template';

const SchoolSecretaryControls: React.FC = () => {
    // Contexts
    const { breadcrumbs, setBreadcrumbs, school } = useContext(GeneralContext);
    const { setModalClass, setModalOpen, setModalButton, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(doc: string, data?: any) {
        if (doc === 'control_sheets.teacher_sheets' && !data) {
            setModalClass('createDocs')
            setModalTitle('Ficha de Professores')
            setModalBody(<TeacherSheets doc={doc} reportName="Ficha de Professores" school={school} />)
            setModalButton(false)
            setModalOpen(true)
        } else if (doc === 'control_sheets.class_sheets' && !data) {
            setModalClass('createDocs')
            setModalTitle('Ficha de Turmas')
            setModalBody(<ClassSheets doc={doc} reportName="Ficha de Truma" school={school} />)
            setModalButton(false)
            setModalOpen(true)
        } else if (doc === 'control_sheets.class_mirror' && !data) {
            setModalClass('createDocs')
            setModalTitle('Espelho da Turma')
            setModalBody(<ClassMirror doc={doc} reportName="Espelho da Turma" school={school} />)
            setModalOpen(true)
        }
    }

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Controles") && setBreadcrumbs({
            curr: 'Controles',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Secretaria Escolar', url: '/secretaria-escolar'},
                { name: 'Controles' }
            ]
        });
    }, [breadcrumbs]);

    return <TemplateSchoolSecretary
        page="Controles"
        pageTitle="Secretaria Escolar - Controles"
        className="secEscDocs"
    >
        <div className="documents">
            <button className='btns decAttendance' onClick={() => openModal('control_sheets.teacher_sheets')}>
                <FaFilePdf size={28} />
                <span>Ficha de Professores</span>
            </button>

            <button className='btns decAttendance' onClick={() => openModal('control_sheets.class_sheets')}>
                <FaFilePdf size={28} />
                <span>Ficha da Turma</span>
            </button>

            <button className='btns decAttendance' onClick={() => openModal('control_sheets.class_mirror')}>
                <FaFilePdf size={28} />
                <span>Espelho da Turma</span>
            </button>
        </div>
    </TemplateSchoolSecretary>;
}

export default SchoolSecretaryControls;