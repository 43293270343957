import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBoxArchive, FaFileContract } from 'react-icons/fa6';
import numbers from '~/utils/numbers';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';

const Transport: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData]   = useState<any>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    // Loading Data
    useEffect(() => {
        !ready && api.get('dashboard/sec-school', {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setData(resp.data);
            setReady(true);
        }).catch((err: any) => {
            setError('Erro ao carregar os dados do dashboard')
            setReady(true)
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Secretaria Escolar") && setBreadcrumbs({
            curr: 'Secretaria Escolar',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Secretaria Escolar' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Secretaria Escolar" pageTitle="Secretaria Escolar" className="secEsc">
        {
            ready ? <>
                <div className="contentBox">
                    <div className="header">
                        <Link className="docsBox box" to="/secretaria-escolar/documentos">
                            <FaBoxArchive size={36} />
                            <small>Documentos</small>
                        </Link>

                        <Link className="reportsBox box" to="/secretaria-escolar/relatorios">
                            <FaFileContract size={36} />
                            <small>Relatórios</small>
                        </Link>

                        <div className='box schools'>
                            <h5>{ numbers.format(data.classes) }</h5>
                            <small>Classes</small>
                        </div>

                        <div className='box others'>
                            <h5>{ numbers.format(data.teachers) }</h5>
                            <small>Professores</small>
                        </div>

                        <div className='box'>
                            <h5>{ numbers.format(data.students) }</h5>
                            <small>Alunos</small>
                        </div>
                    </div>
                </div>
            </> : <Loading />
        }
    </Template>;
}

export default Transport;