import dateFormat from 'dateformat';

const dates = {
    getDate: function (date?: string | Date) {
        if (!date) date = new Date();

        let year  = dateFormat(date, 'yyyy'),
            month = dateFormat(date, 'mm'),
            day   = dateFormat(date, 'dd'),
            days  = new Date(Number(year), Number(month), 0).getDate();

        return {
            year, month, monthName: this.getNameMonth(Number(month)), day, days
        }
    },

    getDateFormatted: function (date: string | Date, format?: string) {
        return dateFormat(date, format ? format : 'dd-mm-yyyy');
    },

    getTimeFormatted: function (date: string, format?: string) {
        const dateCur  = new Date();
        const month    = dateCur.getMonth() + 1;
        const dateBase = `${dateCur.getFullYear()}-${(month<=9 ? '0' : '') + month}-${dateCur.getDate()} ${date}${date.length===5 ? ':00' : ''}`;

        return dateFormat(dateBase, format ? format : 'HH:MM:ss');
    },

    getDateDBFormatted: function (date: string, init?: boolean) {
        let nDate = new Date(date);

        let year  = dateFormat(nDate, 'yyyy'),
            month = dateFormat(nDate, 'mm'),
            day   = dateFormat(nDate, 'dd');

        return year + '-' + month + '-' + (init ? '01' : day) + 'T03:00:00.000Z';
    },

    getDateString: function (date: Date | string) {
        let year  = dateFormat(date, 'yyyy'),
            month = dateFormat(date, 'mm'),
            day   = dateFormat(date, 'dd');

        return {year, month, day};
    },

    getYearsOld: function (date: Date | string) {
        let currDate: any = new Date();
        let fromDate: any = new Date(date);

        const diffInMs   = currDate - fromDate;
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        let   years      = Math.floor(diffInDays / 365);
        let   restMonths = diffInDays - (years * 365);
        let   months     = Math.floor(restMonths / 30);
        let   days       = Math.floor(restMonths - (months * 30));

        return { years, months, days };
    },

    getWeekDay: function (date: string | Date) {
        let dateBase = new Date(date);

        switch (dateBase.getDay()) {
            case 1:
                return 'Segunda-Feira';

            case 2:
                return 'Terça-Feira';

            case 3:
                return 'Quarta-Feira';

            case 4:
                return 'Quinta-Feira';

            case 5:
                return 'Sexta-Feira';

            case 6:
                return 'Sábado';

            default:
                return 'Domingo';
        }
    },

    getWeekDays: function (date: string | Date) {
        let ret: any = [];

        // Set param date
        let d = typeof date === 'string' ? new Date(date + ' 03:00:00') : new Date(date);

        // Get week day
        let weekDay = d.getDay();

        // Define diff to Sunday
        let diff    = d.getDate() - weekDay + (weekDay === 0 ? -6 : 1);

        // Set Monday day
        let monday  = new Date(d.setDate(diff));

        // set Infos Date
        let year   = monday.getFullYear();
        let month  = ((monday.getMonth() + 1) <= 9 ? '0' : '') + (monday.getMonth() + 1);
        let day    = monday.getDate();

        for (let i = day; i < (day+5); i++) {
            let day = (i <= 9 ? '0' : '') + i;
            ret.push({
                week: this.getWeekDay(year + '-' + month + '-' + day + ' 03:00:00'),
                date: this.getDateFormatted(year + '-' + month + '-' + day + ' 03:00:00', 'dd/mm/yyyy')
            })
        }

        return ret;
    },

    checkWeekend: function (date: string) {
        let dateBase = new Date(date);
        return dateBase.getDay() === 0 || dateBase.getDay() === 6 ? true : false;
    },

    getNameMonth: function (month: number) {
        let monthArr = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ];


        return monthArr[month-1];
    },

    getShortNameMonth: function (month: number) {
        let monthArr = [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez"
        ];

        return monthArr[month-1];
    }
}

export default dates;