import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '../Template';
import Loading from '~/components/Default/Loading';
import dates from '~/utils/dates';
import { FaEye } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const curr    = dates.getDateFormatted(new Date(), 'dd/mm/yyyy');
const weekDay = dates.getDateFormatted(new Date(), 'yyyy-mm-dd');

const ParentsNutrition: React.FC = () => {
    const [ready, setReady]         = useState(false);
    const [error, setError]         = useState<string | null>(null);
    const [schedules, setSchedules] = useState<any>([]);
    const [tips, setTips]           = useState<any>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            { getSchedule() }
            { getTips() }
        </>
    }

    function getSchedule () {
        let days = dates.getWeekDays(new Date());

        return <div className="schedules">
            {
                days.map((el: any, idx: number) => {
                    let daySchedule = schedules.filter((el2: any) => el.date === dates.getDateFormatted(el2.date, 'dd/mm/yyyy'));
                    let infosDay    = daySchedule.length > 0 ? daySchedule[0].meals : [];

                    return <div
                        key={`sch_${idx}`}
                        className={`scheduleBox${curr === el.date ? ' curr' : ''}`}
                    >
                        <div className='week'>{el.week}</div>
                        <div className='date'>{el.date}</div>
                        <hr />
                        <div className='meals'>{infosDay.length > 0 ? infosDay.length + (infosDay.length > 1 ? ' Refeições' : ' Refeição') : '-'}</div>
                    </div>
                })
            }
        </div>
    }

    function getTips () {
        return <>
            <h3>Dicas de Nutrição</h3>

            <div className="tipsContent">
                {
                    tips.length > 0 ? <>
                        <div className="head">
                            <div className="title">Título</div>
                            <div className="author">Autor</div>
                            <div className="date">Publicação</div>
                            <div className="btn"></div>
                        </div>

                        <div className="tips">
                            {
                                tips.map((el: any) => <div
                                    key={`tip_${el.id}`}
                                    className="tipLine"
                                >
                                    <div className="title">{el.title}</div>
                                    <div className="author">{el.author ? el.author.name : '-'}</div>
                                    <div className="date">{dates.getDateFormatted(el.date, 'dd/mm/yyyy')}</div>
                                    <Link to={`/pais-e-alunos/nutricao/${el.slug}`} className="btn"><FaEye size={14} /></Link>
                                </div>)
                            }
                        </div>
                    </> : <div className="alertInfo">Nenhuma dica disponível</div>
                }
            </div>
        </>
    }

    async function loadInfos () {
        try {
            const [schedulesReponse, tipsReponse] = await Promise.all([
                api.get(`nutrition/schedule/week/${weekDay}`, { headers: { Authorization: user.token } }),
                api.get(`nutrition/tips`, { headers: { Authorization: user.token } }),
            ]);

            setSchedules(schedulesReponse.data);
            setTips(tipsReponse.data);
            setReady(true);
        } catch (error) {
            alert('Erro ao carregar informações do formulário')
            console.error('Erro ao carregar informações:', error);
        }
    }

    useEffect(() => {
        !ready && loadInfos();
    }, [])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Nutrição") && setBreadcrumbs({
            curr: 'Nutrição',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Pais e Alunos', url: '/pais-e-alunos'},
                { name: 'Nutrição' }
            ]
        });
    }, [breadcrumbs]);

    return <Template
        page="Nutrição"
        pageTitle="Pais e Alunos - Nutrição"
        className="parentsNutrition"
    >
        {ready ? getContent() : <Loading />}
    </Template>;
}

export default ParentsNutrition;