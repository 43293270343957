import React, { Fragment } from 'react';
import { FaChevronRight } from 'react-icons/fa6';
import Treats from '~/utils/treats';
import dates from '~/utils/dates';
import "./styles.css";

const ParentsContent: React.FC<any> = ({ data }: any) => {
    // Birth Infos
    const birthDate = dates.getDate(data.birth);
    const yearsOld  = dates.getYearsOld(data.birth);

    function checkSpecialNeeds() {
        if (
            data.deficiency.reduced_mobility ||
            data.deficiency.school_support ||
            data.deficiency.school_support_food ||
            data.deficiency.school_support_hygiene ||
            data.deficiency.school_support_locomotion
        ) {
            return true;
        }

        return false;
    }

    function checkSpecialNeedsDates() {
        if (
            data.deficiency.start_term_activities_daily &&
            data.deficiency.end_term_activities_daily &&
            data.deficiency.start_term_activities_daily &&
            data.deficiency.end_term_school_activities
        ) {
            return true;
        }

        return false;
    }

    return <>
        <h3>Informações do Aluno</h3>
        <div className="line">
            <div className="box name">
                <small>Nome Completo:</small>
                <span>{Treats.formatNames(data.name)}</span>
            </div>

            <div className="box ra">
                <small>RA:</small>
                <span>{data.ra_number}</span>
            </div>

            <div className="box birth">
                <small>Data de Nascimento:</small>
                <span>{birthDate.day} de {birthDate.monthName} de {birthDate.year}</span>
            </div>

            <div className="box age">
                <small>Idade:</small>
                <span>{yearsOld.years > 0 ? yearsOld.years + ' ano' + (yearsOld.years>1 ? 's' : '') : yearsOld.months + (yearsOld.months>1 ? ' meses' : ' mês')}</span>
            </div>
        </div>

        <div className="line">
            <div className="box birth_local">
                <small>Local de Nascimento:</small>
                <span>{Treats.formatNames(data.birth_city)}/{data.birth_uf}</span>
            </div>

            <div className="box nationality">
                <small>Nacionalidade:</small>
                <span>{Treats.formatNames(data.nationality)}</span>
            </div>

            <div className="box cpf">
                <small>CPF:</small>
                <span>{data.docs.cpf ? Treats.formatCPF(data.docs.cpf) : 'Não Informado'}</span>
            </div>

            <div className="box cns">
                <small>Cartão Nacional de Saúde:</small>
                <span>{data.docs.cns_number ? data.docs.cns_number : 'Não Informado'}</span>
            </div>
        </div>

        <div className="line">
            <div className="box sex">
                <small>Gênero:</small>
                <span>{Treats.formatNames(data.sex)}</span>
            </div>

            <div className="box blood_type">
                <small>Tipo Sanguínio:</small>
                <span>{data.blood_type ? data.blood_type : 'Não Informado'}</span>
            </div>

            <div className="box organ_donor">
                <small>Doador de Orgãos?</small>
                <span>{data.organ_donor ? 'Sim' : 'Não'}</span>
            </div>

            <div className="box color_race">
                <small>Raça/Cor:</small>
                <span>{data.color_race ? Treats.formatNames(data.color_race) : 'Não Declarada'}</span>
            </div>

            <div className="box quilombola">
                <small>Quilombola?</small>
                <span>{data.quilombola ? 'Sim' : 'Não'}</span>
            </div>
        </div>

        {
            checkSpecialNeeds() ? <div className="defBox">
                <h4>Possui Necessidades Especiais?</h4>

                <div className="lines">
                    {
                        checkSpecialNeedsDates() ? <div className="dates">
                            <div className="dailyInit">
                                <small>Data Inicial do Suporte Diário</small>
                                <span>{dates.getDateFormatted(data.deficiency.start_term_activities_daily, 'dd/mm/yyyy')}</span>
                            </div>

                            <div className="dailyEnd">
                            <small>Data Final do Suporte Diário</small>
                                <span>{dates.getDateFormatted(data.deficiency.start_term_activities_daily, 'dd/mm/yyyy')}</span>
                            </div>

                            <div className="schoolInit">
                                <small>Data Inicial do Suporte na Escola</small>
                                <span>{dates.getDateFormatted(data.deficiency.start_term_activities_daily, 'dd/mm/yyyy')}</span>
                            </div>

                            <div className="schoolEnd">
                                <small>Data Final do Suporte na Escola</small>
                                <span>{dates.getDateFormatted(data.deficiency.start_term_activities_daily, 'dd/mm/yyyy')}</span>
                            </div>
                        </div> : <></>
                    }

                    {
                        data.deficiency.reduced_mobility ? <div className="line mobility">
                            <FaChevronRight size={12} /> <span>Possui Redução de Mobilidade ({data.deficiency.reduced_mobility_type})</span>
                        </div> : <></>
                    }

                    {
                        data.deficiency.school_support ? <div className="line def school">
                            <FaChevronRight size={12} /> <span>Necessita Suporte da Escola</span>
                        </div> : <></>
                    }

                    {
                        data.deficiency.school_support_food ? <div className="line def food_support">
                            <FaChevronRight size={12} /> <span>Necessita Suporte para Alimentação</span>
                        </div> : <></>
                    }

                    {
                        data.deficiency.school_support_hygiene ? <div className="line def hygiene_support">
                            <FaChevronRight size={12} /> <span>Necessita Suporte para Higiêne</span>
                        </div> : <></>
                    }

                    {
                        data.deficiency.school_support_locomotion ? <div className="line def locomotion_support">
                            <FaChevronRight size={12} /> <span>Necessita Suporte para Locomoção</span>
                        </div> : <></>
                    }
                </div>
            </div> : <></>
        }

        <hr />

        <h3>Informações da Família</h3>
        <div className="line">
            <div className="box address">
                <small>Endereço:</small>
                <span>{Treats.formatNames(data.address.address)}{data.address.number ? ', ' + data.address.number : ''}</span>
            </div>

            <div className="box neighborhood">
                <small>Bairro:</small>
                <span>{Treats.formatNames(data.address.neighborhood)}</span>
            </div>

            <div className="box cep">
                <small>CEP:</small>
                <span>{data.address.cep ? Treats.formatCEP(data.address.cep) : 'Não Informado'}</span>
            </div>

            <div className="box city">
                <small>Cidade/Estado:</small>
                <span>{Treats.formatNames(data.address.city)}/{data.address.uf}</span>
            </div>
        </div>

        <div className="line">
            <div className="box mother">
                <small>Nome da Mãe:</small>
                <span>{data.mother_name ? Treats.formatNames(data.mother_name) : 'Não Informado'}</span>
            </div>

            <div className="box father">
                <small>Nome do Pai:</small>
                <span>{data.father_name ? Treats.formatNames(data.father_name) : 'Não Informado'}</span>
            </div>
        </div>

        <div className="line">
            <div className="box internet">
                <small>Possui Internet em Casa?</small>
                <span>{data.internet ? 'Sim' : 'Não'}</span>
            </div>

            <div className="box family_bag">
                <small>Possui Bolsa Família?</small>
                <span>{data.family_bag ? 'Sim' : 'Não'}</span>
            </div>

            <div className="box family_bag_cod">
                <small>Código do Bolsa Família:</small>
                <span>{data.family_bag ? data.family_bag_cod : '-'}</span>
            </div>
        </div>

        <hr />

        <h3>Informações Escolares</h3>
        <div className="line">
            <div className="box school">
                <small>Escola:</small>
                <span>{Treats.formatNames(data.school.name)}</span>
            </div>

            <div className="box school_network">
                <small>Rede:</small>
                <span>{Treats.formatNames(data.school.network.name)}</span>
            </div>
        </div>

        <div className="line">
            <div className="box school_class">
                <small>Classe:</small>
                <span>{data.school_class.class_serie} Ano {data.school_class.class_acron.toUpperCase()}</span>
            </div>

            <div className="box school_year">
                <small>Ano Letivo:</small>
                <span>{data.school_class.school_year}</span>
            </div>

            <div className="box school_period">
                <small>Período:</small>
                <span>{Treats.formatNames(data.school_class.shift.name)}</span>
            </div>

            <div className="box school_teaching">
                <small>Tipo:</small>
                <span>{Treats.formatNames(data.school_class.teaching.name)}</span>
            </div>
        </div>

        <div className="line">
            <div className="box school_address">
                <small>Endereço:</small>
                <span>{Treats.formatNames(data.school.address)}{data.school.address_number ? ', ' + data.school.address_number : ''}</span>
            </div>

            <div className="box school_neighborhood">
                <small>Bairro:</small>
                <span>{Treats.formatNames(data.school.address_neighborhood)}</span>
            </div>

            <div className="box school_cep">
                <small>CEP:</small>
                <span>{data.school.address_cep ? Treats.formatCEP(data.school.address_cep) : 'Não Informado'}</span>
            </div>

            <div className="box school_city">
                <small>Cidade:</small>
                <span>{Treats.formatNames(data.school.address_city)}</span>
            </div>
        </div>
    </>
}

export default ParentsContent;