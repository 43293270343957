import React from 'react';
import dates from '~/utils/dates';

const ActivityLine: React.FC<any> = (props: any) => {
    return <div className="activityLine">
        <div className="school">
            <small>Escola</small>
            <span>{ props.schoolClass.school.name  }</span>
        </div>

        <div className="classs">
            <small>Classe</small>
            <span>{props.schoolClass.class_serie}ª Série{ props.schoolClass.class_acron ? ' ' + props.schoolClass.class_acron.toUpperCase() : '' }</span>
        </div>

        <div className="date">
            <small>Data</small>
            <span>{dates.getDateFormatted(props.date_end + ' 03:00:00', 'dd/mm/yyyy')}</span>
        </div>

        <div className="activity">
            <small>Atividade</small>
            <span>{ props.title }</span>
        </div>
    </div>;
}

export default ActivityLine;
