import React, { useContext, useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa6';
import Treats from '~/utils/treats';
import Dates from '~/utils/dates';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import TemplateSupDir from '../Template';
import Modal from './Modal';

const SupervisionDirectionTeachersReports: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [data, setData]   = useState<any>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs, school } = useContext(GeneralContext);
    const { setModalClass, setModalOpen, setModalButton, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal (data: any) {
        let tableHeader = [{ INFOS1: 'Nome', INFOS2: 'Idade', INFOS3: 'Gênero', INFOS4: 'Disciplinas' }];

        setModalClass('createRepTeachers')
        setModalTitle('Relatório de Professores')
        setModalBody(<Modal doc="schools_reports.teachers" reportName="Relatório de Professores" school={school} tableHeader={tableHeader} tableData={data} />)
        setModalButton(false)
        setModalOpen(true)
    }

    function generateReport() {
        let dataTable: any = [];

        data.map((el: any) => {
            dataTable.push({
                INFOS1: Treats.formatNames(el.name),
                INFOS2: treatYearsOld(el.birth),
                INFOS3: getGender(el.gender),
                INFOS4: Treats.formatNames(el.disciplines.join(', '))
            })
        })

        openModal(dataTable)
    }

    function getGender (gender: string) {
        switch (gender) {
            case 'male':
                return 'Masculino';

            case 'female':
                return 'Feminino';

            default:
                return 'Não informado';
        }
    }

    function treatYearsOld (date: any) {
        let yearsOld = Dates.getYearsOld(date);

        // Less than a year old
        if (yearsOld.years===0) return yearsOld.months + ' meses';
        else return yearsOld.years + ' ano' + (yearsOld.years>1 ? 's' : '')
    }

    function treatTeachers (teachers: any) {
        let ret: any = [];
        let teacher = 0;

        // Loop teachers
        let item: any = {id: 0, name: '', birth: '', gender: '', disciplines: []};
        teachers.map((el: any, idx: number) => {
            if (teacher === 0) {
                item = {id: el.id, name: el.name, birth: el.birth_date, gender: el.gender, disciplines: []};
                teacher = el.id;
            }  else if (teacher !== Number(el.id)) {
                ret.push(item);
                teacher = el.id;
                item = {id: el.id, name: el.name, birth: el.birth_date, gender: el.gender, disciplines: []};
            }

            if (!item.disciplines.includes(el.discipline)) {
                item.disciplines.push(el.discipline);
                item.disciplines.sort();
            }

            if (idx===(teachers.length-1)) ret.push(item);
        })

        return ret;
    }

    // Get page data
    useEffect(() => {
        !ready && school && api.get(`teacher/school/${school.id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            let teacherData = treatTeachers(resp.data);
            setData(teacherData);
            setReady(true);
        }).catch(() => {
            setReady(true);
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Relatório de Professores") && setBreadcrumbs({
            curr: 'Relatório de Professores',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Supervisão e Direção', url: '/supervisao-e-direcao'},
                { name: 'Professores' }
            ]
        });
    }, [breadcrumbs]);

    return <TemplateSupDir
        page="Relatório de Professores"
        pageTitle="Supervisão e Direção - Relatório de Professores"
        className="supDirTeacher"
        buttons={[
            {
                icons: <FaFilePdf size={14} />,
                title: "Exportar",
                nameButton: "Exportar",
                className: "report add",
                onClick: () => generateReport()
            }
        ]}
    >
        {
            data.length>0 ? (
                <div className="report">
                    <div className="header">
                        <div className="name">Nome</div>
                        <div className="age">Idade</div>
                        <div className="gender">Gênero</div>
                        <div className="disciplines">Disciplinas</div>
                    </div>

                    {
                        ready && data.map((el: any) => <div key={`teach_${el.id}`} className='line'>
                            <div className="name">{Treats.formatNames(el.name)}</div>
                            <div className="age">{treatYearsOld(el.birth)}</div>
                            <div className="gender">{getGender(el.gender)}</div>
                            <div className="disciplines">{Treats.formatNames(el.disciplines.join(', '))}</div>
                        </div>)
                    }
                </div>
            ) : <div className='alert'>Nenhum professor localizado</div>
        }
    </TemplateSupDir>;
}

export default SupervisionDirectionTeachersReports;