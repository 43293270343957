import React, { useContext } from 'react';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import ParentsUser from './User';
import ParentsAdmin from './Admin';

const Parents: React.FC = () => {
    // Contexts
    const { user } = useContext(GeneralContext);

    return user.role === 'student' ? (
        <ParentsUser />
    ) : (
        <ParentsAdmin />
    );
}

export default Parents;