import React from 'react';
import { InputLabel } from '~/components/Forms';

const WorkInfoTab: React.FC<any> = ({ errors, onChange, data, position, onChangeCheckbox, positions, permissions, units }: any) => {
    console.log("🚀 ~ data:", data)
    return <>
        <div className="formGroup nomg">
            <div className="formItem w20">
                <label htmlFor="position_id">Cargo</label>
                <select onChange={onChange} id="position_id" name="position_id" value={data.position_id} defaultValue={data.position_id} disabled={position ? true : false} required>
                    <option></option>
                    {
                        positions.map((el: any) => <option key={'position_' + el.position.id} value={el.position.id}>{el.position.name}</option>)
                    }
                </select>
            </div>
            <div className="formItem w20">
                <label htmlFor="rules_category_id">Permissões do usuário</label>
                <select onChange={onChange} id="rules_category_id" name="rules_category_id" value={data.rules_category_id} defaultValue={data.rules_category_id} required>
                    <option></option>
                    {
                        permissions.map((el: any) => <option key={'rules_category_' + el.id} value={el.id}>{el.name}</option>)
                    }
                </select>
            </div>

            <div className="formItem w20">
                <InputLabel
                    name="department"
                    type='text'
                    labelName='Departamento/Setor'
                    value={data?.department}
                    error={errors && errors['department']}
                    onChange={onChange}
                />
            </div>
            <div className="formItem w40">
                <label htmlFor="work_location">Local de trabalho</label>
                <select onChange={onChange} id="work_location" name="work_location" value={data.work_location} defaultValue={data.work_location} required>
                    <optgroup label="Demais unidades">
                        <option></option>
                        {
                            units.otherUnits.map((el: any) => <option key={'other_' + el.id} value={'other_'+el.id}> {el.name} </option>)
                        }
                    </optgroup>
                    <optgroup label="Escolas">
                        {
                            units.schools.map((el: any) => <option key={'other_' + el.id} value={'school_'+el.id}> {el.name} </option>)
                        }
                    </optgroup>
                </select>
            </div>
        </div>

        <div className="formGroup">
            <div className="formItem w15">
                <InputLabel
                    name="admission_date"
                    type='date'
                    labelName='Data de Admissão'
                    value={data?.admission_date}
                    error={errors && errors['admission_date']}
                    onChange={onChange}
                />
            </div>

            <div className="formItem w15">
                <InputLabel
                    name="contract_end_date"
                    type='date'
                    labelName='Data de Demissão'
                    value={data?.contract_end_date}
                    error={errors && errors['contract_end_date']}
                    onChange={onChange}
                />
            </div>

            <div className="formItem w30">
                <label htmlFor="contract_type">Tipo de Contrato</label>
                <select id="contract_type" name="contract_type" onChange={onChange} value={data.contract_type} defaultValue={data.contract_type} required>
                    <option value="CLT">CLT</option>
                    <option value="PJ">PJ (Pessoa Jurídica)</option>
                    <option value="Internship">Estagiário</option>
                </select>
            </div>

            <div className="formItem w40">
                <InputLabel
                    name="contract_number"
                    type='number'
                    labelName='Número do contrato'
                    value={data?.contract_number}
                    error={errors && errors['contract_number']}
                    onChange={onChange}
                />
            </div>
        </div>

        <div className="formGroup">
            <div className="formItem w1-4">
                <InputLabel
                    name="work_start_time"
                    type='time'
                    labelName='Horário de entrada'
                    value={data?.work_start_time}
                    error={errors && errors['work_start_time']}
                    onChange={onChange}
                />
            </div>

            <div className="formItem w1-4">
                <InputLabel
                    name="work_end_time"
                    type='time'
                    labelName='Horário de saída'
                    value={data?.work_end_time}
                    error={errors && errors['work_end_time']}
                    onChange={onChange}
                />
            </div>
            <div className="formItem w1-4">
            <label htmlFor="work_hours">Período de trabalho</label>
                <select id="work_hours" name="work_hours" onChange={onChange} value={data.work_hours} defaultValue={data.work_hours}required>
                    <option value="Full-time">Tempo integral</option>
                    <option value="Part-time">Meio Período</option>
                </select>
            </div>

        </div>

        <div className="formGroup">
            <div className="formItem wauto line">
                <label htmlFor="is_temporary">É temporário?</label>
                <input onChange={onChangeCheckbox} type="checkbox" name="is_temporary" />
            </div>
        </div>
        <div className="formGroup">
            <div className="formItem wauto line">
                <label htmlFor="login_flag">O funcionário terá acesso à plataforma? </label>
                <input onChange={onChangeCheckbox} type="checkbox" name="login_flag" />
            </div>
        </div>
        {data.login_flag === true && <p style={{ width:'100%', color:"blue" }}>A senha de acesso será o número do CPF, sem pontos e sem traço.</p>}
    </>;
};

export default WorkInfoTab;
