import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { FaHome, FaSignOutAlt } from 'react-icons/fa';
import { FaUser } from 'react-icons/fa6';
import { hasPermission } from '~/utils/permissions';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import SecretaryEducation from './secretaryEducation';
import SchoolSecretary from './schoolSecretary';
import Teacher from './teacher';
import TeacherRole from './teacherRole';
import Parents from './parents';
import ParentsRole from './parentsRole';
import SupervisionDirection from './direction';
import Transport from './transport';
import Nutrition from './nutrition';
import Warehouse from './warehouse';
import WarehouseSchool from './warehouseSchool';

// Images
import logo from '~/assets/images/4educ_logo3.png';
import userProfile from '~/assets/images/user.jpg';

// Get Version
const version = require('~/../package.json').version;

const Menu: React.FC = () => {
    const location = useLocation();
    const { user } = useContext(GeneralContext);

    function getMenu () {
        if (user.employee_id) return <TeacherRole />
        else if (user.role === 'student') return <ParentsRole />

        return <>
            {
                hasPermission(user.permissions, user.role, 'dashboard') && (
                    <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
                        <FaHome />
                    <span>Dashboard</span>
                </Link>)
            }

            {
                hasPermission(user.permissions, user.role, 'secretaria-educacao') && (
                    <SecretaryEducation />
                )
            }

            {
                hasPermission(user.permissions, user.role, 'secretaria-escolar') && (
                    <SchoolSecretary />
                )
            }

            {
                hasPermission(user.permissions, user.role, 'professor') && (
                    <Teacher />
                )
            }

            {
                hasPermission(user.permissions, user.role, 'pais-e-alunos') && (
                    <Parents />
                )
            }

            {
                hasPermission(user.permissions, user.role, 'supervisao-e-direcao') && (
                    <SupervisionDirection />
                )
            }

            {
                hasPermission(user.permissions, user.role, 'transporte') && (
                    <Transport />
                )
            }

            {
                hasPermission(user.permissions, user.role, 'nutricao') && (
                    <Nutrition />
                )
            }

            {
                hasPermission(user.permissions, user.role, 'almoxarifado') && (
                    <Warehouse />
                )
            }

            {
                hasPermission(user.permissions, user.role, 'secretaria-escolar') && (
                    <WarehouseSchool />
                )
            }
        </>
    }

    return (
        <aside>
            <div className="logo">
                <Link to='/'>
                    <img src={logo} alt="Energizou"/>
                </Link>
            </div>

            <div className="userBox">
                <div className="foto">
                    <img src={userProfile} alt="Foto do Usuário" />
                </div>

                <div className="infos">
                    <div className="username">{ user.name }</div>
                    <div className="role">{ user.role }</div>
                </div>

                <Link to="/perfil" data-tip="tooltip" data-for="profile">
                    <FaUser size={14} />
                </Link>
                <ReactTooltip id="profile" place="right" effect="solid" type="info">Perfil</ReactTooltip>
            </div>

            <nav>
                { getMenu() }
            </nav>

            <footer>
                <div>
                    <Link to="/logout" className='logout'>
                        <FaSignOutAlt />
                    </Link>
                </div>

                <span>Version {version}</span>
            </footer>
        </aside>
    );
}

export default Menu;
