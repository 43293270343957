import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import FormTransfer from './Modal';
import DeleteTransfer from './Modal/delete';
import TransferBox from './Box';

const SecretaryEducationEnrollment: React.FC = () => {
    const [ready, setReady]                 = useState(false);
    const [transfers, setTransfers]         = useState<any>(null);
    const [transfersList, setTransfersList] = useState<any>([]);
    const [error, setError]                 = useState<string | null>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        if (type==="New") {
            setModalClass('transAddEdit')
            setModalTitle('Adicionar Nova Transferência')
            setModalBody(<FormTransfer setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type==="Edit") {
            setModalClass('transAddEdit')
            setModalTitle('Editar Transferência')
            setModalBody(<FormTransfer data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type==="Delete") {
            setModalClass('transDelete')
            setModalTitle('Remover Transferência')
            setModalBody(<DeleteTransfer data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        }

        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <HeaderSearchAndForm
                buttons={[{
                    nameButton: "Nova Transferência",
                    className: "add",
                    onClick: () => {openModal("New")}
                }]}
                search={{
                    dataList: transfersList,        // Substitua 'yourDataList' pela sua lista de dados
                    filterKey: ["schoolRequester.name", "schoolRequired.name", "student.name"],    // Substitua 'nome' pela chave que você deseja filtrar
                    setFilteredList: setTransfers   // Substitua 'setYourList' pela função que define a lista filtrada
                }}
                isViewButton={true}
                isViewSearch={true}
            />

            {
                transfers && transfers.length > 0 ? (
                    <div className="transfers">
                        {
                            transfers.map((transferData: any) => (
                                <TransferBox
                                    key={'transfer_' + transferData.id}
                                    data={transferData}
                                    openModal={openModal}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhuma transferência</div>
            }
        </>
    }

    // Get page data
    useEffect(() => {
        !ready && api.get('transfer', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setTransfers(resp.data)
            setTransfersList(resp.data)
            setReady(true)
        }).catch(err => {
            setError('Erro ao carregar os dados das escolas')
            setReady(true)
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Transferências") && setBreadcrumbs({
            curr: 'Transferências',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Secretaria da Educação', url: '/secretaria-educacao'},
                { name: 'Transferências' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Transferências" pageTitle="Secretaria da Educação - Transferências - Lista de Espera" className="secEducTransfers">
        {ready ? getContent() : <Loading />}
    </Template>
}

export default SecretaryEducationEnrollment;