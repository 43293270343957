import React from 'react';
import './styles.css';

const PlanningInfos: React.FC<any> = ({ data }) => {

    if(data.cycle ===  "1"){ return <>
        <h3>Código {data.planningSkillId}</h3>
        <p><b>Descrição da habilidade:</b> {data.planningSkillDesc}</p>
        <p><b>Ciclo:</b> {data.cycle}</p>
        <p><b>Ano:</b> {data.series}</p>
        <p><b>Bimestre:</b> {data.bimester}</p>
        <p><b>Objeto de conhecimento:</b> {data.objectKnowDesc}</p>
        <p><b>Unidade temática:</b> {data.thematicUnitDesc}</p>
        {data.tasks.map((el: any, idx: number) => <>
            <hr />
            <p><b>Modalidades organizativas {idx+1}:</b> {el.taskOrganizationalModalities}</p>
            <p><b>Materiais usados {idx+1}:</b> {el.taskMaterialsUsed}</p>
            <p><b>Referências {idx+1}:</b> {el.taskReferences}</p>
            <p><b>Instrumentos avaliativos {idx+1}:</b> {el.taskEvaluationInstrument}</p>
        </>)}
    </>;
    }
    return <>
        <h3>Código {data.planningSkillId}</h3>
        <p><b>Descrição da habilidade:</b> {data.planningSkillDesc}</p>
        <p><b>Ciclo:</b> {data.cycle}</p>
        <p><b>Ano:</b> {data.series}</p>
        <p><b>Bimestre:</b> {data.bimester}</p>
        <p><b>Campo de experiência:</b> {data.fieldsExperienceDesc}</p>
        {data.tasks.map((el: any, idx: number) => <>
            <hr />
            <p><b>Abordagem {idx+1}:</b> {el.taskApproach}</p>
            <p><b>Contexto {idx+1}:</b> {el.taskContextPlanning}</p>
            <p><b>Sessão {idx+1}:</b> {el.taskSessionPlanning}</p>
        </>)}
    </>;
}

export default PlanningInfos;