import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Treats from '~/utils/treats';
import dates from '~/utils/dates';
import Numbers from '~/utils/numbers';
import "./styles.css";

const types = [
    { type: 'Breakfast', name: 'Café da Manhã' },
    { type: 'Lunch', name: 'Almoço' },
    { type: 'Snack', name: 'Lanche' },
    { type: 'Dinner', name: 'Jantar' },
    { type: 'Supper', name: 'Ceia' }
]

const ParentsContent: React.FC<any> = ({ data }: any) => {
    function getNutritionType (type: string) {
        let mealName = types.filter(el2 => el2.type === type);
        let meal = mealName.length > 0 ? mealName[0].name : 'Não Identificado';
        return meal;
    }

    return <>
            <div className="head">
                <div className="box name">
                    {Treats.formatNames(data.student.name)}
                </div>

                <div className="box presences">
                    <small>Presenças:</small>
                    <span>{ data.presence.presences ? data.presence.presences : '-' }</span>
                </div>

                <div className="box faults">
                    <small>Faltas:</small>
                    <span>{ data.presence.faults ? data.presence.faults : '-' }</span>
                </div>

                <div className={`box avarage ${data.avarage ? (data.avarage>5 ? 'upper' : 'down') : ''}`}>
                    <small>Média:</small>
                    <span>{ data.avarage ? Numbers.format(data.avarage, true, 1) : '-' }</span>
                </div>
            </div>

            <div className="contentBox">
                <div className="nutrition">
                    <div className="header">
                        <h4>Cardápio de Hoje</h4>
                        <small>02 de Fevereiro de 2025</small>
                    </div>

                    <div className="infos">
                        {
                            data.nutrition.length > 0 ? (
                                data.nutrition.map((el: any, idx: number) => el.type ? <Fragment key={`sch_${idx}`}>
                                    {idx>0 ? <hr /> : <></>}
                                    <div className='box'>
                                        <small>{ getNutritionType(el.type) }</small>
                                        <h5>{ Treats.formatNames(el.name) }</h5>
                                        <div className="description">{ el.description }</div>
                                    </div>
                                    </Fragment> : <></>)
                            ) : <div className='alertInfo'>Nenhum cardápio cadastrado</div>
                        }
                    </div>

                    <Link to="/pais-e-alunos/nutricao" className='nutBtn'>
                        Ir para Nutrição
                    </Link>
                </div>

                <div className="nextActivities">
                    <div className="header">
                        <h4>Próximas Atividades</h4>

                        <Link to="/pais-e-alunos/notas" className='nutBtn'>
                            Ir para Notas e Atividades
                        </Link>
                    </div>

                    <div className="activitiesBox">
                        <div className="header">
                            <div className="type">Tipo</div>
                            <div className="date">Data</div>
                            <div className="title">Actividade</div>
                        </div>

                        <div className="activitiesContent">
                            {
                                data.activities.length > 0 ? data.activities.map((el: any) => {
                                    return <div key={`act_${el.id}`} className="line">
                                        <div className="type">{el.weight ? 'Prova' : 'Atividade'}</div>
                                        <div className="date">{dates.getDateFormatted(el.date_end, 'dd/mm/yyyy')}</div>
                                        <div className="title">{el.title}</div>
                                    </div>
                                }) : <div className='alertInfo'>Nenhum atividade cadastrada</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
}

export default ParentsContent;