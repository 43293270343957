import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import TemplateTeacher from '../Template';
import Loading from '~/components/Default/Loading';
import Infos from './Box/infos';
import PlanningContent from './Content'

const TeacherPlanning: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [plans, setPlans] = useState<any>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    // Contexts
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal (props: any) {
        setModalClass('planningInfos')
        setModalTitle('Visualizando planejamento ' + props.planningSkillId)
        setModalBody(<Infos data={props} />)

        setModalOpen(true);
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>
        if (!plans) return <div className='noData'>Não foi possível localizar nenhum plano de aula</div>

        return <PlanningContent plans={plans} openModal={openModal} />
    }

    // Get page data
    useEffect(() => {
        !ready && api.get('teacher/planning/all', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setReady(true);
            setPlans(resp.data.plannings);
        }).catch(err => {
            setError('Erro ao carregar os dados dos planos de aula');
            setReady(true);
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Planejamento de Aulas") && setBreadcrumbs({
            curr: 'Planejamento de Aulas',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Professor', url: '/professor'},
                { name: 'Planejamento' }
            ]
        });
    }, [breadcrumbs]);

    return <TemplateTeacher
        page="Planejamento de Aulas"
        pageTitle="Professor - Planejamento de Aulas"
        className="teacherPlanning"
        buttons={[{ nameButton: "Criar Planejamento",  url: "cadastro", className:"add"}]}
    >
        { ready ? getContent() : <Loading /> }
    </TemplateTeacher>;
}

export default TeacherPlanning;