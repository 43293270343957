import React, { useContext } from 'react';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import TeacherStudentsUser from './User';
import TeacherStudentsAdmin from './Admin';

const TeacherStudents: React.FC = () => {
    // Contexts
    const { user } = useContext(GeneralContext);

    return user.employee_id ? (
        <TeacherStudentsUser />
    ) : (
        <TeacherStudentsAdmin />
    );
}

export default TeacherStudents;