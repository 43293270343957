import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaBoxArchive, FaGraduationCap, FaRightLeft, FaSliders, FaUsersLine } from 'react-icons/fa6';
import { FaAddressCard, FaFileContract } from 'react-icons/fa';
import { hasPermission } from '~/utils/permissions';
import { GeneralContext } from '~/contexts';

const SchoolSecretary: React.FC = () => {
    const { user } = useContext(GeneralContext);
    const location = useLocation();

    function submenu () {
        return (
            <div className='submenu'>
                {
                    hasPermission(user.permissions, user.role, 'secretaria-escolar', 'turmas') && (
                        <Link to="/secretaria-escolar/turmas" className={location.pathname.indexOf('/secretaria-escolar/turmas')>=0 ? 'active' : ''}>
                            <FaUsersLine />
                            <span>Turmas</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'secretaria-escolar', 'alunos') && (
                        <Link to="/secretaria-escolar/alunos" className={location.pathname.indexOf('/secretaria-escolar/alunos')>=0 ? 'active' : ''}>
                            <FaAddressCard />
                            <span>Alunos</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'secretaria-escolar', 'documentos') && (
                        <Link to="/secretaria-escolar/documentos" className={location.pathname === '/secretaria-escolar/documentos' ? 'active' : ''}>
                            <FaBoxArchive />
                            <span>Documentos</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'secretaria-escolar', 'controle') && (
                        <Link to="/secretaria-escolar/controle" className={location.pathname === '/secretaria-escolar/controle' ? 'active' : ''}>
                            <FaSliders />
                            <span>Controle</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'secretaria-escolar', 'relatorios') && (
                        <Link to="/secretaria-escolar/relatorios" className={location.pathname === '/secretaria-escolar/relatorios' ? 'active' : ''}>
                            <FaFileContract />
                            <span>Relatórios</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'secretaria-escolar', 'transferencias') && (
                        <Link to="/secretaria-escolar/transferencias" className={location.pathname === '/secretaria-escolar/transferencias' ? 'active' : ''}>
                            <FaRightLeft />
                            <span>Transferências</span>
                        </Link>
                    )
                }
            </div>
        );
    }

    return <>
        <Link to="/secretaria-escolar" className={location.pathname.indexOf('/secretaria-escolar')===0 ? ' active' : ''}>
            <FaGraduationCap />
            <span>Secretaria Escolar</span>
        </Link>

        { location.pathname.indexOf('/secretaria-escolar')===0 && submenu() }
    </>
}

export default SchoolSecretary;