import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { eachDayOfInterval, startOfYear, endOfYear, getDay } from 'date-fns';
import { FaFileExcel, FaSquareCheck } from "react-icons/fa6";
import dates from "~/utils/dates";
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";
import { ModalContext } from "~/contexts/modal";

// Components
import Template from "~/components/Default/Template";
import HeaderSearchAndForm from "~/components/Default/HeaderSearchAndForm";
import Loading from '~/components/Default/Loading';
import Modal from './Modal';
import CalendarComponent from "./Components/Calendar";
import BimesterInfoComponent from "./Components/Bimester";
import LegendComponent from "./Components/Legend";

interface BimesterDates {
    bimester1Start: string;
    bimester1End  : string;
    bimester2Start: string;
    bimester2End  : string;
    bimester3Start: string;
    bimester3End  : string;
    bimester4Start: string;
    bimester4End  : string;
};

type EventDays = Record<string, string>;

const eventColors: Record<string, string> = {
    "Recesso"                                  : "recess",
    "Feriado"                                  : "holiday",
    "Férias do docente"                        : "teacherHoliday",
    "Planejamento/Replanejamento"              : "planning",
    "Reunião de pais/Responsáveis (Letivo)"    : "schoolMeeting",
    "Formação Continuada"                      : "continuingEducation",
    "Ponto Facultativo"                        : "optionalHoliday",
    "Conselho de classe (Letivo)"              : "classCouncil",
    "Dias Letivos"                             : "schoolDays",
    "Final de semana"                          : "weekend",
    "Semana nacional da Pessoa com deficiência": "pcdDay",
    "Início/Encerramento do ano letivo"        : "startEndSchoolYear",
};

const bimesterBase = {
    bimester1Start: "",
    bimester1End  : "",
    bimester2Start: "",
    bimester2End  : "",
    bimester3Start: "",
    bimester3End  : "",
    bimester4Start: "",
    bimester4End  : ""
}

const SecretaryEducationCalendar: React.FC = () => {
    const [searchParams] = useSearchParams();
    const yearBase: number   = Number(searchParams.get("year")) || Number(new Date().getFullYear());

    // States
    const [ready, setReady]                 = useState(false);
    const [year, setYear]                   = useState<number>(yearBase);
    const [eventDays, setEventDays]         = useState<EventDays>({});
    const [eventDaysMult, setEventDaysMult] = useState<any>({});
    const [bimesterDates, setBimesterDates] = useState<BimesterDates>(bimesterBase);
    const [multSelect, setMultSelect]       = useState<boolean>(false);

    // Contexts
	const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
	const { setModalOpen, setModalButton, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    // Functions
    const handleDayClick = (day: number, month: number) => {
        let monthName   = dates.getNameMonth(month+1)
        let daySelected:string = String(day).length > 1 ? String(day) : "0" + String(day)

        setModalClass('eventDay')
        setModalTitle(`Defina um evento para o dia ${ daySelected } de ${ monthName }`)
        setModalBody(Object.entries(eventColors).map(([eventType, color]) => (
            <button key={eventType} className="legend-item" onClick={() => handleEventSelect(day, month, eventType)}>
                <span className={color} />
                {eventType}
            </button>
        )))

        setModalOpen(true);
    };

    const sendMultClick = () => {
        setModalClass('eventDay')
        setModalTitle(`Defina um evento para os dias selecionados`)
        setModalBody(Object.entries(eventColors).map(([eventType, color]) => (
            <button key={eventType} className="legend-item" onClick={() => saveEventDaysMult(eventType)}>
                <span className={color} />
                {eventType}
            </button>
        )))

        setModalOpen(true);
    };

    const handleDayClickMult = (event: any, day: number, month: number) => {
        let isChecked = event.target.checked;
        const key = `${day}-${month}`;
        setEventDaysMult((prevState:any) => {
            if (isChecked) {
                // Adiciona o item
                return {
                    ...prevState,
                    [key]: { day, month }
                };
            } else {
                // Remove o item
                const updatedState = { ...prevState };
                delete updatedState[key];
                return updatedState;
            }
        });
    };

    const saveEventDaysMult = async (eventType: string) => {
        Object.values(eventDaysMult).forEach((el: any) => {
            console.log(el.day, el.month, eventType);
            handleEventSelect(el.day, el.month, eventType);
        });
        setMultSelect(false)
        setModalOpen(false)
    };

    const handleEventSelect = (day: number, month: number, eventType: string) => {
        setModalOpen(false);
        const key = `${day}-${month}`;
        setEventDays((prev) => ({
            ...prev,
            [key]: eventType,
        }));
    };

    const fillLetiveDaysForYear = (year: number) => {
        const startDate        = startOfYear(new Date(year, 0, 1));                      // Primeiro dia do ano
        const endDate          = endOfYear(new Date(year, 11, 31));                      // Último dia do ano
        const daysInYear       = eachDayOfInterval({ start: startDate, end: endDate });
        const updatedEventDays = { ...eventDays };

        daysInYear.forEach((date) => {
            const dayOfWeek = getDay(date);       // 0 = Domingo, 6 = Sábado
            const day       = date.getDate();
            const month     = date.getMonth();    // Janeiro é 0, Dezembro é 11
            const key       = `${day}-${month}`;

            // Preenche apenas os dias de segunda a sexta-feira e não sobrescreve eventos já existentes
            if (dayOfWeek >= 1 && dayOfWeek <= 5 && !updatedEventDays[key])
                updatedEventDays[key] = "Dias Letivos";
            else if(dayOfWeek === 0 || dayOfWeek === 6)
                updatedEventDays[key] = "Final de semana";
        });

        setEventDays(updatedEventDays)

        return updatedEventDays;
    };

    const calculateLetiveDays = (): number => {
        let letiveDays = 0;
        Object.values(eventDays).forEach((event) => {
            if (event === "Dias Letivos") letiveDays += 1;
        });

        return letiveDays;
    };

    const handleBimesterDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBimesterDates({
            ...bimesterDates,
            [e.target.name]: e.target.value,
        });
    };

    function renderMultSelect () {
        if (!multSelect) {
            return <button className="mult" onClick={() => setMultSelect(true)}>
                <FaSquareCheck size={14} /> <span>Selecionar Vários Dias</span>
            </button>
        }

        return <div className="multSelect">
            <button className="apply" onClick={() => sendMultClick()}> Aplicar </button>
            <button className="cancel" onClick={() => setMultSelect(false)}> Cancelar </button>
        </div>
    }

    function renderButtons () {
        let buttons = [
            {
                nameButton: "Exportar",
                className: "add",
                icons: <FaFileExcel />,
                onClick: () => exportCalendar()
            }
        ]

        if (Object.keys(eventDays).length===0) buttons.push({
            nameButton: "Preencher Dias Úteis e Finais de Semana",
            className: "other",
            icons: <></>,
            onClick: () => fillLetiveDaysForYear(year)
        })

        return buttons;
    }

    const exportCalendar: any = () => {
        let data = {
            year,
            bimesterDates,
            eventDays,
            letiveDays: calculateLetiveDays()
        };

        setModalClass('createDocCalendar')
        setModalTitle('Calendário Escolar')
        setModalBody(<Modal reportName={`Calendário Escolar ${year}`} data={data} />)
        setModalButton(false)
        setModalOpen(true)
    }

    const saveDataForm = async () => {
        await api.post('calendar',
            {
                year,
                bimesterDates,
                eventDays,
                letiveDays: calculateLetiveDays()
            },
            {headers: { Authorization: user.token }})
        .then(resp => {
            alert('Cadastro realizado com sucesso')
            // navigate('/secretaria-educacao/funcionarios')
        }).catch(err => {
            console.log("🚀 ~ saveDataForm ~ err:", err)
        })
    }

    useEffect(() => {
        !ready && api.get('calendar', {
            headers: { Authorization: user.token }
        }).then(resp => {
            if (resp.data) {
                setYear(resp.data.year)
                setEventDays(resp.data.data)
                setBimesterDates(resp.data.bimesters)
            }
            setReady(true);
        });
    }, [ready]);

    useEffect(() => {
        if (!multSelect) {
            setMultSelect(false)
            setEventDaysMult({})
        }
    }, [multSelect]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr !== "Calendário") && setBreadcrumbs({
            curr: 'Calendário',
            links: [
                { name: 'Home', url: '/' },
                { name: 'Secretaria da Educação', url: '/secretaria-educacao' },
                { name: 'Calendário' }
            ]
        });
    }, [breadcrumbs]);

    return (
        <Template page="Calendário Escolar" pageTitle="Secretaria da Educação - Calendário Escolar" className="secEducCalendar">
            {
                ready ? <>
                    <HeaderSearchAndForm
                        module={renderMultSelect()}
                        buttons={renderButtons()}
                        isViewButton={true}
                        isViewSearch={false}
                    />

                    <div className="calendar-container">
                        {/* Calendário à esquerda */}
                        <CalendarComponent
                            eventColors={eventColors}
                            eventDays={eventDays}
                            onDayClick={handleDayClick}
                            onDayMult={handleDayClickMult}
                            year={Number(year)}
                            multSelect={multSelect}
                        />

                        <div className="rigth">
                            <BimesterInfoComponent
                                onBimesterDateChange={handleBimesterDateChange}
                                letiveDays={calculateLetiveDays()}
                                bimesterDates={bimesterDates}
                                saveEventDaysMult={saveEventDaysMult}
                            />

                            <hr />

                            <LegendComponent eventColors={eventColors} submit={saveDataForm} />
                        </div>
                    </div>
                </> : <Loading />
            }
        </Template>
    );
};

export default SecretaryEducationCalendar;
