import React, { useContext, useState, ChangeEvent, useEffect } from 'react';
import { FaTrash } from 'react-icons/fa6';
import treats from '~/utils/treats';
import api from '~/services/api';
import './addTeacherStyles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Interface
interface TeachersProps {
    id        ?: number,
    class_id   : number,
    teacher_id : number,
    discipline : string,
    teacher    : any
}

const NewRequestForm: React.FC<any> = (props: any) => {
    const [ready, setReady]       = useState(false);
    const [teachers, setTeachers] = useState<TeachersProps[]>([]);
    const [error, setError]       = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);

    function addTeacher() {
        setTeachers([...teachers, { id: teachers.length + 1, class_id: 0, teacher_id: 0, discipline: 'Interdisciplinar', teacher: {} }]);
    }

    function removeTeacher(id: number) {
        if (teachers.length === 1) return;
        let reqBase = JSON.parse(JSON.stringify(teachers));
        let idx     = teachers.findIndex((el: any) => id === el.id);

        // Remove Item
        reqBase.splice(idx, 1);

        // Recreate Requests
        setTeachers(reqBase);
    }

    function onChange(index: number, event: ChangeEvent<HTMLSelectElement>) {
        const { name, value } = event.target;
        const newTeachers = [...teachers];

        // Get Teacher if change teacher_id
        let teacher = newTeachers[index].teacher;
        if (name==='teacher_id') teacher = value ? props.teachers.filter((el: any) => el.id===Number(value))[0] : "";

        newTeachers[index]    = {
            ...newTeachers[index],
            class_id: props.class_id,
            [name]: name === 'teacher_id' ? Number(value) : value,
            teacher
        };

        setTeachers(newTeachers);
    }

    async function sendForm() {
        api.post('school_classes/teacher', teachers, {
            headers: { Authorization: user.token }
        }).then(resp => {
            props.openModal('teacher');
        }).catch(err => {
            setError('Erro ao carregar os dados dos professores')
            setReady(true)
        });
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <div className='teachersList'>
                {
                    teachers && teachers.map((data: any, idx: number) => (
                        <div key={`prod_${idx}`} className="formGroup">
                            <div className="inputLabel teacher">
                                <label htmlFor="teacher">Professor</label>
                                <select
                                    name="teacher_id"
                                    value={data.teacher.id}
                                    onChange={(e) => onChange(idx, e)}
                                >
                                    <option value="">Selecione um Professor</option>

                                    {
                                        props.teachers.map((teacher: any) => (
                                            <option key={`teacher_${teacher.id}`} value={teacher.id}>
                                                { treats.formatNames(teacher.name) }
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="inputLabel discipline">
                                <label htmlFor="discipline">Materia</label>
                                <select
                                    name="discipline"
                                    value={data.discipline}
                                    onChange={(e) => onChange(idx, e)}
                                >
                                    <option value="">Selecione uma Materia</option>
                                    <option value="Polivalente">Polivalente</option>
                                    <option value="Arte">Arte</option>
                                    <option value="Biologia">Biologia</option>
                                    <option value="Ciências">Ciências</option>
                                    <option value="Educação Física">Educação Física</option>
                                    <option value="Filosofia">Filosofia</option>
                                    <option value="Física">Física</option>
                                    <option value="Geografia">Geografia</option>
                                    <option value="História">História</option>
                                    <option value="Língua Inglesa">Língua Inglesa</option>
                                    <option value="Língua Portuguesa">Língua Portuguesa</option>
                                    <option value="Matemática">Matemática</option>
                                    <option value="Química">Química</option>
                                    <option value="Sociologia">Sociologia</option>
                                </select>
                            </div>

                            <button
                                type='button'
                                onClick={() => removeTeacher(data.id)}
                            >
                                <FaTrash size={14} />
                            </button>
                        </div>
                    ))
                }
            </div>

            <div className="buttons">
                <button className='add' onClick={addTeacher}>Adicionar Professor</button>

                <div className="btnLine">
                    <button className='save' onClick={sendForm}>Salvar</button>
                    <button className='cancel' onClick={() => props.openModal('teacher')}>Cancelar</button>
                </div>
            </div>
        </>
    }

    useEffect(() => {
        if (!ready) {
            setTeachers(props.classTeacher);
            setReady(true);
        }
    }, [ready, teachers])

    return ready ? getContent() : <></>
}

export default NewRequestForm;