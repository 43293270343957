import React, { useContext, useEffect, useState } from 'react';
import dates from "~/utils/dates";
import api from '~/services/api';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import ParentsGradesContent from './Content';

// Current Date
const currDate = dates.getDate();

const ParentsGrades: React.FC = () => {
    const [ready, setReady]         = useState(false);
    const [error, setError]         = useState<string | null>(null);
    const [date, setDate]           = useState<any>(currDate);
    const [presences, setPresences] = useState<any>(null);
    const [grades, setGrades]       = useState<any>(null);
    const [data, setData]           = useState<any>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    async function getMonthPresence(month?: string) {
        await api.get(`teacher/student/presence/${user.student_id}/${date.year}/${Number(month ? month : date.month)}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            let data = resp.data.length === 1 ? JSON.parse(resp.data[0].presence) : [];
            setPresences(data);
            setReady(true);
        }).catch(() => {
            console.log("Error")
        });
    }

    async function getMonthGrades(month?: string) {
        await api.get(`/teacher/activity-test-grade/student/${user.student_id}/${date.year}/${Number(month ? month : date.month)}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setGrades(resp.data);
            setReady(true);
        }).catch(() => {
            console.log("Error")
        });
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <ParentsGradesContent
            data={data}
            presences={presences}
            grades={grades}
            currDate={currDate}
            date={date}
            setDate={setDate}
            setError={setError}
            getMonthPresence={getMonthPresence}
            getMonthGrades={getMonthGrades}
        />
    }

    async function loadInfos() {
        try {
            const [studentReponse] = await Promise.all([
                api.get(`student/${user.student_id}`, { headers: { Authorization: user.token } }),
                getMonthGrades(),
                getMonthPresence()
            ]);

            setData(studentReponse.data);
            setReady(true);
        } catch (error) {
        }
    }

    useEffect(() => {
        loadInfos();
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Notas e Faltas") && setBreadcrumbs({
            curr: 'Notas e Faltas',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Notas/Faltas' }
            ]
        });
    }, [breadcrumbs]);

    return <Template
        page="Notas e Faltas"
        pageTitle="Pais e Alunos - Notas e Faltas"
        className="parentsGrades"
    >
        {ready ? getContent() : <Loading />}
    </Template>;
}

export default ParentsGrades;