import { ChangeEvent, useContext, useEffect, useState } from 'react';
import Treats from '~/utils/treats';
import validateForm from '~/utils/validateForm';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import InputLabel, { InputLabelMask } from '~/components/Forms/InputLabel';
import dates from '~/utils/dates';
import sort_by from '~/utils/sort';

// Base Data
let dataBase = {
    "SCHOOL_NAME"   : "",
    "SCHOOL_ADDRESS": "",
    "CLASS_ID"      : "",
    "SERIE"         : "",
    "PERIOD"        : "",
    "TEACHER"       : "",
    "CLASS_TIME"    : "",
    "CLASS_DATE"    : "",
    "TABLE"         : []
};

const ClassMirrorDoc: React.FC<any> = ({doc, reportName, school}: any) => {
    const [ready, setReady]       = useState<boolean>(false);
    const [loadData, setLoadData] = useState<boolean>(false);
    const [data, setData]         = useState<any>(dataBase);
    const [classes, setClasses]   = useState<any>([]);
    const [teachers, setTeachers] = useState<any>([]);
    const [students, setStudents] = useState<any>([]);
    const [errors, setErrors]     = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);
    const { setModalOpen } = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        let { name, value } = event.target;
        let classesSel = classes.filter((el: any) => el.id === Number(value))[0];

        if (name === 'CLASS_ID') {
            if (classesSel) {
                setTeachers(classesSel.teachers);
                setStudents(classesSel.students);

                // Set Data
                setData({
                    ...data,
                    SERIE     : classesSel.serie,
                    PERIOD    : getShiftName(classesSel.period),
                    TEACHER   : "",
                    CLASS_TIME: classesSel.class_time.init + ' - ' + classesSel.class_time.end,
                    CLASS_DATE: dates.getDateFormatted(new Date(), 'dd/mm/yyyy'),
                    [name]    : value
                });
            } else {
                setTeachers([]);
                setStudents([]);

                setData({
                    ...data,
                    SERIE     : "",
                    PERIOD    : "",
                    TEACHER   : "",
                    CLASS_TIME: "",
                    CLASS_DATE: "",
                    [name]    : value
                });
            }
        } else {
            setData({
                ...data,
                [name]: value
            });
        }
    }

    function getContent () {
        if (loadData) return <div className="saving">Gerando Ficha...</div>

        return <>
            <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()}>
                <div className="form-group no-margin">
                    <div className="inputLabel">
                        <label>Turma</label>
                        <select name="CLASS_ID" onChange={onChange} defaultValue={data.CLASS_ID} disabled={classes && classes.length>0 ? false : true}>
                            <option value="">-- Selecione uma Classe --</option>

                            {
                                classes.map((el: any) => <option key={`class_${el.id}`} value={el.id}>
                                    { el.serie }
                                </option>)
                            }
                        </select>
                    </div>
                </div>

                <div className="form-group no-margin">
                    <div className="inputLabel">
                        <label>Professor</label>
                        <select name="TEACHER" onChange={onChange} value={data.TEACHER} disabled={teachers && teachers.length>0 ? false : true}>
                            <option value="">-- Selecione um Professor --</option>

                            {
                                teachers.map((el: any) => <option key={`teacher_${el}`} value={el}>
                                    { el }
                                </option>)
                            }
                        </select>
                    </div>
                </div>
            </form>

            <div className="buttons">
                {errors && <div className="error">{errors}</div>}

                <button id="btnAdd" type="button" onClick={saveDataForm}>Gerar Ficha</button>
                <button id="btnCancel" type="button" onClick={() => setModalOpen(false)}>Cancelar</button>
            </div>
        </>
    }

    async function saveDataForm () {
        setErrors(false);
        setLoadData(true);

        // Get Teachers Data
        let dataForm            = data;
        dataForm.SCHOOL_NAME    = Treats.formatNames(school.name);
        dataForm.SCHOOL_ADDRESS = Treats.formatNames(school.address + (school.address_number ? ', ' + school.address_number : '') + ' - ' + school.address_neighborhood);

        // Check Errors
        if (validateForm.checkFields(dataForm, ['TABLE'])) {
            setErrors('Todos os campos devem estar preenchidos')
            return false
        }

        // Treat Datas to Table and Set
        let itensData = students.sort(sort_by({name: "ASC"})).map((el: any, idx: number) => ({
            NUMBER: (idx+1),
            STUDENT_NAME: Treats.formatNames(el.name)
        }));

        data.TABLE = itensData;

        api.post(`docs/${doc}`, data, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then(async resp => {
            let url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
            window.open(url, reportName, "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=900,height=600");
            setModalOpen(false);
            setLoadData(false);
        }).catch(errr => {
            console.log(errr)
            setLoadData(false);
            setErrors('Erro ao gerar a ficha');
        });
    }

    function getShiftName (period: string) {
        switch (period) {
            case 'INTEGRAL':
                return 'Integral'
            case 'MANHA':
                return 'Matutino'
            case 'TARDE':
                return 'Vespertino'
            case 'NOITE':
                return 'Noturno'
            default:
                return 'Não Identificado'
        }
    }

    useEffect(() => {
        !ready && api.get(`school_classes/control/class/mirror/${school.id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setClasses(resp.data)
            setReady(true)
        }).catch(errr => {
            console.log(errr)
        });
    }, [ready])

    return ready ? getContent() : <></>
}

export default ClassMirrorDoc;