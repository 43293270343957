import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaKey, FaPencil } from 'react-icons/fa6';
import Dates from '~/utils/dates';
import Treats from '~/utils/treats';
import api from '~/services/api';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import ProfileForm from './Modal/profile';
import PassForm from './Modal/password';

const Profile: React.FC = () => {
    const [ready, setReady] = useState<boolean>(false);
    const [data, setData]   = useState<any>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal (type: string) {
        if (type === "Edit") {
            setModalClass('editProfile')
            setModalTitle('Editando Perfil');
            setModalBody(<ProfileForm data={user} setModalOpen={setModalOpen} />)
        } else if (type === "Pass"){
            setModalClass('changePass')
            setModalTitle('Trocar a Senha')
            setModalBody(<PassForm data={user} setModalOpen={setModalOpen} />)
        }

        setModalOpen(true);
    }

    // Contents
    function getPermissionContent () {
        return user.role === "admin" ? (
            <div className="box permission">
                <small>Permissão:</small>
                <span>Administrador</span>
            </div>
        ) : (
            <div className="box permission">
                <small>Permissão:</small>
                <span>{ user.role_category ? Treats.formatNames(user.role_category) : '-' }</span>
            </div>
        )
    }

    function getEmployeeContent () {
        // Dates Infos
        const birthDate = Dates.getDate(data.birth_date);
        const yearsOld  = Dates.getYearsOld(data.birth_date);

        // Contract Dates
        const ContractInitDate = Dates.getDate(data.workInformation.admission_date);
        const ContractInit     = data.workInformation.admission_date ? `${ContractInitDate.day} de ${ContractInitDate.monthName} de ${ContractInitDate.year}` : 'Não Informado';
        const ContractEndDate  = Dates.getDate(data.workInformation.contract_end_date);
        const ContractEnd      = data.workInformation.contract_end_date ? `${ContractEndDate.day} de ${ContractEndDate.monthName} de ${ContractEndDate.year}` : 'Não Informado';

        return <>
            <hr />
            <h3>
                <span>Informações de Cadastro</span>
                <span className='bag'>Funcionário</span>
            </h3>

            {
                !data ? (
                    <div className="alertInfo">Não foi possível localizar os dados do Funcionário</div>
                ) : <>
                    <div className="line">
                        <div className="box name">
                            <small>Nome do Aluno:</small>
                            <span>{ Treats.formatNames(data.name) || '-' }</span>
                        </div>

                        <div className="box birth">
                            <small>Aniversário:</small>
                            <span>{birthDate.day} de {birthDate.monthName} de {birthDate.year}</span>
                        </div>

                        <div className="box age">
                            <small>Idade:</small>
                            <span>{yearsOld.years > 0 ? yearsOld.years + ' ano' + (yearsOld.years>1 ? 's' : '') : yearsOld.months + (yearsOld.months>1 ? ' meses' : ' mês')}</span>
                        </div>
                    </div>

                    <div className="line">
                        <div className="box nationality">
                            <small>Nacionalidade:</small>
                            <span>{Treats.formatNames(data.nationality)}</span>
                        </div>

                        <div className="box cns">
                            <small>Estado Civil:</small>
                            <span>{data.marital_status ? getMaritalStatus(data.marital_status) : 'Não Informado'}</span>
                        </div>

                        <div className="box cpf">
                            <small>CPF:</small>
                            <span>{data.cpf ? Treats.formatCPF(data.cpf) : 'Não Informado'}</span>
                        </div>

                        <div className="box cpf">
                            <small>RG:</small>
                            <span>{data.rg ? Treats.formatRG(data.rg) : 'Não Informado'}</span>
                        </div>
                    </div>

                    <h4>Informações de Trabalho</h4>
                    <div className="line">
                        <div className="box mather">
                            <small>Departamento:</small>
                            <span>{ data.workInformation.department ? Treats.formatNames(data.workInformation.department) : '-' }</span>
                        </div>

                        <div className="box mather">
                            <small>Cargo:</small>
                            <span>{ data.position.name ? Treats.formatNames(data.position.name) : '-' }</span>
                        </div>

                        <div className="box father">
                            <small>Horário de Trabalho:</small>
                            <span>{ data.workInformation.work_start_time && data.workInformation.work_end_time ? `Das ${Dates.getTimeFormatted(data.workInformation.work_start_time, 'HH:MM')} às ${Dates.getTimeFormatted(data.workInformation.work_end_time, 'HH:MM')}` : '-' }</span>
                        </div>
                    </div>

                    <div className="line">
                        <div className="box father">
                            <small>Tipo de Contrato:</small>
                            <span>{ data.workInformation.contract_type ? data.workInformation.contract_type : '-' }</span>
                        </div>

                        <div className="box father">
                            <small>Início do Contrato:</small>
                            <span>{ ContractInit }</span>
                        </div>

                        <div className="box father">
                            <small>Encerramento do Contrato:</small>
                            <span>{ ContractEnd }</span>
                        </div>

                        <div className="box father">
                            <small>Temporário?</small>
                            <span>{ data.workInformation.is_temporary ? 'Sim' : 'Não' }</span>
                        </div>
                    </div>

                    <h4>Informações das Escolas</h4>
                    {
                        data.schools.length > 0 ? data.schools.map((el: any) => (
                            <div className="line">
                                <div className="box mather">
                                    <small>Nome:</small>
                                    <span>{ Treats.formatNames(el.school.name) }</span>
                                </div>

                                <div className="box father">
                                    <small>Rede:</small>
                                    <span>{ Treats.formatNames(el.school.network.name) }</span>
                                </div>
                            </div>
                        )) : <div className="alert">Não foi ligado a nenhuma escola até o momento</div>
                    }
                </>
            }
        </>;
    }

    function getStudentContent () {
        // Birth Infos
        const birthDate = Dates.getDate(data.birth);
        const yearsOld  = Dates.getYearsOld(data.birth);

        return <>
            <hr />
            <h3>
                <span>Informações de Cadastro</span>
                <Link to="/pais-e-alunos/aluno" className='bag'>
                    Estudante
                </Link>
            </h3>

            {
                !data ? (
                    <div className="alertInfo">Não foi possível localizar os dados do Aluno</div>
                ) : <>
                    <div className="line">
                        <div className="box name">
                            <small>Nome do Aluno:</small>
                            <span>{ Treats.formatNames(data.name) || '-' }</span>
                        </div>

                        <div className="box birth">
                            <small>Aniversário:</small>
                            <span>{birthDate.day} de {birthDate.monthName} de {birthDate.year}</span>
                        </div>

                        <div className="box age">
                            <small>Idade:</small>
                            <span>{yearsOld.years > 0 ? yearsOld.years + ' ano' + (yearsOld.years>1 ? 's' : '') : yearsOld.months + (yearsOld.months>1 ? ' meses' : ' mês')}</span>
                        </div>
                    </div>

                    <div className="line">
                        <div className="box birth_local">
                            <small>Local de Nascimento:</small>
                            <span>{Treats.formatNames(data.birth_city)}/{data.birth_uf}</span>
                        </div>

                        <div className="box nationality">
                            <small>Nacionalidade:</small>
                            <span>{Treats.formatNames(data.nationality)}</span>
                        </div>

                        <div className="box cpf">
                            <small>CPF:</small>
                            <span>{data.docs.cpf ? Treats.formatCPF(data.docs.cpf) : 'Não Informado'}</span>
                        </div>

                        <div className="box cns">
                            <small>Cartão Nacional de Saúde:</small>
                            <span>{data.docs.cns_number ? data.docs.cns_number : 'Não Informado'}</span>
                        </div>
                    </div>

                    <div className="line">
                        <div className="box sex">
                            <small>Gênero:</small>
                            <span>{Treats.formatNames(data.sex)}</span>
                        </div>

                        <div className="box blood_type">
                            <small>Tipo Sanguínio:</small>
                            <span>{data.blood_type ? data.blood_type : 'Não Informado'}</span>
                        </div>

                        <div className="box organ_donor">
                            <small>Doador de Orgãos?</small>
                            <span>{data.organ_donor ? 'Sim' : 'Não'}</span>
                        </div>

                        <div className="box color_race">
                            <small>Raça/Cor:</small>
                            <span>{data.color_race ? Treats.formatNames(data.color_race) : 'Não Declarada'}</span>
                        </div>

                        <div className="box quilombola">
                            <small>Quilombola?</small>
                            <span>{data.quilombola ? 'Sim' : 'Não'}</span>
                        </div>
                    </div>

                    <h4>Informações da Família</h4>
                    <div className="line">
                        <div className="box mather">
                            <small>Nome da Mãe:</small>
                            <span>{ data.mother_name ? Treats.formatNames(data.mother_name) : '-' }</span>
                        </div>

                        <div className="box father">
                            <small>Nome do Pai:</small>
                            <span>{ data.father_name ? Treats.formatNames(data.father_name) : '-' }</span>
                        </div>
                    </div>

                    <div className="line">
                        <div className="box internet">
                            <small>Possui Internet em Casa?</small>
                            <span>{data.internet ? 'Sim' : 'Não'}</span>
                        </div>

                        <div className="box family_bag">
                            <small>Possui Bolsa Família?</small>
                            <span>{data.family_bag ? 'Sim' : 'Não'}</span>
                        </div>

                        <div className="box family_bag_cod">
                            <small>Código do Bolsa Família:</small>
                            <span>{data.family_bag ? data.family_bag_cod : '-'}</span>
                        </div>
                    </div>

                    <h4>Informações da Escola</h4>
                    <div className="line">
                        <div className="box school">
                            <small>Escola:</small>
                            <span>{Treats.formatNames(data.school.name)}</span>
                        </div>

                        <div className="box school_network">
                            <small>Rede:</small>
                            <span>{Treats.formatNames(data.school.network.name)}</span>
                        </div>
                    </div>

                    <div className="line">
                        <div className="box school_class">
                            <small>Classe:</small>
                            <span>{data.school_class.class_serie} Ano {data.school_class.class_acron.toUpperCase()}</span>
                        </div>

                        <div className="box school_year">
                            <small>Ano Letivo:</small>
                            <span>{data.school_class.school_year}</span>
                        </div>

                        <div className="box school_period">
                            <small>Período:</small>
                            <span>{Treats.formatNames(data.school_class.shift.name)}</span>
                        </div>

                        <div className="box school_teaching">
                            <small>Tipo:</small>
                            <span>{Treats.formatNames(data.school_class.teaching.name)}</span>
                        </div>
                    </div>
                </>
            }
        </>;
    }

    function getMaritalStatus (status: string) {
        <option value="single"></option>

        switch (status) {
            case 'married':
                return 'Casado(a)';

            case 'divorced':
                return 'Divorciado(a)';

            case 'widowed':
                return 'Viúvo(a)';

            default:
                return 'Solteiro(a)';
        }
    }

    // Get User Datas
    async function getEmployeeData() {
        await api.get(`employees/record/${user.employee_id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setData(resp.data);
            setReady(true);
        }).catch(errr => {
            console.log("Errors")
            console.log(errr)
        });
    }

    async function getStudentData() {
        await api.get(`student/${user.student_id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            console.log(resp.data)
            setData(resp.data);
            setReady(true);
        }).catch(errr => {
            console.log("Errors")
            console.log(errr)
        });
    }

    useEffect(() => {
        if (!ready && user.employee_id) getEmployeeData();
        else if (!ready && user.student_id) getStudentData();
        else if (!ready && !user.employee_id && !user.student_id) setReady(true);
    }, [ready])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Perfil do Usuário") && setBreadcrumbs({
            curr: 'Perfil do Usuário',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Perfil' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Perfil" pageTitle="Perfil" className="profile">
        {
            ready ? <>
                <HeaderSearchAndForm
                    buttons={[
                        {
                            nameButton: "Editar Perfil",
                            onClick: () => openModal('Edit'),
                            className: "edit",
                            icons: <FaPencil />
                        },
                        {
                            nameButton: "Mudar Senha",
                            onClick: () => openModal('Pass'),
                            className: "pass",
                            icons: <FaKey />
                        }
                    ]}
                    isViewButton={true}
                    isViewSearch={false}
                />

                <h3>Informações do Usuário</h3>
                <div className="line">
                    <div className="box name">
                        <small>Nome Completo:</small>
                        <span>{ user.name }</span>
                    </div>

                    <div className="box mail">
                        <small>Email:</small>
                        <span>{ user.mail }</span>
                    </div>

                    { getPermissionContent() }
                </div>

                {/* Employee Infos */}
                { user.employee_id ? getEmployeeContent() : <></> }

                {/* Student Infos */}
                { user.student_id ? getStudentContent() : <></> }
            </> : <Loading />
        }
    </Template>;
}

export default Profile;