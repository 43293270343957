import React from 'react';
import dates from '~/utils/dates';
import './styles.css';

const StudentInfos: React.FC<any> = ({ data }) => {
    const yearsOld = dates.getYearsOld(data.birth);
    const birth    = dates.getDateFormatted(data.birth, 'dd/mm/yyyy');

    function renderClass() {
        if (data.school_class) {
            return <>
                <p><b>Tipo:</b> {data.school_class.teaching.name}</p>
                <p><b>Série:</b> {data.school_class.class_serie} {data.school_class.class_acron}</p>
                <p><b>Ano Letivo:</b> {data.school_class.school_year}</p>
                <p><b>Período:</b> {data.school_class.shift.name}</p>
                <p><b>Horário:</b> {dates.getTimeFormatted(data.school_class.start_time, 'HH:MM')} às {dates.getTimeFormatted(data.school_class.end_time, 'HH:MM')}</p>
            </>
        }

        return <p><b>Classe:</b> Não Informada</p>
    }

    return <>
        <h3>{data.name}</h3>
        <p><b>Idade:</b> {yearsOld.years > 0 ? yearsOld.years + ' ano' + (yearsOld.years>1 ? 's' : '') : yearsOld.months + (yearsOld.months>1 ? ' meses' : ' mês')}</p>
        <p><b>Nascimento:</b> {birth}</p>
        <p><b>Local de Nascimento:</b> {data.birth_city}/{data.birth_uf}</p>
        <p><b>Nacionalidade:</b> {data.nationality}</p>
        <p><b>Gênero:</b> {data.sex}</p>
        <p><b>Cor:</b> {data.color_race}</p>

        <hr />

        <p><b>Mãe:</b> {data.mother_name}</p>
        <p><b>Pai:</b> {data.father_name}</p>

        <hr />

        <p><b>Endereço:</b> {data.address.address}{data.address.number ? ', ' + data.address.number : ''} - {data.address.neighborhood}</p>
        <p><b>Cidade/Estado:</b> {data.address.city}/{data.address.uf}</p>
        <p><b>Área:</b> {data.address.public_area}</p>

        <hr />

        <p><b>RA:</b> {data.ra_number}</p>
        <p><b>Escola:</b> {data.school ? data.school.name : 'Não Informada'}</p>
        {renderClass()}
    </>;
}

export default StudentInfos;