import React from 'react';
import { FaPencil, FaTrash } from 'react-icons/fa6';
import Treats from '~/utils/treats';
import Dates from '~/utils/dates';
import './styles.css';

const TransferBox: React.FC<any> = ({data, openModal}: any) => {
    function getButtons() {
        return <>
            <button type="button" className="edit" onClick={() => openModal("Edit", data)} title="Editar Transferência">
                <FaPencil size={14} /> <span>Editar</span>
            </button>

            <button type="button" className="inactivate" onClick={() => openModal('Delete', data)} title="Deletar Transferência">
                <FaTrash size={14} /> <span> Deletar</span>
            </button>
        </>
    }

    return <div className="transferBox">
        <div className="infos">
            <div className="origin">
                <b>Escola de Origem</b>
                <span>{ Treats.formatNames(data.schoolRequester.name) }</span>
            </div>

            <div className="destiny">
                <b>Escola de Destino</b>
                <span>
                    {
                        data.schoolRequired!==null && data.schoolRequired!=='' ?
                            Treats.formatNames(data.schoolRequired.name) :
                            'Escola Particular / Outro Município'
                    }
                </span>
            </div>

            <hr />

            <div className="student">
                <b>Aluno</b>
                <span>{ Treats.formatNames(data.student.name) }</span>
            </div>

            <hr />

            <div className="student">
                <b>Data da Requisição</b>
                <span>{ Dates.getDateFormatted(data.created_at, 'dd/mm/yyyy') }</span>
            </div>

            <div className={`status ${data.status.toLowerCase()}`}>
                { data.status }
            </div>
        </div>

        <div className="buttons">
            {getButtons()}
        </div>
    </div>;
}

export default TransferBox;
