import React, { useContext } from 'react';
import { FaRegFileAlt } from 'react-icons/fa';
import treats from '~/utils/treats';
import './styles.css';

// Contexts
import { ModalContext } from '~/contexts/modal';

// Components
import CreateActivitiesTests from '../Modal/activitiesTestCreate';
import ListActivitiesTests from '../Modal/activitiesTestsList';
import ViewActivitiesTests from '../Modal/activitiesTestView';
import ListAverage from '../Modal/averageList';

interface Props {
    id         : number;
    class_id   : number;
    class_serie: number;
    class_acron: string;
    school     : { name: string };
    teaching   : { name: string };
    shift      : { name: string };
    teachers   : Array<any>;
}

const SchoolBox: React.FC<any> = (props: Props) => {
    // Contexts
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        switch (type) {
            case 'GradesActivities':
                setModalClass('gradesTestsList')
                setModalTitle(`Atividades da ${props.class_serie}ª Série${ props.class_acron ? ' ' + props.class_acron.toUpperCase() : '' }`)
                setModalBody(<ListActivitiesTests id={props.id} openModal={openModal} setModalOpen={setModalOpen} />)
                break;

            case 'GradesStudents':
                setModalClass('gradesTestsView')
                setModalTitle(`Visualizando Notas da ${props.class_serie}ª Série${ props.class_acron ? ' ' + props.class_acron.toUpperCase() : '' }`)
                setModalBody(<ViewActivitiesTests data={data} openModal={openModal} />)
                break;

            case 'GradesCreate':
                setModalClass('gradesTestsCreate')
                setModalTitle(`Editando Notas do ${props.class_serie}º Ano${ props.class_acron ? ' ' + props.class_acron.toUpperCase() : '' }`)
                setModalBody(<CreateActivitiesTests activity_id={props.id} data={data} openModal={openModal} />)
                break;

            case 'StudentsAverage':
                setModalClass('listAverage')
                setModalTitle(`Médias do ${data.class_serie}º Ano${ data.class_acron ? ' ' + data.class_acron.toUpperCase() : '' }`)
                setModalBody(<ListAverage class_id={data.id} data={data} setModalOpen={setModalOpen} />)
                break;
        }

        setModalOpen(true);
    }

    return <div className="classBox">
        <div className="infos">
            <h3>{props.class_serie}ª Série{ props.class_acron ? ' ' + props.class_acron.toUpperCase() : '' }</h3>

            <div className="school">
                <b>Escola:</b> { treats.formatNames(props.school.name) }
            </div>

            <div className="teaching">
                <b>Tipo:</b> { treats.formatNames(props.teaching.name) }
            </div>

            <div className="shift">
                <b>Período:</b> { treats.formatNames(props.shift.name) }
            </div>
        </div>

        <div className="buttons">
            <button type="button" className="view" onClick={() => openModal('GradesActivities')} title="Visualizar Atividades">
                <FaRegFileAlt  size={16} /><span> Notas por Atividade</span>
            </button>
            <button type="button" className="view" onClick={() => openModal('StudentsAverage', props)} title="Médias">
                <FaRegFileAlt  size={16} /><span> Médias</span>
            </button>
        </div>
    </div>;
}



export default SchoolBox;