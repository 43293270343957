import React, { useContext, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import TemplateSchoolSecretary from '../Template';
import Loading from '~/components/Default/Loading';
import StudentBox from './Box';

// Forms
import FormRA from './Form/Ra';

const SchoolSecretaryStudents: React.FC = () => {
    const [ready, setReady]               = useState(false);
    const [students, setStudents]         = useState<any>(null);
    const [studentsList, setStudentsList] = useState<any>([]);
    const [error, setError]               = useState<string | null>(null);

    // Contexts
    const { user, school, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const {setModalOpen, setModalClass, setModalTitle, setModalBody} = useContext(ModalContext);

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return students && students.length > 0 ? (
            <div className='students'>
                {
                    students.map((studentData: any) => (
                        <StudentBox
                            key={'student_' + studentData.id}
                            {...studentData}
                        />
                    ))
                }
            </div>
        ) : <div className='noData'>Não foi possível localizar nenhum estudante</div>
    }

    function modalRA() {
        setModalClass('studentRA')
        setModalTitle('Adicionar Aluno via RA')
        setModalBody(<FormRA />)
        setModalOpen(true)
    }

    // Get page data
    useEffect(() => {
        let url = school ? '?school=' + school.id : '';
        !ready && api.get(`secEduc/students${url}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setStudents(resp.data);
            setStudentsList(resp.data);
            setReady(true);
        }).catch(err => {
            setError('Erro ao carregar os dados dos estudantes')
            setReady(true)
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Alunos") && setBreadcrumbs({
            curr: 'Alunos',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Secretaria Escolar', url: '/secretaria-escolar'},
                { name: 'Alunos' }
            ]
        });
    }, [breadcrumbs]);

    return <TemplateSchoolSecretary
        page="Alunos"
        pageTitle="Secretaria Escolar - Alunos"
        className="secEscStudents"
        buttons={[
            { nameButton: "com RA", icons: <FaPlus size={14} />, onClick: () => { modalRA() }, className: "add" },
            { url:"novo", icons: <FaPlus size={14} />, className: "add" }
        ]}
        search={{
            dataList: studentsList,        // Substitua 'yourDataList' pela sua lista de dados
            filterKey: ["name", "ra_number"],             // Substitua 'nome' pela chave que você deseja filtrar
            setFilteredList: setStudents   // Substitua 'setYourList' pela função que define a lista filtrada
        }}
        isViewButton={true}
        isViewSearch={true}
    >
        {ready ? getContent() : <Loading /> }
    </TemplateSchoolSecretary>;
}

export default SchoolSecretaryStudents;