import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '../Template';
import Loading from '~/components/Default/Loading';
import Parents from './Content';

const ParentsDashboard: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData]   = useState<any>(null);

    // Contexts
    const { user, student, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <Parents data={data} />
    }

    // Get page data
    async function loadInfos() {
        if (!student) return false;

        let url = student ? '?student=' + student.id : '';
        await api.get(`dashboard/parents${url}`, {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setData(resp.data);
            setReady(true);
        }).catch((err: any) => {
            setError('Erro ao carregar os dados do dashboard')
            setReady(true)
        });
    }

    useEffect(() => {
        loadInfos();
    }, [student])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Pais e Alunos") && setBreadcrumbs({
            curr: 'Pais e Alunos',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Pais e Alunos' }
            ]
        });
    }, [breadcrumbs]);

    return <Template
        page="Pais e Alunos"
        pageTitle="Pais e Alunos"
        className="parents"
    >
        {ready ? getContent() : <Loading />}
    </Template>;
}

export default ParentsDashboard;