import React, { useContext, useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa6';
import Treats from '~/utils/treats';
import Dates from '~/utils/dates';
import sort_by from '~/utils/sort';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import TemplateSupDir from '../Template';
import Loading from '~/components/Default/Loading';
import Modal from './Modal';

const SupervisionDirectionActivitiesReports: React.FC = () => {
    const [ready, setReady]     = useState(false);
    const [data, setData]       = useState<any>([]);
    const [classe, setClasse]   = useState<any>([]);
    const [classes, setClasses] = useState<any>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs, school } = useContext(GeneralContext);
    const { setModalClass, setModalOpen, setModalButton, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal (data: any) {
        let tableHeader = [{ INFOS1: 'Título', INFOS2: 'Professor(a)', INFOS3: 'Tipo', INFOS4: 'Data' }];

        setModalClass('createRepActivities')
        setModalTitle('Relatório de Atividades')
        setModalBody(<Modal doc="schools_reports.activities" reportName="Relatório de Atividades" school={school} tableHeader={tableHeader} tableData={data} />)
        setModalButton(false)
        setModalOpen(true)
    }

    function onChangeClasss(e: React.ChangeEvent<HTMLSelectElement>) {
        let classs = classes.filter((el: any) => el.id === Number(e.target.value))[0];
        setClasse(classs)
    }

    function generateReport() {
        let dataTable: any = [];

        data.map((el: any) => {
            dataTable.push({
                INFOS1: Treats.formatNames(el.title),
                INFOS2: Treats.formatNames(el.teacher.name),
                INFOS3: el.extra_activity ? 'Prova' : 'Atividade',
                INFOS4: Dates.getDateFormatted(el.date_end + ' 03:00:00', 'dd/mm/yyyy')
            })
        })

        openModal(dataTable)
    }

    function classContent () {
        return <div className="classes">
            <select id="classes" name="classes" onChange={onChangeClasss} defaultValue={classe.id}>
                {
                    classes.map((el: any) => <option
                        key={`school_${el.id}`}
                        value={el.id}
                    >
                        {Number(el.class_serie)>0 ? el.class_serie + ' ano' : 'Turma'} {el.class_acron.toUpperCase()}
                    </option>)
                }
            </select>
        </div>
    }

    async function loadData (class_id: number) {
        setReady(false);

        class_id && await api.get(`school_classes/activities/${class_id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setData(resp.data);
            setReady(true);
        })
    }

    // Get page data
    useEffect(() => {
        let url = school ? `?school=${school.id}` : '';
        !ready && school && classes.length===0 && api.get(`school_classes/${url}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            let classData = resp.data ? resp.data.sort(sort_by({ name: 'class_serie' }, { name: 'class_acron' })) : [];
            setClasses(classData);
            loadData(classData[0].id);
        })
    }, [ready]);

    useEffect(() => {
        loadData(classe.id);
    }, [classe]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Relatório de Atividades") && setBreadcrumbs({
            curr: 'Relatório de Atividades',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Supervisão e Direção', url: '/supervisao-e-direcao'},
                { name: 'Atividades' }
            ]
        });
    }, [breadcrumbs]);

    return <TemplateSupDir
        page="Relatório de Atividades"
        pageTitle="Supervisão e Direção - Relatório de Atividades"
        className="supDirActivities"
        module={classContent()}
        buttons={[
            {
                icons: <FaFilePdf size={14} />,
                title: "Exportar",
                nameButton: "Exportar",
                className: "report add",
                onClick: () => generateReport()
            }
        ]}
    >
        {
            ready ? (
                data.length>0 ? (
                    <div className="report">
                        <div className="header">
                            <div className="title">Título</div>
                            <div className="teacher">Professor(a)</div>
                            <div className="type">Tipo</div>
                            <div className="date">Data</div>
                        </div>

                        {
                            ready && data.map((el: any, idx: number) => <div key={`activity_${idx}`} className='line'>
                                <div className="title">{Treats.formatNames(el.title)}</div>
                                <div className="teacher">{Treats.formatNames(el.teacher.name)}</div>
                                <div className="type">{el.extra_activity ? 'Prova' : 'Atividade'}</div>
                                <div className="date">{Dates.getDateFormatted(el.date_end + ' 03:00:00', 'dd/mm/yyyy')}</div>
                            </div>)
                        }
                    </div>
                ) : <div className='alert'>Nenhuma atividade localizado</div>
            ): <Loading />
        }
    </TemplateSupDir>;
}

export default SupervisionDirectionActivitiesReports;