import React, { useContext, useEffect, useState } from 'react';
import numbers from '~/utils/numbers';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import TermsLine from './Box/terms';

const Transport: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData]   = useState<any>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    // Loading Data
    useEffect(() => {
        !ready && api.get('dashboard/supervision-director', {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setData(resp.data);
            setReady(true);
        }).catch((err: any) => {
            setError('Erro ao carregar os dados do dashboard')
            setReady(true)
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Supervisão e Direção") && setBreadcrumbs({
            curr: 'Supervisão e Direção',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Supervisão e Direção' }
            ]
        });
    }, [breadcrumbs]);

    return <Template page="Supervisão e Direção" pageTitle="Supervisão e Direção" className="supDirection">
        {
            ready ? <div className="contentBox">
                <div className="boxLine">
                    <div className='box visits'>
                        <h5>{ numbers.format(data.terms) }</h5>
                        <small>Termos de Visita</small>
                    </div>

                    <div className='box previous'>
                        <h5>{ numbers.format(data.previousTerms) }</h5>
                        <small>Visitas Anteriores</small>
                    </div>

                    <div className='box next'>
                        <h5>{ numbers.format(data.nextTerms) }</h5>
                        <small>Visitas Futuras</small>
                    </div>
                </div>

                <hr />

                <h3>Próximas Visitas</h3>

                <div className="nextVisitTerm">
                    {
                        data.termsData.length > 0 ? data.termsData.map((el: any) => <TermsLine key={`activity_${el.id}`} {...el} />) : <div className="alertLine">Não há nenhuma atividade cadastrada!</div>
                    }
                </div>
            </div> : <Loading />
        }
    </Template>;
}

export default Transport;