import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaQrcode } from 'react-icons/fa';
import { FaAddressCard, FaBowlFood, FaChartSimple } from 'react-icons/fa6';
import { GeneralContext } from '~/contexts/general';

const Parents: React.FC = () => {
    const { user } = useContext(GeneralContext);
    const location = useLocation();

    return (
        <>
            {
                user.role==='student' && (
                    <Link to="/pais-e-alunos/aluno" className={location.pathname === '/pais-e-alunos/aluno' ? ' active' : ''}>
                        <FaAddressCard />
                        <span>Ficha do Aluno</span>
                    </Link>
                )
            }

            {
                user.role==='student' && (
                    <Link to="/pais-e-alunos/notas" className={location.pathname === '/pais-e-alunos/notas' ? ' active' : ''}>
                        <FaChartSimple />
                        <span>Notas e Faltas</span>
                    </Link>
                )
            }

            {
                user.role==='student' && (
                    <Link to="/pais-e-alunos/nutricao" className={location.pathname.indexOf('/pais-e-alunos/nutricao')>=0 ? ' active' : ''}>
                        <FaBowlFood />
                        <span>Nutrição</span>
                    </Link>
                )
            }

            {
                user.role==='student' && (
                    <Link to="/pais-e-alunos/matricula" className={location.pathname === '/pais-e-alunos/matricula' ? ' active' : ''}>
                        <FaQrcode />
                        <span>Matrícula</span>
                    </Link>
                )
            }
        </>
    );
}

export default Parents;
