// general.tsx
import { createContext, ReactNode, useEffect, useState } from "react";
import api from "~/services/api";
import apiIP from "~/services/getIp";

// Login
import cryptos from '~/utils/cryptos';
import storage from '~/utils/storage';

interface Permission {
    page   : string;
    subpage: string;
    ready  : boolean;
    write  : boolean;
}

interface UserInterface {
    id           : number;
    employee_id  : number;
    student_id   : number;
    role         : string;
    name         : string;
    mail         : string;
    active       : boolean;
    token        : string;
    role_category: string;
    permissions  : Permission[];
}

interface GeneralContextData {
    logged            : boolean;
    user              : UserInterface;
    breadcrumbs       : any;
    school            : any;
    student           : any;
    teacher           : any;
    login             : Function;
    logout            : Function;
    setBreadcrumbs    : Function;
    checkToken        : Function;
    updateUser        : Function;
    changeSchool      : Function;
    checkSchool       : Function;
    changeStudent     : Function;
    checkStudent      : Function;
    changeTeacher     : Function;
    checkTeacher      : Function;
    checkAdminDirector: Function;
}

interface GeneralProviderProps {
    children: ReactNode;
}

// User Init
const userInit: UserInterface = {
    id           : 0,
    employee_id  : 0,
    student_id   : 0,
    role         : 'guest',
    name         : 'Guest',
    mail         : '',
    active       : true,
    token        : '',
    role_category: '',
    permissions  : []
}

export const GeneralContext = createContext({} as GeneralContextData);

export function GeneralProvider ({ children }: GeneralProviderProps) {
    const [ready, setReady]             = useState<boolean>(false);
    const [logged, setLogged]           = useState<boolean>(false);
    const [breadcrumbs, setBreadcrumbs] = useState<any>(null);
    const [user, setUser]               = useState<UserInterface>(userInit);
    const [school, setSchool]           = useState<any>(null);
    const [student, setStudent]         = useState<any>(null);
    const [teacher, setTeacher]         = useState<any>(null);


    async function login(userData: any) {
        const updatedUserData = { ...userData };
        setUser(updatedUserData);

        // Set User Cookie
        let data = cryptos.encryptWeb(updatedUserData);
        storage.set('UToken', data);
        setLogged(true);
    }

    function logout() {
        storage.remove('UToken');
        setUser(userInit);
        setLogged(false);
    }

    async function checkToken(user: any) {
        let tokens: any = cryptos.encryptServer({ token: user.token, refresh: user.refreshToken });
        let data: any = null;

        // Validate Token
        let ip = await apiIP.get();
        if (ip === user.ip) {
            await api.post('validateToken', { data: tokens })
            .then(async resp => {
                if (resp.data.valid && resp.data.user === "") {
                    const updatedUser = { ...user };
                    data = cryptos.encryptWeb(updatedUser);

                    setUser(updatedUser);
                } else {
                    let userData = cryptos.decryptServer(resp.data.user);
                    const updatedUserData = { ...userData };
                    data = cryptos.encryptWeb(updatedUserData);
                    storage.set('UToken', data);
                    setUser(updatedUserData);
                }

                setLogged(true);
                setReady(true);
                return true;
            }).catch(error => {
                storage.remove('UToken');
                setUser(userInit);
                setLogged(false);
                return false;
            });
        } else {
            alert('Faça o login novamente');
            logout();
        }
    }

    // Users Functions
    function updateUser(userData: any) {
        let updatedUserData = { ...user, ...userData };
        setUser(updatedUserData);

        // Set User Cookie
        let data = cryptos.encryptWeb(updatedUserData);
        storage.set('UToken', data);
    }

    // Schools Functions
    function changeSchool(school: any, notReady?: boolean) {
        storage.set('School', school.id);
        setSchool(school);
        !notReady && setReady(false)
    }

    function checkSchool() {
        let school_id = storage.get('School');
        school_id && setSchool({ id: school_id });
    }

    // Students Functions
    function changeStudent(student: any) {
        if (student) {
            storage.set('Student', student.id);
            setStudent(student);
        } else {
            storage.remove('Student');
            setStudent(null);
        }
    }

    function checkStudent() {
        let student_id = storage.get('Student');
        student_id && setStudent({ id: student_id });
    }

    // Teachers Functions
    function changeTeacher(teacher: any, notReady?: boolean) {
        storage.set('Teacher', teacher.id);
        setTeacher(teacher);
        !notReady && setReady(false)
    }

    function checkTeacher() {
        let teacher_id = storage.get('Teacher');
        teacher_id && setTeacher({ id: teacher_id });
    }

    function checkAdminDirector () {
        if (
            user.role === 'admin' ||
            Number(user.role_category) === 3 // Supervisão e Direção
        ) return true;
        return false;
    }

    useEffect(() => {
        if (!ready) {
            let data = storage.get('UToken');

            if (data) {
                let userData = cryptos.decryptWeb(data);
                checkToken(userData);
            } else {
                setUser(userInit);
                setLogged(false);
                setReady(true);
            }
        }
    }, [ready, logged]);

    useEffect(() => {}, [breadcrumbs]);
    useEffect(() => {}, [user, school, student, teacher]);

    return (
        <GeneralContext.Provider value={{
            user,
            login,
            logged,
            logout,
            checkToken,
            updateUser,
            breadcrumbs,
            setBreadcrumbs,
            school,
            changeSchool,
            checkSchool,
            student,
            changeStudent,
            checkStudent,
            teacher,
            changeTeacher,
            checkTeacher,
            checkAdminDirector,
        }}>
            { ready ? children : <></> }
        </GeneralContext.Provider>
    );
}
