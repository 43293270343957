import React, { useContext } from 'react';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import TeacherActivitiesUser from './User';
import TeacherActivitiesAdmin from './Admin';

const TeacherActivities: React.FC = () => {
    // Contexts
    const { user } = useContext(GeneralContext);

    return user.employee_id ? (
        <TeacherActivitiesUser />
    ) : (
        <TeacherActivitiesAdmin />
    );
}

export default TeacherActivities;