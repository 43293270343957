import React, { useContext } from 'react';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import TeacherClassesUser from './User';
import TeacherClassesAdmin from './Admin';

const TeacherClasses: React.FC = () => {
    // Contexts
    const { user } = useContext(GeneralContext);

    return user.employee_id ? (
        <TeacherClassesUser />
    ) : (
        <TeacherClassesAdmin />
    );
}

export default TeacherClasses;