import { useContext, useEffect, useState } from "react";
import api from "~/services/api";
import Treats from '~/utils/treats';
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

// Base Data
let baseData = {
    "id"                 : null,
    "school_id_requester": "",
    "school_id_required" : "",
    "applicant_id"       : "",
    "student_id"         : "",
    "status"             : "Pendente",
};

const ModalForm: React.FC<any> = (props: any) => {
    const [loadData, setLoadData] = useState<boolean>(false);
    const [data, setData]         = useState<any>(baseData);
    const [errors, setErrors]     = useState<string | null>(null);

    // Contexts
    const { user, school } = useContext(GeneralContext);

    function changeStatus(status: string) {
        console.log(status)
        setData({
            ...data,
            status
        });
    }

    const saveDataForm = async () => {
        if (
            data.school_id_requester === "" ||
            data.school_id_required === "" ||
            data.student_id === ""
        ) {
            setErrors("Preencha todos os campos.");
            return;
        }

        // Clear states
        // setLoadData(true);
        setErrors(null);

        // Set Body POST
        let dataFormat: any = data;
        dataFormat.applicant_id = user.id;
        if (props.data && props.data.id) dataFormat.id = props.data.id;

        // Send data to server
        await api.post('transfer', dataFormat, {
            headers: { Authorization: user.token }
        }).then(data => {
            setLoadData(false);
            props.setReady(false);
            props.setModalOpen(false);
        }).catch(errr => {
            console.log("Errors")
            console.log(errr)
            setErrors("Erro ao salvar os dados. Tente novamente.")
        });
    };

    useEffect(() => {
        setData({
            id                 : props.data.id,
            school_id_requester: props.data.school_id_requester,
            school_id_required : props.data.school_id_required,
            applicant_id       : props.data.applicant_id,
            student_id         : props.data.student_id,
            status             : props.data.status
        })
    }, [])

    useEffect(() => {
        console.log(data)
    }, [data]);

    return !loadData ? <>
        <div className="originSchool">
            <b>Escola de Origem</b>
            <span>{Treats.formatNames(props.data.schoolRequester.name)}</span>
        </div>

        <div className="destinySchool">
            <b>Escola de Destino</b>
            <span>{Treats.formatNames(props.data.schoolRequired.name)}</span>
        </div>

        <hr />

        <div className="student">
            <b>Aluno</b>
            <span>{Treats.formatNames(props.data.student.name)}</span>
        </div>

        <hr />

        <div className="status">
            <b>Selecione o Status</b>
            <div className="statusBox">
                <button id="btnApproved" className={data.status === 'Aprovado' ? 'active' : ''} type="button" onClick={() => changeStatus('Aprovado')}>Aprovado</button>
                <button id="btnRefused" className={data.status === 'Negado' ? 'active' : ''} type="button" onClick={() => changeStatus('Negado')}>Negado</button>
            </div>
        </div>

        <div className="buttons">
            {errors && <div className="error">{errors}</div>}
            <button id="btnAdd" type="button" onClick={saveDataForm}>Salvar</button>
            <button id="btnCancel" type="button" onClick={() => props.setModalOpen(false)}>Cancelar</button>
        </div>
    </> : <div className="saving">Salvando dados...</div>;
};

export default ModalForm;
