import React, { useContext, useEffect, useState } from "react";
import treats from "~/utils/treats";
import dates from "~/utils/dates";
import sort_by from '~/utils/sort';
import api from "~/services/api";
import './styles.css';

// Contexts
import { GeneralContext } from "~/contexts";

// Current Date
const currDate = dates.getDate();

const ModalAttendance: React.FC<any> = ({ data, setModalOpen }: any) => {
    const [presences, setPresences] = useState<any>(null);
    const [monthSel, setMonthSel]   = useState<any>(currDate.month);
    const [date, setDate]           = useState<any>(currDate);
    const [loadData, setLoadData]   = useState<boolean>(false);
    const [error, setError]         = useState<string | null>(null);
    const [status, setStatus]       = useState<string | null>(null);


    // Contexts
    const { user } = useContext(GeneralContext);

    function getDays() {
        if (presences === null) return <></>;

        return Array.from(Array(date.days), (e: any, idx: number) => {
            let day     = (idx + 1) <= 9 ? '0' + (idx + 1) : String(idx + 1);
            let weekend = dates.checkWeekend(date.year + '-' + date.month + '-' + day + ' 03:00:00');

            // Check Present / Fault or Weekend
            let presenceBase = presences.length > 0 ? presences.filter((el: any) => el.day === day) : [];
            let presence     = { class: 'fault', value: 'Faltou' };

            if (presenceBase.length > 0 && presenceBase[0].status === 'present') presence = { class: 'present', value: 'Presente' }
            if (weekend) presence = { class: 'weekend', value: 'Final de Semana' }

            return <div className={`dayLine ${presence.class}`} key={`presence_${day}`}>
                <div className="day">{ day }</div>
                <div className={`status ${presence.class!=='weekend' ? presence.class : ''}`}>{ presence.value }</div>
                {
                    !weekend ?
                        <button className="button" onClick={() => changeStatus(day, presence.class)}>Alterar</button> :
                        <div className="button weekend"></div>
                }
            </div>
        })
    }

    function changeMonth(month: string) {
        let newDate = dates.getDate(`${date.year}-${(Number(month)<=9 ? '0' : '') + month}-01 03:00:00`);
        setDate(newDate);
        setMonthSel(month);
        getMonthPresence(month);
        setError(null);
        setStatus(null);
    }

    function changeStatus(day: string, status: string) {
        let presencesBase = JSON.parse(JSON.stringify(presences));
        let statusReverse = status === 'fault' ? 'present' : 'fault';

        // Get a index day in presences
        let indexDay      = presencesBase.findIndex((el: any) => el.day === day);

        // Change status day
        if (indexDay >= 0) presencesBase[indexDay].status = statusReverse;
        else presencesBase.push({ day, status: statusReverse })

        // Sort data by day
        presencesBase.sort(sort_by({ name: 'day' }))

        // Set new json with presences
        setPresences(presencesBase)
    }

    function getContentMonths() {
        return <>
            <button
                className={(monthSel==='01' ? 'active' : '') + (Number(currDate.month)<1 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=1 ? (monthSel!=='01' ? changeMonth('01') : null) : undefined}
                type="button"
            >
                Jan
            </button>

            <button
                className={(monthSel==='02' ? 'active' : '') + (Number(currDate.month)<2 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=2 ? (monthSel!=='02' ? changeMonth('02') : null) : undefined}
                type="button"
            >
                Fev
            </button>

            <button
                className={(monthSel==='03' ? 'active' : '') + (Number(currDate.month)<3 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=3 ? (monthSel!=='03' ? changeMonth('03') : null) : undefined}
                type="button"
            >
                Mar
            </button>

            <button
                className={(monthSel==='04' ? 'active' : '') + (Number(currDate.month)<4 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=4 ? (monthSel!=='04' ? changeMonth('04') : null) : undefined}
                type="button"
            >
                Abr
            </button>

            <button
                className={(monthSel==='05' ? 'active' : '') + (Number(currDate.month)<5 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=5 ? (monthSel!=='05' ? changeMonth('05') : null) : undefined}
                type="button"
            >
                Mai
            </button>

            <button
                className={(monthSel==='06' ? 'active' : '') + (Number(currDate.month)<6 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=6 ? (monthSel!=='06' ? changeMonth('06') : null) : undefined}
                type="button"
            >
                Jun
            </button>

            <button
                className={(monthSel==='07' ? 'active' : '') + (Number(currDate.month)<7 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=7 ? (monthSel!=='07' ? changeMonth('07') : null) : undefined}
                type="button"
            >
                Jul
            </button>

            <button
                className={(monthSel==='08' ? 'active' : '') + (Number(currDate.month)<8 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=8 ? (monthSel!=='08' ? changeMonth('08') : null) : undefined}
                type="button"
            >
                Ago
            </button>

            <button
                className={(monthSel==='09' ? 'active' : '') + (Number(currDate.month)<9 ? ' disabled' : '' )}
                onClick={() => Number(currDate.month)>=9 ? (monthSel!=='09' ? changeMonth('09') : null) : undefined}
                type="button"
            >
                Set
            </button>

            <button
                className={(monthSel==='10' ? 'active' : '') + (Number(currDate.month)<10 ? ' disabled' : '')}
                onClick={() => Number(currDate.month)>=10 ? (monthSel!=='10' ? changeMonth('10'): null) : undefined}
                type="button"
            >
                Out
            </button>

            <button
                className={(monthSel==='11' ? 'active' : '') + (Number(currDate.month)<11 ? ' disabled' : '')}
                onClick={() => Number(currDate.month)>=11 ? (monthSel!=='11' ? changeMonth('11'): null) : undefined}
                type="button"
            >
                Nov
            </button>

            <button
                className={(monthSel==='12' ? 'active' : '') + (Number(currDate.month)<12 ? ' disabled' : '')}
                onClick={() => Number(currDate.month)>=12 ? (monthSel!=='12' ? changeMonth('12'): null) : undefined}
                type="button"
            >
                Dez
            </button>
        </>
    }

    async function getMonthPresence(month?: string) {
        await api.get(`teacher/student/presence/${data.id}/${date.year}/${Number(month ? month : date.month)}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            let data = resp.data.length === 1 ? JSON.parse(resp.data[0].presence) : [];
            setPresences(data);
        }).catch(() => {
            console.log("Error")
        });
    }

    async function saveDataForm () {
        // Clear states
        setLoadData(true);

        // Set Body POST
        let dataFormat: any = {
            student_id: data.id,
            year      : date.year,
            month     : date.month,
            presences
        };

        //Send data to server
        await api.post('/student/presence', dataFormat, {
            headers: { Authorization: user.token }
        }).then(data => {
            setLoadData(false);
            setStatus(`Dados de ${date.monthName} salvo com sucesso!`);

            // Remove aviso após 4 segundos
            setTimeout(() => {
                setStatus(null);
            }, 4000);
        }).catch(errr => {
            setLoadData(false);
            setError("Erro ao salvar os dados. Tente novamente");

            // Remove aviso após 4 segundos
            setTimeout(() => {
                setError(null);
            }, 4000);
        });
    }

    useEffect(() => {
        getMonthPresence();
    }, [])

    // Refresh screen before update presences
    useEffect(() => {}, [presences])

    return <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="updatePresences">
        <h3>{treats.formatNames(data.name)}</h3>
        <div className="description">Presenças de {date.monthName} de {date.year}</div>

        <hr />

        <div className="monthsLine">
            {getContentMonths()}
        </div>

        <div className="dayHeader">
            <div className="day">Dia</div>
            <div className="status">Status</div>
            <div className="button"></div>
        </div>

        <div className="daysBox">
            {getDays()}
        </div>

        <div className="btns">
            {error && <div className="error">{error}</div>}
            {status && <div className="status">{status}</div>}
            <button id="btnAdd" type="button" onClick={!loadData ? saveDataForm : undefined}>Salvar</button>
            <button id="btnCancel" type="button" onClick={() => !loadData ? setModalOpen(false) : undefined}>Cancelar</button>
        </div>
    </form>;
};

export default ModalAttendance;
