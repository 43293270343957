import React, { useState, ChangeEvent, useContext } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

const FormRA: React.FC<any> = () => {
    const [studentData, setStudentData] = useState<any>({ errors: [], ra: '' });
    const [loadData, setLoadData]       = useState<boolean>(false);
    const [error, setError]             = useState<string | null>(null);

    // Contexts
    const { user } = useContext(GeneralContext);
    const Modal = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {name, value} = event.target;
        const data = { [name]: value };
        setStudentData({...studentData, ...data});
        setError(null);
    }

    async function saveStudent() {
        setLoadData(true);
        setError(null);

        if (studentData.ra.trim() === '') {
            setLoadData(false);
            setError('Você precisa preencher o RA');
            return false;
        }

        await api.put('secEduc/students/ra', { ra: studentData.ra }, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setLoadData(true);
            setError(null);
            Modal.setModalOpen(false);
        }).catch(errr => {
            setLoadData(false);
            setError("Erro ao salvar os dados. Verifique o RA");

            // Remove aviso após 4 segundos
            setTimeout(() => {
                setError(null);
            }, 4000);
        });
    }

    return !loadData ? <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="formRA">
        <div className="form-group no-margin">
            <div className="input-group">
                <input id="name" className={studentData.errors && studentData.errors.name ? 'error' : ''} name="ra" type="text" placeholder="RA" value={studentData.ra} onChange={onChange} />
            </div>

            <div className="input-group btns">
                {error && <div className="error">{error}</div>}
                <button id="btnAdd" type="button" onClick={() => saveStudent()}>Adicionar</button>
                <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
            </div>
        </div>
    </form> : <div className="saving">Salvando dados...</div>;
}

export default FormRA;