import React, { useContext, useEffect, useState } from 'react';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';

// Components
import TemplateSchoolSecretary from '../Template';
import Loading from '~/components/Default/Loading';
import TransferBox from './Box';
import FormTransfer from './Modal';
import FormTransferStatus from './Modal/status';
import DeleteTransfer from './Modal/delete';

const SecretaryEducationEnrollment: React.FC = () => {
    const [ready, setReady]                 = useState(false);
    const [transfers, setTransfers]         = useState<any>(null);
    const [transfersList, setTransfersList] = useState<any>([]);
    const [tabList, setTabList]             = useState<string>('requester');
    const [error, setError]                 = useState<string | null>(null);

    // Contexts
    const { user, school, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        if (type==="New") {
            setModalClass('transAddEdit')
            setModalTitle('Adicionar Nova Transferência')
            setModalBody(<FormTransfer setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type==="Edit") {
            setModalClass('transAddEdit')
            setModalTitle('Editar Transferência')
            setModalBody(<FormTransfer data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        }  else if (type==="Status") {
            setModalClass('transStatus')
            setModalTitle('Atualizando Transferência')
            setModalBody(<FormTransferStatus data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        } else if (type==="Delete") {
            setModalClass('transDelete')
            setModalTitle('Remover Transferência')
            setModalBody(<DeleteTransfer data={data} setModalOpen={setModalOpen} setReady={setReady} />)
        }

        setModalOpen(true)
    }

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <>
            <div className="header">
                <button
                    className={tabList==='requester' ? 'active' : ''}
                    onClick={() => {
                        if (tabList === 'requester') return;
                        setTabList('requester')
                        setReady(false)
                    }}
                >
                    Requisições Realizadas
                </button>
                <button
                    className={tabList==='required' ? 'active' : ''}
                    onClick={() => {
                        if (tabList === 'required') return;
                        setTabList('required')
                        setReady(false)
                    }}
                >
                    Requisições Recebidas
                </button>
            </div>

            {
                transfers && transfers.length > 0 ? (
                    <div className="transfers">
                        {
                            transfers.map((transferData: any) => (
                                <TransferBox
                                    key={'transfer_' + transferData.id}
                                    data={transferData}
                                    openModal={openModal}
                                    tabList={tabList}
                                />
                            ))
                        }
                    </div>
                ) : <div className='noData'>Não foi possível localizar nenhuma transferência</div>
            }
        </>
    }

    // Get page data
    useEffect(() => {
        let url = (tabList ? '/' + tabList : '') + (school ? '/' + school.id : '');

        !ready && api.get(`transfer${url}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setTransfers(resp.data)
            setTransfersList(resp.data)
            setReady(true)
        }).catch(err => {
            setError('Erro ao carregar os dados das escolas')
            setReady(true)
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Transferências") && setBreadcrumbs({
            curr: 'Transferências',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Secretaria Escolar', url: '/secretaria-escolar'},
                { name: 'Transferências' }
            ]
        });
    }, [breadcrumbs]);

    return <TemplateSchoolSecretary
        page="Transferências"
        pageTitle="Secretaria da Educação - Transferências - Lista de Espera"
        className="secEscTransfers"
        buttons={[{
            nameButton: "Nova",
            className: "add",
            onClick: () => {openModal("New")}
        }]}
        search={{
            dataList: transfersList,        // Substitua 'yourDataList' pela sua lista de dados
            filterKey: ["schoolRequester.name", "schoolRequired.name", "student.name"],    // Substitua 'nome' pela chave que você deseja filtrar
            setFilteredList: setTransfers   // Substitua 'setYourList' pela função que define a lista filtrada
        }}
        isViewButton={true}
        isViewSearch={true}
    >
        {ready ? getContent() : <Loading />}
    </TemplateSchoolSecretary>
}

export default SecretaryEducationEnrollment;