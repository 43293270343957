import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaQrcode } from 'react-icons/fa';
import { FaAddressCard, FaBowlFood, FaChartSimple, FaPersonBreastfeeding } from 'react-icons/fa6';
import { GeneralContext } from '~/contexts/general';
import { hasPermission } from '~/utils/permissions';

const Parents: React.FC = () => {
    const { user } = useContext(GeneralContext);
    const location = useLocation();

    function submenu() {
        return (
            <div className='submenu'>
                {
                    hasPermission(user.permissions, user.role, 'pais-e-alunos', 'aluno') && (
                        <Link to="/pais-e-alunos/aluno" className={location.pathname === '/pais-e-alunos/aluno' ? ' active' : ''}>
                            <FaAddressCard />
                            <span>Ficha do Aluno</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'pais-e-alunos', 'notas') && (
                        <Link to="/pais-e-alunos/notas" className={location.pathname === '/pais-e-alunos/notas' ? ' active' : ''}>
                            <FaChartSimple />
                            <span>Notas e Faltas</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'pais-e-alunos', 'nutricao') && (
                        <Link to="/pais-e-alunos/nutricao" className={location.pathname.indexOf('/pais-e-alunos/nutricao')>=0 ? ' active' : ''}>
                            <FaBowlFood />
                            <span>Nutrição</span>
                        </Link>
                    )
                }

                {
                    hasPermission(user.permissions, user.role, 'pais-e-alunos', 'matricula') && (
                        <Link to="/pais-e-alunos/matricula" className={location.pathname === '/pais-e-alunos/matricula' ? ' active' : ''}>
                            <FaQrcode />
                            <span>Matrícula</span>
                        </Link>
                    )
                }
            </div>
        );
    }

    return (
        <>
            <Link to="/pais-e-alunos" className={location.pathname.indexOf('/pais-e-alunos') === 0 ? ' active' : ''}>
                <FaPersonBreastfeeding />
                <span>Pais e Alunos</span>
            </Link>

            {location.pathname.indexOf('/pais-e-alunos') === 0 && submenu()}
        </>
    );
}

export default Parents;
