import React, { useContext } from 'react';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import ParentsStudentRecordUser from './User';
import ParentsStudentRecordAdmin from './Admin';

const ParentsStudentRecord: React.FC = () => {
    // Contexts
    const { user } = useContext(GeneralContext);

    return user.role === 'student' ? (
        <ParentsStudentRecordUser />
    ) : (
        <ParentsStudentRecordAdmin />
    );
}

export default ParentsStudentRecord;