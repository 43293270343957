import React, { useContext, useEffect, useState } from 'react';
import dates from '~/utils/dates';
import api from '~/services/api';
import "./styles.css";

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import ParentsNutritionContent from './Content';

const weekDay = dates.getDateFormatted(new Date(), 'yyyy-mm-dd');

const ParentsNutrition: React.FC = () => {
    const [ready, setReady]         = useState(false);
    const [error, setError]         = useState<string | null>(null);
    const [schedules, setSchedules] = useState<any>([]);
    const [tips, setTips]           = useState<any>([]);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    function getContent() {
        if (error) return <div className='noData'>{error}</div>

        return <ParentsNutritionContent schedules={schedules} tips={tips} />
    }

    async function loadInfos () {
        try {
            const [schedulesReponse, tipsReponse] = await Promise.all([
                api.get(`nutrition/schedule/week/${weekDay}`, { headers: { Authorization: user.token } }),
                api.get(`nutrition/tips`, { headers: { Authorization: user.token } }),
            ]);

            setSchedules(schedulesReponse.data);
            setTips(tipsReponse.data);
            setReady(true);
        } catch (error) {
            alert('Erro ao carregar informações do formulário')
            console.error('Erro ao carregar informações:', error);
        }
    }

    useEffect(() => {
        !ready && loadInfos();
    }, [])

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Nutrição") && setBreadcrumbs({
            curr: 'Nutrição',
            links: [
                { name: 'Home', url: '/'},
                { name: 'Nutrição' }
            ]
        });
    }, [breadcrumbs]);

    return <Template
        page="Nutrição"
        pageTitle="Pais e Alunos - Nutrição"
        className="parentsNutrition"
    >
        {ready ? getContent() : <Loading />}
    </Template>;
}

export default ParentsNutrition;