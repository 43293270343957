import React, { useContext } from 'react';
import { FaTrash, FaChevronLeft, FaFloppyDisk } from 'react-icons/fa6';
import { PlanningDataEi, PlanningData } from '../types';

// Components
import HeaderSearchAndForm from '~/components/Default/HeaderSearchAndForm';
import { InputLabel } from '~/components/Forms';
import api from '~/services/api';
import { GeneralContext } from '~/contexts';
import { useNavigate } from 'react-router-dom';

interface PlanningEiProps {
	data: PlanningData;
	dataEi: PlanningDataEi;
	subjects: any;
    infos: any;
	setDataEi: React.Dispatch<React.SetStateAction<PlanningDataEi>>;
	setStep: React.Dispatch<React.SetStateAction<number>>;
}

const PlanningEi: React.FC<PlanningEiProps> = ({ data, dataEi, setDataEi, setStep, infos, subjects }) => {

	const {user} = useContext(GeneralContext);
    const navigate = useNavigate();

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, index?: number) => {
		if (typeof index === 'number') {
			const updatedRows  = [...dataEi.additionalRows];

			updatedRows[index] = {
				...updatedRows[index],
				[e.target.name]: e.target.value,
			};
			setDataEi((prev) => ({ ...prev, additionalRows: updatedRows }));
		} else {
			setDataEi((prev) => ({ ...prev, [e.target.name]: e.target.value }));
		}
	};

	const addRow = () => {
		setDataEi((prev) => ({
			...prev,
			additionalRows: [
				...prev.additionalRows,
				{
					additionalApproach: '',
					additionalContextPlanning: '',
					additionalSessionPlanning: '',
				},
			],
			}));
	  };


    const removeRow = (index: number) => {
		setDataEi((prev) => ({
			...prev,
			additionalRows: prev.additionalRows.filter((_, i) => i !== index),
		}));
    };

	async function sendForm() {
        await api.post('/teacher/planning-ei', {...data, ...dataEi}, {headers: { Authorization: user.token }})
        .then(resp => {
            alert('Cadastro realizado com sucesso')
            console.log(resp)
            navigate('/professor/planejamento')
        }).catch(err => {
            console.log("🚀 ~ sendForm ~ err:", err)
        })
    }

    return <>
    <HeaderSearchAndForm
        buttons={[
            { nameButton: "Voltar", onClick: () => setStep(0), icons: <FaChevronLeft size={14} /> },
            { nameButton: "Salvar", onClick: () => sendForm(), className: 'save', icons: <FaFloppyDisk size={14} /> }
        ]}
        isViewButton={true}
        isViewSearch={false}
    />
    <div className="ei">
        <header>
            <label><b>Bimestre:</b> {data.bimester}</label>
            <label><b>Série:</b> {data.series}</label>
            <label><b>Matéria:</b> {subjects.find((subject:any) => subject.id == data.subject)?.name || 'Matéria não encontrada'}</label>
        </header>

        <div className="contentForm">
            <div className="formGroup center">
            <div className="formItem inputLabel">
                <label htmlFor="hability">Habilidade</label>
                <select onChange={handleInputChange} id="hability" name="hability" value={dataEi?.hability} defaultValue={dataEi?.hability} required>
                    <option value="" disabled>  Selecione uma habilidade </option>
                    {
                        infos
                            // .filter((el: any) => el.planningSkillCycle === data.level && el.schoolSubjectId === data.subject)
                            .filter((el: any) => el.planningSkillCycle == '0' && el.schoolSubjectId == data.subject)
                            .filter((el: any, index: number, self: any[]) =>
                                index === self.findIndex((e: any) => e.planningSkillId === el.planningSkillId)
                            )
                            .map((el: any) => (
                                <option key={'hability_' + el.planningSkillId} value={el.planningSkillId}>
                                    {el.planningSkillId}
                                </option>
                            ))
                    }
                </select>
            </div>
            </div>

            <div className="formGroup">
                <div className="formItem inputLabel">
                    <label htmlFor="fieldsExperience"> Campo de experiência</label>
                    <select onChange={handleInputChange} id="fieldsExperience" name="fieldsExperience" value={dataEi?.fieldsExperience} defaultValue={dataEi?.fieldsExperience} required>
                        <option value="" disabled>  Selecione uma campo de experiência </option>
                        {
                            infos
                                .filter((el: any) => el.planningSkillId == dataEi.hability && el.fieldsExperienceId == el.skillFieldsExperienceFieldsExperienceId)
                                .map((el: any) => (
                                    <option key={'fields_experience_' + el.fieldsExperienceId} value={el.fieldsExperienceId}>
                                        {el.fieldsExperienceDescription}
                                    </option>
                                ))
                        }
                    </select>
					</div>
            </div>
            <div className="formGroup">
                <div className="formItem">
                    <InputLabel
                        name="approach"
                        type="text"
                        labelName="Abordagem"
                        value={dataEi.approach}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="formItem">
                    <InputLabel
                        name="contextPlanning"
                        type="text"
                        labelName="Contexto"
                        value={dataEi.contextPlanning}
                        onChange={handleInputChange}
                    />
                </div>

                <div className="formItem">
                    <InputLabel
                        name="sessionPlanning"
                        type="text"
                        labelName="Sessão"
                        value={dataEi.sessionPlanning}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <hr />

            {
				dataEi.additionalRows.map((row, index) => (
                    <div className="formGroup lines" key={index}>
                        <div className="formItem">
                            <InputLabel
                                name={`additionalApproach_${index}`}
                                type="text"
                                labelName="Abordagem"
                                value={row.additionalApproach[index]}
                                onChange={(e) => handleInputChange(e, index)}
                            />
                        </div>

                        <div className="formItem">
                            <InputLabel
                                name={`additionalContextPlanning_${index}`}
                                type="text"
                                labelName="Contexto"
                                value={row.additionalContextPlanning[index]}
                                onChange={(e) => handleInputChange(e, index)}
                            />
                        </div>

                        <div className="formItem">
                            <InputLabel
                                name={`additionalSessionPlanning_${index}`}
                                type="text"
                                labelName="Sessão"
                                value={row.additionalSessionPlanning[index]}
                                onChange={(e) => handleInputChange(e, index)}
                            />
                        </div>
                        <button
                            type="button"
                            className="del"
                            onClick={() => removeRow(index)}
                        >
                            <FaTrash />
                        </button>
                    </div>
                ))
            }

            {
                dataEi.additionalRows?.length < 3 && (
                    <button type="button" className='add' onClick={addRow}>
                        Adicionar Linha
                    </button>
                )
            }
        </div>
    </div>
</>;
}

export default PlanningEi;