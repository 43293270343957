import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBook, FaCalendarPlus, FaChartSimple } from 'react-icons/fa6';
import numbers from '~/utils/numbers';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Components
import Template from '~/components/Default/Template';
import Loading from '~/components/Default/Loading';
import ActivityLine from './Box/activity';
import TestsLine from './Box/tests';

const Transport: React.FC = () => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData]   = useState<any>(null);

    // Contexts
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);

    // Loading Data
    useEffect(() => {
        !ready && api.get('dashboard/teachers', {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setData(resp.data);
            setReady(true);
        }).catch((err: any) => {
            setError('Erro ao carregar os dados do dashboard')
            setReady(true)
        });
    }, [ready]);

    // Breadcrumbs
    useEffect(() => {
        (!breadcrumbs || breadcrumbs.curr!=="Professor") && setBreadcrumbs({
            curr: 'Professor',
            links: [
                { name: 'Home' }
            ]
        });
    }, [breadcrumbs]);

    return <Template
        page="Professor"
        pageTitle="Professor"
        className="teacher"
    >
        {
            ready ? <>
                <div className="contentBox">
                    <div className="header">
                        <Link className="testsActivitiesBox box" to="/secretaria-escolar/documentos">
                            <FaBook size={36} />
                            <small>Provas e Atividades</small>
                        </Link>

                        <Link className=" notesOthersBox box" to="/secretaria-escolar/relatorios">
                            <FaChartSimple size={36} />
                            <small>Notas e Outros</small>
                        </Link>

                        <Link className="lessonPlanningBox box" to="/secretaria-escolar/relatorios">
                            <FaCalendarPlus size={36} />
                            <small>Planejamento de Aulas</small>
                        </Link>

                        <div className='box'>
                            <h5>{ numbers.format(data.classes) }</h5>
                            <small>Classes</small>
                        </div>

                        <div className='box'>
                            <h5>{ numbers.format(data.students) }</h5>
                            <small>Alunos</small>
                        </div>
                    </div>

                    <hr />

                    <h3>Próximas Atividades</h3>

                    <div className="lastActivities">
                        {
                            data.activitiesData.length > 0 ? data.activitiesData.map((el: any) => <ActivityLine key={`activity_${el.id}`} {...el} />) : <div className="alertLine">Não há nenhuma atividade cadastrada!</div>
                        }
                    </div>

                    <hr />

                    <h3>Próximas Provas</h3>

                    <div className="lastTests">
                        {
                            data.testsData.length > 0 ? data.testsData.map((el: any) => <TestsLine key={`test_${el.id}`} {...el} />) : <div className="alertLine">Não há nenhuma prova cadastrada!</div>
                        }
                    </div>
                </div>
            </> : <Loading />
        }
    </Template>;
}

export default Transport;