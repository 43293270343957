import React, { useContext } from 'react';
import { FaEye, FaUsers } from 'react-icons/fa6';
import treats from '~/utils/treats';
import './styles.css';

// Components
import Infos from './infos';
import Students from './students';

// Contexts
import { ModalContext } from '~/contexts/modal';

interface Props {
    id         : number;
    class_id   : number;
    class_serie: number;
    class_acron: string;
    school     : { name: string };
    teaching   : { name: string };
    shift      : { name: string };
}

const SchoolBox:React.FC<any> = (props: Props) => {
    // Contexts
    const { setModalOpen, setModalClass, setModalTitle, setModalBody } = useContext(ModalContext);

    function openModal(type: string, data?: any) {
        switch (type) {
            case 'students':
                setModalClass('classStudents')
                setModalTitle('Estudantes da Classe')
                setModalBody(<Students id={props.id} />)
                break;

            default:
                setModalClass('classInfos')
                setModalTitle('Visualizando Classe')
                setModalBody(<Infos id={props.id} />)
                break;
        }

        setModalOpen(true);
    }

    return <div className="classBox">
        <div className="infos">
            <h3>{props.class_serie}ª Série{ props.class_acron ? ' ' + props.class_acron.toUpperCase() : '' }</h3>

            <div className="school">
                <b>Escola:</b> { treats.formatNames(props.school.name) }
            </div>

            <div className="teaching">
                <b>Tipo:</b> { treats.formatNames(props.teaching.name) }
            </div>

            <div className="shift">
                <b>Período:</b> { treats.formatNames(props.shift.name) }
            </div>
        </div>

        <div className="buttons">
            <button type="button" className="view" onClick={() => openModal('infos')} title="Visualizar Classe">
                <FaEye size={16} /><span> Visualizar</span>
            </button>

            <button type="button" className="view" onClick={() => openModal('students')} title="Professores da Classe">
                <FaUsers size={16} /><span> Alunos</span>
            </button>
        </div>
    </div>;
}

export default SchoolBox;