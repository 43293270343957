const ValidateForm = {
    checkFields: function (data: any, ignore?: any) {
        let keys   = Object.keys(data)
        let values = Object.values(data)
        let errors = false

        // Check Errors
        if (ignore) values.map((el: any, idx: number) => errors = !ignore.includes(keys[idx]) && el.trim() === '' ? true : errors);
        else values.map((el: any) => errors = el && el.trim() === '' ? true : errors);

        return errors
    },

    checkFormat: function (data: any, fields: any) {
        let errors = false

        fields.map((el: any) => {
            let item = data[el];

            // Check Date
            if (el.indexOf('_DATE') >= 0 && !(/^\d{4}-\d{2}-\d{2}$/).test(item)) return errors = true;

            // Check Time
            else if (el.indexOf('_TIME') >= 0 && !(/^\d{2}:\d{2}$/).test(item)) return errors = true;

            // Check RG
            else if (el.indexOf('_RG') >= 0 && !(/^\d{2}\.\d{3}\.\d{3}-\d{1}$/).test(item)) return errors = true;

            // Check CPF
            else if (el.indexOf('_CPF') >= 0 && !(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/).test(item)) return errors = true;
        })

        return errors
    }
}

export default ValidateForm