import React, { useState, ChangeEvent, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import '../auth.css';
import './styles.css';

// Components
import {InputLabel} from '~/components/Forms';

// Images
// import logo from '~/assets/images/logo.svg';
import logo from '~/assets/images/4educ_logo3.png';
import { InputLabelMask } from '~/components/Forms/InputLabel';

// Constants
const initialState = {
	ra_number: '',
	cpf: '',
	name: '',
	mail: '',
	student_id:''
};

const NewUser: React.FC = () => {
	let params = useParams();
	let text   = params.text;

    const navigate              = useNavigate();
    const {logged}              = useContext(GeneralContext);
    const [values, setValues]   = useState(initialState);
    const [message, setMessage] = useState<string>('');
    const [errors, setErrors]   = useState<String[]>([]);
    const [step, setStep]  	    = useState<number>(0);

	function onChange(event: ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;
        setValues({...values, [name]: value})
    }

	async function onSubmitFind (event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		var errArr = [];

		if (values.cpf==='')      errArr.push('O campo de CPF está vazio');
		if (values.ra_number==='') errArr.push('O campo de RA está vazio');

		if(errArr.length > 0) {
			setErrors(errArr);
			return;
		}

		let cpf_format =(values.cpf).replaceAll('.','').replace('-','')
		await api.get(`infos/parents/${values.ra_number}/${cpf_format}`).then(async (resp: any) => {
			setErrors([])
			setValues(resp.data)
			setStep(1)
		}).catch((err: any) => {
			if(err.response?.status){
				const { status } = err.response
				if (status===401) setErrors(['O aluno já possui um cadastro'])
				else if (status===404) setErrors(['Aluno não encontrado'])
				else setErrors(['Erro ao buscar aluno'])
			}else{
				console.log(err)
			}
		});
	}

	async function onSubmit (event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		var errArr = [];

		if (values.name==='') errArr.push('O campo de nome está vazio');
		if (values.ra_number==='') errArr.push('O campo de RA está vazio');
		if (values.cpf==='') errArr.push('O campo de CPF está vazio');
		if (values.mail==='' || values.mail === null) errArr.push('O campo de e-mail está vazio');

		if(errArr.length > 0) {
			setErrors(errArr);
			return;
		}

		let cpf_format =(values.cpf).replaceAll('.','').replace('-','')
		await api.post('infos/create-user', {...values, cpf:cpf_format}).then(async (resp: any) => {
			setErrors([])
			setValues(initialState)
			setMessage(`Usuário criado com sucesso! Sua senha de acesso é o CPF sem pontos nem traço.`)

		}).catch((err: any) => {
			if(err.response?.status){

					setMessage('')
					const { status } = err.response
					if (status===400) {
						const error = err.response.data.errors
						setErrors(error)
					}
			}else{
				console.log(err)
			}
		});
	}

	function loadInfos(){

		if(step === 0) return (
			<form onSubmit={onSubmitFind} autoComplete='off' method="POST">
				<InputLabel name='ra_number' type='text' labelName='Registro do aluno (RA)'  value={values.ra_number} onChange={onChange}/>
				<InputLabelMask
					name='cpf'
					value={values?.cpf}
					type='text'
					mask="999.999.999-99"
					placeholder='000.000.000-00'
					labelName='CPF'
					onChange={onChange}
				/>

				<button className="send" type="submit">
					Buscar aluno
				</button>

				<div className="back">
					<span>Deseja cancelar?</span>

					<Link to='/login' className="dec">
						volte ao login
					</Link>
				</div>
			</form>
		)

		if(step === 1) {
			return (
				<form onSubmit={onSubmit} autoComplete='off' method="POST">
					<InputLabel name='name' type='text' labelName='Nome'  value={values.name} onChange={onChange} disabled={true}/>
					<InputLabel name='ra_number' type='text' labelName='Registro do aluno (RA)'  value={values.ra_number} onChange={onChange} disabled={true}/>
					<InputLabelMask
						name='cpf'
						value={values?.cpf}
						type='text'
						mask="999.999.999-99"
						placeholder='000.000.000-00'
						labelName='CPF'
						onChange={onChange}
						disabled={true}
					/>
					<InputLabel name='mail' type='mail' labelName='Escolha um e-mail *' value={values.mail} onChange={onChange}/>

					<button className="send" type="submit">
						Cadastrar aluno
					</button>

					<div className="back">
						<span>Deseja cancelar?</span>

						<Link to='/login' className="dec">
							volte ao login
						</Link>
					</div>
				</form>
			)
		}



		}

	function treatErrors() {
        if (message!=='') {
            navigate('/lembrar-senha', {replace: true})
            setMessage('');
        }

        return (
            <div className="boxErrors">
				{ errors.map(el => <p key={`error_${el}`}>{ el }</p>) }
            </div>
        )
    }

    function treatMessage() {
        return (
            <div className="boxMessage">
				<p>{ message }</p>
				{ message &&
					<Link to='/login' className="dec">
						volte ao login
					</Link>
				}
            </div>
        )
    }

	// Set Message
	useEffect(() => {
        text && setMessage(Buffer.from(text, 'base64').toString('ascii'))
    }, [text, message, errors])

	// Test Navigate
	useEffect(() => {
		if (logged) navigate('/');
	}, [logged, navigate])

	return (
		<div id="new-user" className="auth">
			<div className='header'>
				<img src={logo} alt="Logo 4educ" />

				<h2>Cadastrar login do aluno</h2>
			</div>

			{ Array.isArray(errors) && errors.length>0 && treatErrors() }
			{ !errors.length && message && treatMessage() }

			{loadInfos()}
		</div>
	)
}
export default NewUser
