import React, { useContext, useEffect, useState } from 'react';
import Treats from '~/utils/treats';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';
import { ModalContext } from '~/contexts/modal';


// Variables Base
let dataBase = {
    "REPORT_NAME"   : "",
    "SCHOOL_NAME"   : "",
    "SCHOOL_ADDRESS": "",
    "TABLE_HEADER"  : [],
    "TABLE_DATA"    : [],
};

const SupervisionDirectionTeachersReports: React.FC<any> = ({doc, reportName, tableHeader, tableData, school}: any) => {
    const [errors, setErrors] = useState<any>(null);

    // Contexts
    const { user } = useContext(GeneralContext);
    const { setModalOpen, setModalButton } = useContext(ModalContext);

    async function saveDataForm () {
        // Set errors false
        setErrors(false);

        // Check if exists data
        if (tableData.length === 0) {
            setErrors("Não há informações para o relatório!")
            setModalButton(true)
            return false;
        }

        // Get School data and name report
        let data            = dataBase;
        data.REPORT_NAME    = reportName;
        data.SCHOOL_NAME    = Treats.formatNames(school.name);
        data.SCHOOL_ADDRESS = Treats.formatNames(school.address + (school.address_number ? ', ' + school.address_number : '') + ' - ' + school.address_neighborhood);
        data.TABLE_HEADER   = tableHeader;
        data.TABLE_DATA     = tableData;

        // Get report
        api.post(`docs/school/${doc}`, data, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then(async resp => {
            let url = window.URL.createObjectURL(new Blob([resp.data], { type: 'application/pdf' }));
            window.open(url, reportName, "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=900,height=600");
            setModalOpen(false);
        }).catch(errr => {
            console.log(errr)
        });
    }

    useEffect(() => {
        saveDataForm();
    }, [])

    return !errors ? <div className="saving">Gerando relatório...</div> : <div className="error">{errors}</div>
}

export default SupervisionDirectionTeachersReports;