
interface Permission {
    page: string;
    subpage: string;
    ready: boolean;
    write: boolean;
}


export function hasPermission(permissions: Permission[], role:string, page: string, subpage?: string): boolean {
    // Assume 'admin' is the role name for admin users
    if (role === 'admin' || role === 'guest') {
        return true; // Admin has access to everything
    }

    if (!permissions) return false;

    return permissions.some(permission =>
        permission.page === page && (subpage ? permission.subpage === subpage : true) && permission.ready
    );
}